import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { DeviceCatalogDialogComponent } from "../../device-catalog-component/device-catalog-dialog/device-catalog-dialog.component";
import CatalogDialogModel from "../../models/catalog/CatalogDialogModel";
import { ProductNode } from "../../models/catalog/CatalogProductNodeModel";
import { Constants } from "../../models/constants/Constants";
import ProjectInfo from "../../models/drive/projectInfo";
import { EventType } from "../../models/constants/eventType";
import MarkModel from "../../models/marks/MarkModel";
import ProductModel from "../../models/devices/ProductModel";
import ListEntry from "../../models/devices/ListEntry";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../services/toaster.service";

@Component({
  selector: "app-setting-scl-mark-header",
  templateUrl: "./setting-scl-mark-header.component.html",
  styleUrls: ["./setting-scl-mark-header.component.css"],
})
export class SettingSclMarkHeaderComponent implements OnChanges {
  @Input() selectedProduct: ProductModel = new ProductModel();
  @Input() projectinfo: ProjectInfo;
  @Input() selectedMark: MarkModel = new MarkModel();
  @Input() catalogTreeData: ProductNode;
  @Input() isMarkSelected: boolean;
  settingsHeader: string = "tooltip.devices";
  productNameLabel: string;
  markNameLabel: string;
  @ViewChild("deviceName") productName: ElementRef;
  @ViewChild("markName") markName: ElementRef;
  constructor(
    public eventBus: NgEventBus,
    private toasterService: ToasterService,
    private sessionExpiredDialog: SessionExpiredService,
    public dialog: MatDialog,
    private renderer: Renderer2
  ) {
    this.renderer.listen("window", "click", (e: Event) => {
      if (
        this.productName &&
        !this.productName.nativeElement.contains(e.target) &&
        this.selectedProduct &&
        this.selectedProduct.product
      ) {
        this.selectedProduct.product.isEditProductLabelInSettings = false;
      } else if (this.selectedMark && this.markName) {
        this.selectedMark.isEditMarkLabelSetting = false;
      }
    });
  }
  ngOnChanges() {
    this.setHeader();
  }
  setHeader() {
    if (this.isMarkSelected) {
      this.settingsHeader = "Action_addMark";
    } else {
      if (
        this.selectedProduct &&
        this.selectedProduct.product &&
        this.selectedProduct.product.type === Constants.SHORTCIRCUITLINE
      ) {
        this.settingsHeader = "Action_add_shortcurcuitcurrent";
      } else if (
        this.selectedProduct &&
        this.selectedProduct.product &&
        this.selectedProduct.product.type !== Constants.SHORTCIRCUITLINE
      ) {
        this.settingsHeader = "tooltip.devices";
      }
    }
  }
  collapseSideBar() {
    this.eventBus.cast(EventType.OPENADDDEVICESIDEBAR, false);
    setTimeout(() => {
      this.eventBus.cast(EventType.AFTERSIDEBARNAVIGATION, true);
    }, 300);
  }
  showHideDevice() {
    if (!this.isMarkSelected) {
      this.selectedProduct.product.toggleSlashEye =
        !this.selectedProduct.product.toggleSlashEye;
      this.eventBus.cast(EventType.SHOW_HIDE_GRAPH, this.selectedProduct);
    } else {
      this.showHideMark();
    }
  }
  async showHideMark() {
    this.selectedMark.visible = !this.selectedMark.visible;
    // this.eventBus.cast(EventType.SHOW_HIDE_MARK, this.selectedMark);
    let updatedMarkIndex: number = this.selectedMark.index;

    await serviceFactory.MarkService.updatemark(
      this.selectedMark,
      this.selectedMark.index,
      this.projectinfo.id
    )
      .then((markList: MarkModel[]) => {
        this.eventBus.cast(EventType.UPDATED_MARK_LIST, markList);
        this.eventBus.cast(EventType.HIGHLIGHT_SELECTED_MARK, updatedMarkIndex);
      })
      .catch((err: AxiosError) => {
        if (err.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(err);
        }
      });
  }
  deleteDevice() {
    if (!this.isMarkSelected) {
      this.eventBus.cast(
        EventType.EMIT_DELETE_EVENT,
        this.selectedProduct.product
      );
    } else {
      this.eventBus.cast(EventType.EMIT_DELETEMARK_EVENT, this.selectedMark);
    }
  }
  onEditProductDetails() {
    let catalogDialogModel = new CatalogDialogModel();
    catalogDialogModel.catalogTreeData = this.catalogTreeData;
    catalogDialogModel.projectInfo = this.projectinfo;
    catalogDialogModel.isEditDevice = true;
    const dialogRef = this.dialog.open(DeviceCatalogDialogComponent, {
      width: "80%",
      height: "83%",
      data: catalogDialogModel,
    });
    setTimeout(() => {
      this.eventBus.cast(EventType.ONEDITPRODUCTDETAILS, this.selectedProduct);
    }, 500);
  }
  setEditingMode() {
    this.productNameLabel = this.selectedProduct.product.name;
    this.selectedProduct.product.isEditProductLabelInSettings =
      !this.selectedProduct.product.isEditProductLabelInSettings;
  }
  async editDeviceLabel() {
    this.selectedProduct.product.isEditProductLabelInSettings = false;
    if (
      this.productNameLabel &&
      this.productNameLabel !== this.selectedProduct.product.name
    ) {
      await serviceFactory.ProductService.updateDeviceLabel(
        this.selectedProduct.facadeListEntry.listEntry,
        this.productNameLabel,
        this.selectedProduct.facadeListEntry.listEntry.index,
        this.projectinfo.id
      )
        .then((response: ListEntry) => {
          if (response.mLabel) {
            this.selectedProduct.product.name = response.mLabel;
            this.selectedProduct.facadeListEntry.listEntry.mLabel =
              response.mLabel;
            if (
              this.selectedProduct.product.type == Constants.SHORTCIRCUITLINE
            ) {
              this.selectedProduct.product.isShortCircuitLineLabelEdited = true;
            }
            this.productNameLabel = response.mLabel;
            this.eventBus.cast(
              EventType.ONDEVICELABELEDIT,
              this.selectedProduct
            );
          }
        })
        .catch((err: AxiosError) => {
          this.productNameLabel = this.selectedProduct.product.name;
          if (err.response.data === Constants.SESSIONEXPIRED) {
            this.sessionExpiredDialog.openDialog();
          } else {
            this.toasterService.showError(err);
          }
        });
    }
  }
  async editmarkLabel() {
    this.selectedMark.isEditMarkLabelSetting = false;
    this.selectedMark.isMarkLabelIsEdited = true;
    let updatedMarkIndex: number = this.selectedMark.index;
    if (this.markNameLabel && this.markNameLabel !== this.selectedMark.label) {
      let updateValue = new MarkModel();
      updateValue = this.selectedMark;
      updateValue.label = this.markNameLabel;
      await serviceFactory.MarkService.updatemark(
        updateValue,
        this.selectedMark.index,
        this.projectinfo.id
      )
        .then((markList: MarkModel[]) => {
          this.eventBus.cast(EventType.UPDATED_MARK_LIST, markList);
          this.eventBus.cast(
            EventType.HIGHLIGHT_SELECTED_MARK,
            updatedMarkIndex
          );
          // if (response.label) {
          //   this.selectedMark.label = response.label;
          //   this.selectedMark.translatedLabel = response.label;
          //   this.eventBus.cast(EventType.EDITLABELMARK, this.selectedMark);
          // }
        })
        .catch((err: AxiosError) => {
          this.markNameLabel = this.selectedMark.label;
          if (err.response.data === Constants.SESSIONEXPIRED) {
            this.sessionExpiredDialog.openDialog();
          } else {
            this.toasterService.showError(err);
          }
        });
    }
  }
  setMarkEditingMode() {
    this.markNameLabel = this.selectedMark.label;
    this.selectedMark.isEditMarkLabelSetting =
      !this.selectedMark.isEditMarkLabelSetting;
  }
}
