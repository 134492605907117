import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-link-icon",
  templateUrl: "./link-icon.component.html",
})
export class LinkIconComponent {
  @Input() type: string;
  constructor() {}
}
