import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import loginButtonModel from "../user-registration/loginButtonModel";
import UserRegistrationUIModel from "../user-registration/userRegistrationUIModel";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { ToasterService } from "../../services/toaster.service";
import { AxiosError } from "axios";
import { LoginConstants } from "../../models/constants/loginConstants";

@Component({
  selector: "app-email-window",
  templateUrl: "./email-window.component.html",
  styleUrls: ["./email-window.component.css"],
})
export class EmailWindowComponent {
  userProfileData: UserRegistrationUIModel = new UserRegistrationUIModel();
  isEmailValid: boolean = false;
  isNextButtonClicked: boolean = false;
  @Input() buttonName = new loginButtonModel();
  @Output() emitEmailValidStatus = new EventEmitter();

  constructor(
    private auth: AuthService,
    private toasterService: ToasterService
  ) {}
  isUserEmailValidCheck() {
    this.isEmailValid = this.isValidMailIdCheck(this.userProfileData.emailId)
      ? (this.isEmailValid = true)
      : (this.isEmailValid = false);
    this.isEmailValid ? this.checkIfUserIsExternal() : "";
  }
  checkIfUserIsExternal() {
    this.userProfileData.isExternal = this.userProfileData.emailId.includes(
      LoginConstants.INTERNAL_USER_ID
    )
      ? false
      : true;
    if (!this.userProfileData.isExternal) {
      this.buttonName.value = "LOGIN";
      this.buttonName.label = "pages.login.login";
    }
  }
  isValidMailIdCheck(email: string) {
    const regExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    return regExp.test(String(email).toLowerCase());
  }
  async checkIfNewUser() {
    this.isNextButtonClicked = true;
    if (!this.userProfileData.isExternal) {
      this.auth.loginWithRedirect();
    } else {
      await serviceFactory.AuthService.verifyNewUser(
        this.userProfileData.emailId
      )
        .then((response) => {
          this.userProfileData.isNewUser = response;
        })
        .catch((err: AxiosError) => {
          this.toasterService.showError(err);
          this.userProfileData.isNewUser = false;
        })
        .finally(() => {
          this.isNextButtonClicked = false;
          this.emitEmailValidStatus.emit(this.userProfileData);
          if (!this.userProfileData.isNewUser) {
            this.toasterService.showInfo(
              this.toasterService.toasterMessage(
                "toaster-message.user-available"
              )
            );
          }
        });
    }
  }
}
