import ProductAttribute from "./productAttribute";

export default class Product {
  id: number;
  name: string;
  productgroupID: string;
  mlfb: string;
  optionalTAKs: string;
  necessaryTAKs: string;
  quantity: string;
  productgroup: string;
  attachments: Array<string>;
  textLanguage: string;
  attributes: ProductAttribute[];
}
