import { createAction, props } from "@ngrx/store";
import OrderNumber from "../models/devices/orderNumber";
import CountryModel from "../models/projects/CountryModel";

export const loadProduct = createAction(
  "LoadProduct",
  props<{ productGroup: string }>()
);
export const loadProductSuccess = createAction(
  "LoadProduct Success",
  props<{ product: string; orderNumber: OrderNumber[] }>()
);
export const loadProductFailure = createAction(
  "LoadProduct Failure",
  props<{ error: string; product: string }>()
);
export const loadUserData = createAction(
  "LoadUserData Success",
  props<{ key: string; value: string }>()
);
export const loadCountryData = createAction(
  "loadCountryData Success",
  props<{ value: CountryModel[] }>()
);
