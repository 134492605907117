import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DeviceMainViewComponent } from "./device-view/device-components/shared/device-main-view/device-main-view/device-main-view.component";
import { AuthGuard } from "./guards/auth.guard";
import { RoutingGuard } from "./guards/routing.guard";
import { LoginPageComponent } from "./login-page/login-page.component";
import { ProjectDefinitionsComponent } from "./project-main-view/project-definitions/project-definitions.component";
import { ProjectDetailsComponent } from "./project-main-view/project-details/project-details.component";
import { ProjectListComponent } from "./project-main-view/project-list/project-list.component";
import { InformationPageComponent } from "./shared/information-page/information-page.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/projects",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginPageComponent,
    pathMatch: "full",
  },
  {
    path: "projects",
    component: ProjectDefinitionsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ProjectListComponent,
        children: [
          {
            path: "add",
            component: ProjectDetailsComponent,
            pathMatch: "full",
          },
          {
            path: "edit/:projectId",
            component: ProjectDetailsComponent,
            pathMatch: "full",
          },
        ],
      },
      {
        path: ":projectId/listentry",
        component: DeviceMainViewComponent,
        pathMatch: "full",
        canDeactivate: [RoutingGuard],
      },
    ],
  },
  {
    path: "information",
    component: InformationPageComponent,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
