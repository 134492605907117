import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription } from "rxjs";
import SpinnerModel from "./models/common/SpinnerModel";
import { EventType } from "./models/constants/eventType";
import routerNames from "./models/router/routerNames";
import { ConsoleToggleService } from "./services/ConsoleToggleService";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnDestroy {
  isShowSpinner: boolean = false;
  subscriptions: Subscription = new Subscription();
  constructor(
    private router: Router,
    private consoleToggleService: ConsoleToggleService,
    private eventBus: NgEventBus
  ) {
    this.consoleToggleService.disableConsoleInProduction();
    this.router.navigate([routerNames.getProjectRoute()]);

    const SHOWSPINNER = this.eventBus
      .on(EventType.SHOW_HIDE_SPINNER)
      .subscribe((metadata: MetaData) => {
        let data: SpinnerModel = metadata.data;
        this.isShowSpinner = data.show;
      });

    this.subscriptions.add(SHOWSPINNER);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showLoginPage() {
    let url = this.router.url;
    return url && url.startsWith("/login");
  }
}
