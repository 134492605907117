import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { HttpLoaderFactory } from "../app.module";
import { CustomLoader } from "../language/custom.loader";
import { LanguageMissingTranslationHandler } from "../language/language.missing-translation-handler";
import { SpinnerComponent } from "./spinner/spinner.component";
import { ComponentsHeaderComponent } from "./components-header/components-header.component";
import { TranslateNumberPipe } from "./translate-number.pipe";
@NgModule({
  declarations: [SpinnerComponent, ComponentsHeaderComponent,TranslateNumberPipe,],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        useFactory: HttpLoaderFactory,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler,
      },
    }),
  ],
  exports: [SpinnerComponent, ComponentsHeaderComponent,TranslateNumberPipe],
  providers: [],
  bootstrap: [],
  entryComponents: [],
})
export class CommonHelperModule {}
