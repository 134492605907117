import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { Subscription, take } from "rxjs";
import { Constants } from "../../models/constants/Constants";
import DialogModel from "../../models/common/DialogModel";
import ProjectInfo from "../../models/drive/projectInfo";
import { EventType } from "../../models/constants/eventType";
import routerNames from "../../models/router/routerNames";
import SpinnerModel from "../../models/common/SpinnerModel";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../services/toaster.service";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
@Component({
  selector: "app-icon-cell-renderer-component",
  templateUrl: "./icon-cell-renderer-component.component.html",
  styleUrls: ["./icon-cell-renderer-component.component.css"],
})
export class IconCellRendererComponentComponent
  implements ICellRendererAngularComp
{
  rowData: ProjectInfo;
  isOverlayOpen: boolean = false;
  overlayRef: OverlayRef;
  dialogModel = new DialogModel();
  subscriptions: Subscription = new Subscription();
  spinnerModel = new SpinnerModel();

  @ViewChild("settings") expandMenuButton: any;
  constructor(
    public router: Router,
    public overlay: Overlay,
    public eventBus: NgEventBus,
    private toasterService: ToasterService,
    public dialog: MatDialog,
    private sessionExpiredDialog: SessionExpiredService,
    public translator: TranslateService
  ) {}
  agInit(params: ICellRendererParams<any, any>): void {
    this.rowData = params.data;
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }
  editProject() {
    this.eventBus.cast(EventType.EDITPROJECT, true);
    this.router.navigate([routerNames.getEditProjectURL(this.rowData.id)]);
  }
  deleteProject() {
    let replaceValue;
    this.translator
      .get("confirmation-dialog.deleteProjectContent")
      .pipe(take(1))
      .subscribe(
        (value: string) =>
          (replaceValue = value.replace("{0}", `"${this.rowData.name}"`))
      );

    this.dialogModel.header = "confirmation-dialog.delete";
    this.dialogModel.content = replaceValue;
    this.dialogModel.actions = [
      {
        action: "confirmation-dialog.deleteConfirmation",
        type: "primary",
      },
      {
        action: "CANCEL",
        type: "secondary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: this.dialogModel,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.spinnerModel.show = true;
        this.spinnerModel.text = "loader.deleteProject";
        this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
        //TODO::Folder Id Removal Migration
        await serviceFactory.ProjectService.deleteProject(this.rowData.id)
          .then((res) => {
            if (res) {
              this.toasterService.showSuccess("toaster-message.projectDelete");
            }
          })
          .catch((error: AxiosError) => {
            if (error.response.data === Constants.SESSIONEXPIRED) {
              this.sessionExpiredDialog.openDialog();
            } else {
              this.toasterService.showError(error.message);
            }
          })
          .finally(() => {
            this.spinnerModel.show = false;
            this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
            this.eventBus.cast(EventType.DELETEPROJECTSUCCESS);
          });
      }
    });
  }
  async downloadProject() {
    this.spinnerModel.show = true;
    this.spinnerModel.text = "loader.downloadProject";
    this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
    //TODO::Folder Id Removal Migration
    await serviceFactory.ProjectService.downloadProject(
      this.rowData.id,
      this.rowData.name
    )
      .then((response) => {
        this.toasterService.showSuccess(
          "toaster-message.projectDownloadSuccess"
        );
      })
      .catch((e) => {
        if (e.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          let translatedErrorMsg = this.toasterService
            .toasterMessage("toaster-message.projectDownloadError")
            .replace(/(\<.*?\>)/gi, e);
          this.toasterService.showError(translatedErrorMsg);
        }
      })
      .finally(() => {
        this.spinnerModel.show = false;
        this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
      });
  }
}
