<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M1 1.04688C1.51972 5.55077 3.57021 15.4279 7.61438 18.9053"
    stroke="currentColor"
    stroke-width="1.8"
  />
  <path
    d="M4.85156 1.04688V5.34668L8.96191 8.88986V14.0396H13.3084V18.9531"
    stroke="currentColor"
    stroke-width="1.8"
  />
  <path
    d="M13.3125 1V11.4414H16.8559V15.6465H19.0292V18.9065"
    stroke="currentColor"
    stroke-width="1.8"
  />
</svg>
