<div class="h-100 scroll-hide">
  <div class="search-field">
    <mat-form-field class="w-100" appearance="outline">
      <input
        type="text"
        placeholder="{{ 'CatalogSearchControl_SearchLink' | translate }}"
        matInput
        [(ngModel)]="searchedText"
        (ngModelChange)="this.searchUpdate.next($event)"
      />
      <mat-icon matSuffix *ngIf="!searchedText">search</mat-icon>
      <app-icons
        class="pointer"
        *ngIf="searchedText"
        (click)="clearSearchTextCatalog()"
        [iconName]="'close'"
        [height]="16"
        [width]="16"
      ></app-icons>
    </mat-form-field>
  </div>
  <div class="catalog-tree">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodePadding
        matTreeNodeToggle
        matTreeNodePaddingIndent="20"
        (click)="onNodeClick(node)"
        [ngClass]="activeNode === node ? 'childnode-highlight' : ''"
        class="cursor-pointer catalog-tree__node-width"
      >
        <button mat-icon-button matTreeNodeToggle></button>
        <app-icons [iconName]="'document'"></app-icons>
        <span
          [ngClass]="
            activeNode === node
              ? 'catalog-tree__node-highlight'
              : 'catalog-tree__node'
          "
        >
          {{ node.name }}
        </span>
      </mat-tree-node>
      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePaddingIndent="20"
        matTreeNodePadding
        matTreeNodeToggle
        (click)="onNodeClick(node)"
        [ngClass]="
          activeNode === node
            ? 'catalog-tree__node-highlight'
            : treeControl.isExpanded(node)
            ? 'node-expand-icons'
            : 'catalog-tree__node'
        "
        class="cursor-pointer catalog-tree__node-width"
      >
        <button
          id="{{ node.name }}"
          mat-icon-button
          matTreeNodeToggle
          (click)="onNodeClick(node)"
          [ngClass]="activeNode === node ? 'catalog-tree__icon-highlight ' : ''"
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <div class="d-flex">
            <app-icons
              *ngIf="treeControl.isExpanded(node)"
              [iconName]="'arrow'"
              [type]="'down'"
            ></app-icons>
            <app-icons
              *ngIf="!treeControl.isExpanded(node)"
              [iconName]="'arrow'"
              [type]="'right'"
            ></app-icons>
            <app-icons
              *ngIf="treeControl.isExpanded(node)"
              [iconName]="'folder'"
              [type]="'open'"
            ></app-icons>
            <app-icons
              *ngIf="!treeControl.isExpanded(node)"
              [iconName]="'folder'"
              [type]="'close'"
            ></app-icons>
          </div>
        </button>
        <span
          [ngClass]="
            activeNode === node
              ? 'catalog-tree__node-highlight'
              : treeControl.isExpanded(node)
              ? 'node-expand'
              : 'catalog-tree__node'
          "
          class="pad-10"
        >
          {{ node.name }}
        </span>
      </mat-tree-node>
    </mat-tree>
  </div>
</div>
