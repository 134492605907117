export default class RelaySetting {
  key: string;
  valuesList: Array<number>;
  translatedvaluesList: Array<RelaySettingValueList> = [];
  selectedValue: number;
  translatedSelectedValue: string;
}

export class RelaySettingValueList {
  value: number;
  translatedValue: string;
}
