<div class="flex-justify h-100">
  <div
    class="icon"
    (click)="editProject()"
    matTooltip="{{ 'project-definition.editProject' | translate }}"
  >
    <span> <app-icons [iconName]="'edit'"></app-icons> </span>
  </div>
  <div
    class="icon"
    (click)="downloadProject()"
    matTooltip="{{ 'project-definition.downloadProject' | translate }}"
  >
    <span>
      <app-icons [iconName]="'download'"></app-icons>
    </span>
  </div>
  <div
    class="icon"
    (click)="deleteProject()"
    matTooltip="{{ 'Action_remove' | translate }}"
  >
    <span>
      <app-icons [iconName]="'delete'"></app-icons>
    </span>
  </div>
</div>
