export default class DialogModel {
  content: string;
  component: string;
  actions: Array<DialogActionModel>;
  header: string;
}

export class DialogActionModel {
  action: string;
  type: string;
}
