export enum ResultType {
  SUCCESS,
  FAIL,
}

interface Success<T> {
    type: ResultType.SUCCESS;
  value: T;
}

export interface Fail {
  type: ResultType.FAIL;
  message: string;
  statusCode?: number;
}

export function Success<T>(val: T): Success<T> {
  return {
    type: ResultType.SUCCESS,
    value: val,
  };
}

export function Fail(message: string, statusCode?: number): Fail {
  return {
    type: ResultType.FAIL,
    message: message,
    statusCode: statusCode,
  };
}

export type Result<T> = Success<T> | Fail;
