import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "angular-highcharts";
import { AxiosError } from "axios";
import Highcharts from "highcharts";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription, take } from "rxjs";
import DeviceSettingsModel from "src/app/models/device-settings/DeviceSettingsModel";
import { AddMarkComponentComponent } from "../add-mark-component/add-mark-component.component";
import BaseModel from "../models/devices/BaseModel";
import { ChartType } from "../models/constants/chartType";
import { Constants } from "../models/constants/Constants";
import { CurveDetails } from "../models/marks/curveDetails";
import CurveTracingModel from "../models/marks/CurveTracingModel";
import RelaySetting from "../models/device-settings/DeviceRelaySettingModel";
import SettingsSliderModel from "../models/device-settings/DeviceSettingsSliderModel";
import DialogModel from "../models/common/DialogModel";
import ProjectInfo from "../models/drive/projectInfo";
import { EventType } from "../models/constants/eventType";
import FacadeListEntryModel from "../models/devices/FacadeListEntry";
import LineConfigurationModel from "../models/devices/line-config/LineConfigurationModel";
import MarkModel from "../models/marks/MarkModel";
import ProductModel from "../models/devices/ProductModel";
import ListEntry from "../models/devices/ListEntry";
import ProtectiveDevice from "../services/../models/devices/protectiveDevice";
import { serviceFactory } from "../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../services/toaster.service";
import { ConfirmationDialogComponent } from "../shared/confirmation-dialog/confirmation-dialog.component";
@Component({
  selector: "app-high-chart",
  templateUrl: "./high-chart.component.html",
  styleUrls: ["./high-chart.component.css"],
})
export class HighChartComponent implements OnDestroy, AfterViewInit, OnInit {
  @Input() chartType: ChartType;
  selectedItemList = new Array<ProductModel>();
  @Input() xLabel: string = "I[A]";
  @Input() yLabel: string = "t[s]";
  @Input() xMax: number;
  @Input() xMin: number;
  @Input() yMax: number;
  @Input() yMin: number;
  selectedProduct: ProductModel;
  @Input() projectInfo: ProjectInfo;
  chartData: any;
  subscriptions: Subscription = new Subscription();
  protectiveDeviceMap = new Map<any, ProtectiveDevice>();
  isShowDefaultText: boolean = false;
  superScript = require("superscript-text");
  changedSliderObject: ProtectiveDevice;
  chartHeight: number;
  chartWidth: number;
  dialogModel = new DialogModel();
  tempRenderer;
  curveTracingModel = new CurveTracingModel();
  tablet: boolean = false;
  selectedViewMarksList: MarkModel[] = [];
  isMarkSelected: boolean = false;
  constructor(
    private eventBus: NgEventBus,
    private toasterService: ToasterService,
    private sessionExpiredDialog: SessionExpiredService,
    private dialog: MatDialog,
    private responsive: BreakpointObserver,
    public translator: TranslateService
  ) {
    this.chartData = this.createChartInstance();
    const onProductListUpdate = this.eventBus
      .on(EventType.EMIT_UPDATED_PRODUCTLIST)
      .subscribe((metaData: MetaData) => {
        this.selectedItemList = metaData.data;
      });
    const onProductEdit = this.eventBus
      .on(EventType.CHARTDATATOBEUPDATED)
      .subscribe((metaData: MetaData) => {
        let product: ProductModel = metaData.data;
        this.updateChartSeries(product.product, product.protectiveDevice);
      });
    const selectedProdSub = this.eventBus
      .on(EventType.EMIT_SELECTED_PRODUCT)
      .subscribe((metadata: MetaData) => {
        let product: ProductModel = metadata.data;
        if (product) {
          this.protectiveDeviceMap.set(
            product.product.index,
            product.protectiveDevice
          );
          this.onSelectingProduct(product);
        }
      });
    const showHideSub = this.eventBus
      .on(EventType.SHOW_HIDE_GRAPH)
      .subscribe((metadata: MetaData) => {
        let data: ProductModel = metadata.data;
        if (
          this.chartType === ChartType.TRIP &&
          data.product.type !== Constants.SHORTCIRCUITLINE
        ) {
          if (!data.product.toggleSlashEye) {
            this.hideElement(data.product);
          } else {
            this.showElement(data.product);
          }
          data.facadeListEntry.listEntry.visible = data.product.toggleSlashEye;
          this.synchronizeDeviceData(
            this.protectiveDeviceMap.get(data.product.index),
            data.facadeListEntry
          );
        } else if (
          this.chartType === ChartType.TRIP &&
          data.product.type === Constants.SHORTCIRCUITLINE
        ) {
          if (!data.product.toggleSlashEye) {
            // this.chartData.ref.xAxis[0].removePlotLine(data.product.index);
            this.hideShortCircuitLine(data.product);
          } else {
            if (this.selectedProduct.product.isShortCircuitLineLabelEdited) {
              data.facadeListEntry.listEntry.mLabel =
                this.selectedProduct.product.name;
            }
            this.isShowDefaultText =
              serviceFactory.SCLHelper.onAddShortCircuitLine(
                this.chartData,
                data,
                this.selectedItemList,
                this.selectedViewMarksList
              );
          }
          data.facadeListEntry.listEntry.visible = data.product.toggleSlashEye;
          this.synchronizeDeviceData(
            this.protectiveDeviceMap.get(data.product.index),
            data.facadeListEntry
          );
        }
      });
    // const hideMark = this.eventBus
    //   .on(EventType.SHOW_HIDE_MARK)
    //   .subscribe((metadata: MetaData) => {
    //     let markModel: MarkModel = metadata.data;
    //     serviceFactory.HighChartHelper.showHideMark(markModel, this.chartData);
    //   });
    const onDeletePrdSub = this.eventBus
      .on(EventType.CONFIRM_DELETE_PRODUCT)
      .subscribe((metadata: MetaData) => {
        let item: BaseModel = metadata.data;

        if (item) {
          this.selectedItemList.forEach((item) => {
            if (item.product.type === Constants.SHORTCIRCUITLINE) {
              this.chartData.ref.xAxis[0].removePlotLine(item.product.index);
              this.removeSeries(item.product.index);
            } else {
              this.removeSeries(item.product.index);
            }
          });
        }
        if (
          this.selectedItemList.length == 0 &&
          this.selectedViewMarksList.length == 0
        ) {
          this.removeAllSeries();
        }
        this.chartResize();
      });

    const sliderSub = this.eventBus
      .on(EventType.EMIT_SLIDER_CHANGES)
      .subscribe(async (metadata: MetaData) => {
        let item: DeviceSettingsModel = metadata.data;
        if (this.chartType === ChartType.TRIP) {
          if (item) {
            await serviceFactory.FacadeService.getSliderObjectByIndex(
              item.currentTime[0],
              this.protectiveDeviceMap.get(item.productDetails.index)
            )
              .then((response: ProtectiveDevice) => {
                this.eventBus.cast(
                  EventType.UPDATESETTINGAFTERSLIDERCHANGE,
                  response
                );
                this.changedSliderObject = response;
                this.updateChartSeries(item.productDetails, response);
              })
              .catch((error: AxiosError) => {
                this.toasterService.showError(error.message);
              });
          }
        }
      });
    const onMouseLeaveSliderChange = this.eventBus
      .on(EventType.EMITSLIDERCHANGESONMOUSELEAVE)
      .subscribe(async (metadata: MetaData) => {
        this.synchronizeDeviceData(this.changedSliderObject);
      });
    const EMITSELECTEDMARK = this.eventBus
      .on(EventType.EMITSELECTEDMARK)
      .subscribe(async (metadata: MetaData) => {
        if (metadata.data) {
          let mark = metadata.data;
          this.isMarkSelected = true;
          this.changeStrokeWidth(mark.markListIndex);
          await serviceFactory.HighChartHelper.changeSCLWidth(
            this.chartData,
            this.selectedItemList
          );
        }
      });
    // const markValueChange = this.eventBus
    //   .on(EventType.EMIT_UPDATED_MARK)
    //   .subscribe(async (metadata: MetaData) => {
    //     let markValue: MarkModel;
    //     markValue = metadata.data;
    //     this.removeSeries(markValue.index);
    //     let x = markValue.current;
    //     let y = markValue.time;
    //     let colorValue = markValue.rgbValue;
    //     let updatedx = Math.round(x);
    //     let updatedy = Math.round(y);

    //     Highcharts.SVGRenderer.prototype.symbols.cross = function (x, y, w, h) {
    //       return [
    //         "M",
    //         x,
    //         y,
    //         "L",
    //         x + w,
    //         y + h,
    //         "M",
    //         x + w,
    //         y,
    //         "L",
    //         x,
    //         y + h,
    //         "z",
    //       ];
    //     };
    //     this.chartData.addSeries({
    //       id: markValue.index,
    //       name: markValue.label,
    //       data: [{ x: updatedx, y: updatedy, name: markValue.label }],
    //       color: colorValue,
    //       showInLegend: false,
    //       marker: {
    //         symbol: "cross",
    //         enabled: true,
    //         lineColor: colorValue,
    //         lineWidth: 2,
    //         radius: 6,
    //       },
    //       dataLabels: {
    //         color: markValue.rgbValue,
    //         enabled: true,
    //         y: 30,
    //         format: markValue.label,
    //         style: {
    //           textOutline: 0,
    //           textShadow: false,
    //         },
    //         borderWidth: 0,
    //       },
    //     });
    //   });
    const inputChange = this.eventBus
      .on(EventType.EMITINPUTEVENT)
      .subscribe(async (metadata: MetaData) => {
        let item: DeviceSettingsModel = metadata.data;
        if (this.chartType === ChartType.TRIP) {
          if (item) {
            await serviceFactory.FacadeService.getChangedSliderObject(
              item.currentTime[0],
              this.protectiveDeviceMap.get(item.productDetails.index)
            )
              .then((response: ProtectiveDevice) => {
                this.changedSliderObject = response;
                this.eventBus.cast(
                  EventType.CHANGE_SLIDER_VALUE,
                  this.changedSliderObject
                );
                if (item.productDetails.type !== Constants.SHORTCIRCUITLINE) {
                  this.eventBus.cast(
                    EventType.UPDATESETTINGAFTERSLIDERCHANGE,
                    response
                  );
                  this.changedSliderObject = response;
                  this.eventBus.cast(
                    EventType.CHANGE_SLIDER_VALUE,
                    this.changedSliderObject
                  );
                  this.updateChartSeries(item.productDetails, response);
                  this.synchronizeDeviceData(
                    this.protectiveDeviceMap.get(item.productDetails.index)
                  );
                } else {
                  this.synchronizeDeviceData(
                    this.protectiveDeviceMap.get(item.productDetails.index)
                  ).then((res) => {
                    this.removeSeries(item.productDetails.index);
                    this.chartData.addSeries({
                      data: [[res.mProtectiveDevice["device"].current, 0]],
                      showInLegend: false,
                      id: item.productDetails.index,
                    });
                    this.chartData.ref.xAxis[0].plotLinesAndBands[
                      serviceFactory.SCLHelper.getPlotLineIndex(
                        item.productDetails.index,
                        this.chartData
                      )
                    ].options.value = res.mProtectiveDevice["device"].current;
                    if (!item.productDetails.isShortCircuitLineLabelEdited) {
                      this.chartData.ref.xAxis[0].plotLinesAndBands[
                        serviceFactory.SCLHelper.getPlotLineIndex(
                          item.productDetails.index,
                          this.chartData
                        )
                      ].options.label.text = res.mLabel;
                    } else {
                      this.chartData.ref.xAxis[0].plotLinesAndBands[
                        serviceFactory.SCLHelper.getPlotLineIndex(
                          item.productDetails.index,
                          this.chartData
                        )
                      ].options.label.text = item.productDetails.name;
                    }
                    this.chartData.ref.xAxis[0].update();
                  });
                }
              })
              .catch((error: AxiosError) => {
                this.toasterService.showError(error.message);
              });
          }
        }
      });
    const radioEvent = this.eventBus
      .on(EventType.EMIT_RADIO_EVENT)
      .subscribe(async (metadata: MetaData) => {
        if (this.chartType === ChartType.TRIP) {
          let radioValue: DeviceSettingsModel = metadata.data;
          if (radioValue) {
            await serviceFactory.FacadeService.getRadioAction(
              radioValue,
              this.protectiveDeviceMap.get(radioValue.productDetails.index)
            )
              .then((device: ProtectiveDevice) => {
                this.eventBus.cast(EventType.UPDATE_SLIDER_VALUE, device);
                this.updateChartSeries(radioValue.productDetails, device);
                this.synchronizeDeviceData(device);
              })
              .catch((error: AxiosError) => {
                this.toasterService.showError(error.message);
              });
          }
        }
      });
    const onCheckBoxClickSub = this.eventBus
      .on(EventType.EMIT_CHECKED_EVENT)
      .subscribe(async (metadata: MetaData) => {
        let checked: DeviceSettingsModel = metadata.data;
        if (this.chartType === ChartType.TRIP) {
          if (checked) {
            await serviceFactory.FacadeService.setActive(
              checked,
              this.protectiveDeviceMap.get(checked.productDetails.index)
            )
              .then((device: ProtectiveDevice) => {
                this.updateChartSeries(checked.productDetails, device);
                this.synchronizeDeviceData(device);
              })
              .catch((error: AxiosError) => {
                this.toasterService.showError(error.message);
              });
          }
        }
      });
    const horizontalSliderEvent = this.eventBus
      .on(EventType.EMIT_HORIZONTALSLIDER_VALUE_CHANGE)
      .subscribe(async (metadata: MetaData) => {
        if (this.chartType === ChartType.TRIP) {
          let sliderObject: DeviceSettingsModel = metadata.data;
          await serviceFactory.FacadeService.onHorizontalSliderValueChange(
            this.protectiveDeviceMap.get(sliderObject.productDetails.index),
            sliderObject.currentTime[0]
          )
            .then((device: ProtectiveDevice) => {
              this.changedSliderObject = device;
              this.updateChartSeries(sliderObject.productDetails, device);
            })
            .catch((error: AxiosError) => {
              this.toasterService.showError(error.message);
            });
        }
      });
    const relaySettingEvent = this.eventBus
      .on(EventType.EMIT_RELAYSETTING_CHANGE)
      .subscribe(async (metadata: MetaData) => {
        let relayValue: RelaySetting = metadata.data;
        await serviceFactory.FacadeService.onRelaySettingClick(
          relayValue,
          this.protectiveDeviceMap.get(this.selectedProduct.product.index)
        )
          .then((device: ProtectiveDevice) => {
            this.updateChartSeries(this.selectedProduct.product, device);
            this.synchronizeDeviceData(device);
          })
          .catch((error: AxiosError) => {
            this.toasterService.showError(error.message);
          });
      });
    const lineStyleChange = this.eventBus
      .on(EventType.ONLINECONFIGCHANGE)
      .subscribe((metaData: MetaData) => {
        let lineConfig: LineConfigurationModel = metaData.data;
        let selectedProtectiveDevice = this.protectiveDeviceMap.get(
          lineConfig.productIndex
        );
        lineConfig
          ? ((selectedProtectiveDevice.color = lineConfig.lineColor),
            (selectedProtectiveDevice.lineStyle = lineConfig.lineStyle),
            (selectedProtectiveDevice.lineWidth = lineConfig.lineWidth))
          : "";
        if (lineConfig.productType !== Constants.SHORTCIRCUITLINE) {
          this.changeLineStyle(lineConfig);
          this.changeStrokeWidth(this.selectedProduct.product.index);
        } else {
          serviceFactory.SCLHelper.updateSCLStyle(this.chartData, lineConfig);
        }
      });
    const markColorChange = this.eventBus
      .on(EventType.ON_MARK_COLORCHANGE)
      .subscribe((metaData: MetaData) => {
        let markModel: MarkModel;
        markModel = metaData.data;
        serviceFactory.HighChartHelper.changeMarkColor(
          markModel,
          this.chartData
        );
      });

    const openAddDeviceBarEvent = this.eventBus
      .on(EventType.AFTERSIDEBARNAVIGATION)
      .subscribe((metaData: MetaData) => {
        this.chartResize();
      });
    const deviceMoveUp = this.eventBus
      .on(EventType.REMOVE_ALL_SERIES)
      .subscribe((metaData: MetaData) => {
        this.selectedItemList = metaData.data;
        this.selectedItemList.forEach((i) => {
          if (i.product.type === Constants.SHORTCIRCUITLINE) {
            this.chartData.ref.xAxis[0].removePlotLine(i.product.index);
          }
        });
        this.removeAllSeries();
      });

    const onDevicelabelEdit = this.eventBus
      .on(EventType.ONDEVICELABELEDIT)
      .subscribe((metaData: MetaData) => {
        let product: ProductModel = metaData.data;
        if (
          this.chartType === ChartType.TRIP &&
          product.product.type !== Constants.SHORTCIRCUITLINE
        ) {
          this.updateLegendLabel(product.product.index, product.product.name);
        } else if (
          this.chartType === ChartType.TRIP &&
          product.product.type === Constants.SHORTCIRCUITLINE
        ) {
          serviceFactory.SCLHelper.updateSCLLabel(this.chartData, product);
        }
      });
    const onAddShortCircuitLine = this.eventBus
      .on(EventType.ADDSHORTCIRCUITLINE)
      .subscribe(async (metaData: MetaData) => {
        let product: ProductModel = metaData.data;
        if (this.chartType === ChartType.TRIP) {
          this.protectiveDeviceMap.set(
            product.product.index,
            product.protectiveDevice
          );
          this.selectedProduct = product;
          this.isShowDefaultText = await serviceFactory.SCLHelper.onAddSCL(
            this.chartData,
            product,
            this.selectedItemList,
            this.selectedViewMarksList
          );
        } else {
          this.removeAllSeries();
          this.addEmptySeries();
          this.isShowDefaultText = true;
        }
      });

    const AddMarkInGraph = this.eventBus
      .on(EventType.ADD_MARK_IN_GRAPH)
      .subscribe((metaData: MetaData) => {
        if (metaData.data) {
          let mark = new MarkModel();
          mark = metaData.data;
          this.addMarksInGraph(mark);
        }
      });

    const onDeleteMark = this.eventBus
      .on(EventType.CONFIRM_DELETE_MARK)
      .subscribe((metadata: MetaData) => {
        let item: MarkModel = metadata.data;
        if (item) {
          this.removeSeries(item.markListIndex);
          this.chartResize();
        }
      });
    const onDeleteMarkbe = this.eventBus
      .on(EventType.REMOVE_ALL_DEVICE_MARKS)
      .subscribe((metadata: MetaData) => {
        let item: MarkModel = metadata.data;
        if (item) {
          this.selectedViewMarksList.forEach((deletemark) => {
            this.removeSeries(deletemark.markListIndex);
          });
          this.selectedItemList.forEach((i) => {
            if (i.product.type === Constants.SHORTCIRCUITLINE) {
              this.chartData.ref.xAxis[0].removePlotLine(i.product.index);
            } else {
              this.removeSeries(i.product.index);
            }
          });
        }
      });
    const LINK_MARK_VALUE_CHANGEX = this.eventBus
      .on(EventType.LINK_MARK_VALUE_CHANGEX)
      .subscribe((metadata: MetaData) => {
        if (metadata.data != null || metadata.data != undefined) {
          let interpolatedY = metadata.data;
          let curveDetails: CurveDetails =
            serviceFactory.HighChartHelper.findNearestCurve(
              this.chartType,
              this.curveTracingModel,
              this.chartData,
              this.selectedItemList
            );
          let y = serviceFactory.HighChartHelper.findCorrespondingYvalue(
            curveDetails,
            interpolatedY
          );
          this.eventBus.cast(EventType.UPDATED_VALUE, y);
        }
      });
    const LINK_MARK_VALUE_CHANGEY = this.eventBus
      .on(EventType.LINK_MARK_VALUE_CHANGEY)
      .subscribe((metadata: MetaData) => {
        if (metadata.data != null || metadata.data != undefined) {
          let interpolatedX = metadata.data;
          let curveDetails: CurveDetails =
            serviceFactory.HighChartHelper.findNearestCurve(
              this.chartType,
              this.curveTracingModel,
              this.chartData,
              this.selectedItemList
            );
          let x = serviceFactory.HighChartHelper.findCorrespondingXvalue(
            curveDetails,
            interpolatedX
          );
          this.eventBus.cast(EventType.UPDATED_VALUE, x);
        }
      });
    const SELECTED_VIEW_MARKSLIST = this.eventBus
      .on(EventType.SELECTED_VIEW_MARKSLIST)
      .subscribe((metadata: MetaData) => {
        if (metadata.data) {
          this.selectedViewMarksList = metadata.data;
        }
      });

    this.subscriptions.add(selectedProdSub);
    this.subscriptions.add(showHideSub);
    this.subscriptions.add(onDeletePrdSub);
    this.subscriptions.add(sliderSub);
    this.subscriptions.add(onCheckBoxClickSub);
    this.subscriptions.add(lineStyleChange);
    this.subscriptions.add(relaySettingEvent);
    this.subscriptions.add(horizontalSliderEvent);
    this.subscriptions.add(radioEvent);
    this.subscriptions.add(openAddDeviceBarEvent);
    this.subscriptions.add(onMouseLeaveSliderChange);
    this.subscriptions.add(inputChange);
    this.subscriptions.add(onDevicelabelEdit);
    this.subscriptions.add(onProductEdit);
    this.subscriptions.add(onAddShortCircuitLine);
    this.subscriptions.add(deviceMoveUp);
    this.subscriptions.add(AddMarkInGraph);
    this.subscriptions.add(onDeleteMark);
    this.subscriptions.add(markColorChange);
    // this.subscriptions.add(hideMark);
    // this.subscriptions.add(markValueChange);
    this.subscriptions.add(onDeleteMarkbe);
    this.subscriptions.add(onProductListUpdate);
    this.subscriptions.add(EMITSELECTEDMARK);
    this.subscriptions.add(LINK_MARK_VALUE_CHANGEX);
    this.subscriptions.add(LINK_MARK_VALUE_CHANGEY);
    this.subscriptions.add(SELECTED_VIEW_MARKSLIST);
  }
  async ngAfterViewInit() {
    this.chartHeight = document.getElementById("highchart").clientHeight;
    this.chartWidth = document.getElementById("highchart").clientWidth;
    this.chartData = this.createChartInstance();
    let tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    document.body.appendChild(tempDiv);
    this.tempRenderer = new Highcharts.SVGRenderer(tempDiv, 600, 400);
    this.addEmptySeries();
  }
  @HostListener("window:resize", ["$event"])
  chartResize() {
    setTimeout(() => {
      let currentDivHeight = document.getElementById("highchart").clientHeight;
      let currentDivWidth = document.getElementById("highchart").clientWidth;
      if (
        this.chartHeight !== currentDivHeight ||
        this.chartWidth !== currentDivWidth
      ) {
        this.chartHeight = currentDivHeight;
        this.chartWidth = currentDivWidth;
        this.chartData && this.chartData.ref
          ? this.chartData.ref.setSize(this.chartWidth, this.chartHeight)
          : "";
      }
      this.chartData && this.chartData.ref
        ? this.chartData.ref.setSize(this.chartWidth, this.chartHeight)
        : "";
      if (this.chartType === ChartType.CURRENT) {
        serviceFactory.AuxillaryLineService.drawAuxillaryLineForIc(
          this.chartData,
          this.tempRenderer
        );
      } else if (this.chartType === ChartType.LETTHROUGH) {
        serviceFactory.AuxillaryLineService.drawAuxillaryLineForI2t(
          this.chartData,
          this.tempRenderer
        );
      }
    }, 200);
  }
  ngOnInit() {
    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.Tablet,
        Breakpoints.TabletLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.tablet = true;
        } else if (breakpoints[Breakpoints.Tablet]) {
          this.tablet = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.tablet = true;
        }
      });
  }
  onSelectingProduct(productData: ProductModel) {
    let isExist: boolean = false;
    let selectedCProduct = productData.product;
    if (
      this.chartData &&
      this.chartData.ref &&
      this.chartData.ref.series &&
      this.chartData.ref.series.length > 0
    ) {
      isExist = this.chartData.ref.series.find(
        (x) => x.options.id === selectedCProduct.index
      );
      // for (
      //   let index = 0;
      //   index < this.chartData.ref.series.length - 1;
      //   index++
      // ) {
      //   if (
      //     selectedCProduct.index === this.chartData.ref.series[index].options.id
      //   ) {
      //     isExist = true;
      //   }
      // }
    }
    this.selectedProduct = productData;
    this.isMarkSelected = false;
    let protectiveDevice = this.protectiveDeviceMap.get(selectedCProduct.index);
    if (!isExist) {
      this.isShowDefaultText = false;
      switch (this.chartType) {
        case ChartType.LETTHROUGH:
          {
            if (protectiveDevice.letThroughEnergyCurve) {
              // this.chartData = this.createChartInstance();
              if (this.chartData === undefined) {
                this.chartData = this.createChartInstance();
                this.addSerie(selectedCProduct);
              } else {
                this.removeAllSeries();
                this.addSerie(selectedCProduct);
              }
            } else {
              this.removeAllSeries();
              // this.chartData = undefined;
              this.addEmptySeries();
              this.isShowDefaultText = true;
            }
          }
          break;
        case ChartType.CURRENT:
          {
            if (protectiveDevice.cutOffCurrentCurve) {
              if (this.chartData === undefined) {
                this.chartData = this.createChartInstance();
                this.addSerie(selectedCProduct);
              } else {
                this.removeAllSeries();
                this.addSerie(selectedCProduct);
              }
            } else {
              this.removeAllSeries();
              // this.chartData = undefined;
              this.addEmptySeries();
              this.isShowDefaultText = true;
            }
          }
          break;
        case ChartType.TRIP:
          this.addSerie(selectedCProduct);
      }
    } else {
      this.changeStrokeWidth(selectedCProduct.index);
      this.changeSCLWidth();
    }
    // }
  }
  changeSCLWidth() {
    serviceFactory.SCLHelper.changeSCLWidth(
      this.chartData,
      this.selectedProduct,
      this.selectedItemList
    );
  }
  removeSeries(id: number) {
    serviceFactory.HighChartHelper.removeSeries(id, this.chartData);
  }
  removeAllSeries() {
    serviceFactory.HighChartHelper.removeAllSeries(this.chartData);
  }
  changeStrokeWidth(seriesId: number) {
    serviceFactory.HighChartHelper.changeStrokeWidth(
      seriesId,
      this.chartData,
      this.selectedItemList,
      this.selectedViewMarksList,
      this.selectedProduct,
      this.isMarkSelected
    );
  }
  // changeStrokeWidthMark(seriesId) {
  //   serviceFactory.HighChartHelper.changeStrokeWidthMark(
  //     seriesId,
  //     this.chartData,
  //     this.selectedViewMarksList
  //   );
  // }
  changeLineStyle(lineConfig: LineConfigurationModel) {
    serviceFactory.HighChartHelper.changeLineStyle(lineConfig, this.chartData);
  }
  showElement(item: BaseModel) {
    this.isShowDefaultText = serviceFactory.HighChartHelper.showElement(
      item,
      this.chartData,
      this.isShowDefaultText
    );
  }
  hideElement(item: BaseModel) {
    this.isShowDefaultText = serviceFactory.HighChartHelper.hideElement(
      item,
      this.chartData,
      this.isShowDefaultText
    );
  }
  hideShortCircuitLine(item: BaseModel) {
    this.chartData.ref.xAxis[0].removePlotLine(item.index);
    this.removeSeries(item.index);
    var seriesLength = this.chartData.ref.series.length;
    var shortCircuitLineLength =
      this.chartData.ref.xAxis[0].plotLinesAndBands.length;
    let isSeriesVisible = 0;
    for (var i = 0; i < seriesLength; i++) {
      if (this.chartData.ref.series[i].visible) {
        isSeriesVisible++;
      }
    }
    if (
      (isSeriesVisible == 0 || isSeriesVisible == 1) &&
      shortCircuitLineLength == 0
    ) {
      this.isShowDefaultText = true;
    }
  }
  showHideDefaultText() {
    return (
      (this.selectedItemList.length === 0 &&
        this.selectedViewMarksList.length === 0) ||
      this.isShowDefaultText ||
      this.chartData.ref.series.length === 0
    );
  }
  updateLegendLabel(index: number, updatedLabel: string) {
    let seriesLength = this.chartData.ref.series.length;
    for (let i = 0; i < seriesLength; i++) {
      if (this.chartData.ref.series[i].options.id === index) {
        // this.chartData.ref.series[i].setName(updatedLabel);
        this.chartData.ref.series[i].update({
          name: updatedLabel,
        });
      }
    }
    this.changeStrokeWidth(index);
  }
  async updateChartSeries(
    productData: BaseModel,
    updatedChartdata: ProtectiveDevice
  ) {
    if (this.chartData === undefined) {
      this.chartData = this.createChartInstance();
    }
    if (this.chartType === ChartType.TRIP) {
      let seriesLength = this.chartData.ref.series.length;
      for (let i = 0; i < seriesLength; i++) {
        this.protectiveDeviceMap.set(productData.index, updatedChartdata);
        if (
          this.chartData.ref.series[i].options.id === productData.index &&
          this.chartData.ref.series[i].linkedSeries.length > 0
        ) {
          this.chartData.ref.series[i].setData(
            updatedChartdata.lowerCurveSeriesTrippingChar,
            true,
            true,
            false
          );
          this.chartData.ref.series[i].setName(productData.name);
        } else if (
          this.chartData.ref.series[i].options.id === productData.index &&
          this.chartData.ref.series[i].linkedSeries.length === 0
        ) {
          this.chartData.ref.series[i].setData(
            updatedChartdata.upperCurveSeriesTrippingChar,
            true,
            true,
            false
          );
          this.chartData.ref.series[i].setName(productData.name);
        }
      }
    } else if (this.chartType === ChartType.LETTHROUGH) {
      this.removeSeries(productData.index);
      if (updatedChartdata.letThroughEnergyCurve) {
        this.setChartData(updatedChartdata, productData);
      } else {
        this.addEmptySeries();
        this.isShowDefaultText = true;
      }
    } else if (this.chartType === ChartType.CURRENT) {
      this.removeSeries(productData.index);
      if (updatedChartdata.cutOffCurrentCurve) {
        this.setChartData(updatedChartdata, productData);
      } else {
        this.addEmptySeries();
        this.isShowDefaultText = true;
      }
    }
  }
  async synchronizeDeviceData(
    updatedChartdata: ProtectiveDevice,
    facadeListEntry?: FacadeListEntryModel
  ): Promise<ListEntry> {
    let updatedListEntry: ListEntry = new ListEntry();
    facadeListEntry === undefined
      ? (facadeListEntry = this.selectedProduct.facadeListEntry)
      : "";
    if (this.chartType === ChartType.TRIP) {
      if (updatedChartdata && facadeListEntry.facade.g) {
        facadeListEntry.facade.g = updatedChartdata.device;
        await serviceFactory.FacadeService.writeListEntry(facadeListEntry)
          .then(async (listEntry: string) => {
            let parsedListEntry: ListEntry = JSON.parse(listEntry);
            await serviceFactory.ProductService.synchronizeDeviceData(
              parsedListEntry,
              facadeListEntry,
              updatedChartdata,
              this.projectInfo.id
            )
              .then((res) => {
                facadeListEntry.listEntry = res;
                if (
                  res.index ===
                  this.selectedProduct.facadeListEntry.listEntry.index
                )
                  this.selectedProduct.product.name = res.mLabel;
                updatedListEntry = res;
                return updatedListEntry;
              })
              .catch((err: AxiosError) => {
                if (err.response.data === Constants.SESSIONEXPIRED) {
                  this.sessionExpiredDialog.openDialog();
                } else {
                  this.toasterService.showError(err);
                }
              });
          })
          .catch((err) => {
            this.toasterService.showError(err);
          });
      }
    }
    return updatedListEntry;
  }
  onMouseMove(event) {
    this.curveTracingModel = serviceFactory.HighChartHelper.onMouseMove(
      event,
      this.isShowDefaultText,
      this.chartData,
      this.curveTracingModel,
      this.chartType
    );
  }
  getTooltipText() {
    return serviceFactory.HighChartHelper.getTooltipText(
      this.curveTracingModel,
      this.chartType
    );
  }
  async addSerie(receivedProduct: BaseModel, rangeValue?: SettingsSliderModel) {
    this.removeSeries(this.selectedProduct.product.index);
    this.setChartData(
      this.protectiveDeviceMap.get(receivedProduct.index),
      receivedProduct
    );
    this.changeStrokeWidth(receivedProduct.index);
    this.changeSCLWidth();
  }
  setChartData(response: ProtectiveDevice, receivedPost: BaseModel) {
    if (this.chartType == ChartType.TRIP) {
      this.chartData.addSeries({
        id: receivedPost.index,
        data: response.lowerCurveSeriesTrippingChar,
        color: response.color,
        name: receivedPost.name,
        pointStart: 0,
        marker: {
          enabled: false,
        },
        lineWidth: response.lineWidth,
        dashStyle: response.lineStyle,
        visible: receivedPost.toggleSlashEye,
      });
      this.chartData.addSeries({
        id: receivedPost.index,
        data: response.upperCurveSeriesTrippingChar,
        color: response.color,
        name: receivedPost.name,
        pointStart: 0,
        marker: {
          enabled: false,
        },
        linkedTo: ":previous",
        lineWidth: response.lineWidth,
        dashStyle: response.lineStyle,
        visible: receivedPost.toggleSlashEye,
      });
    } else if (this.chartType == ChartType.LETTHROUGH) {
      if (response.letThroughEnergyCurve) {
        this.chartData.addSeries({
          id: receivedPost.index,
          data: response.letThroughEnergyCurve,
          color: response.color,
          name: receivedPost.voltage,
          pointStart: 0,
          marker: {
            enabled: false,
          },
          lineWidth: response.lineWidth,
          dashStyle: response.lineStyle,
        });
        if (
          this.chartData.ref.yAxis[0].dataMin ===
          this.chartData.ref.yAxis[0].dataMax
        ) {
          this.setYAxisExtremes(
            Math.floor(Math.log10(this.chartData.ref.yAxis[0].dataMin)),
            Math.ceil(Math.log10(this.chartData.ref.yAxis[0].dataMax)) + 1
          );
        } else {
          this.setYAxisExtremes(
            Math.floor(Math.log10(this.chartData.ref.yAxis[0].dataMin)),
            Math.ceil(Math.log10(this.chartData.ref.yAxis[0].dataMax))
          );
        }

        serviceFactory.AuxillaryLineService.drawAuxillaryLineForI2t(
          this.chartData,
          this.tempRenderer
        );
      }
    } else if (this.chartType == ChartType.CURRENT) {
      if (response.cutOffCurrentCurve) {
        this.chartData.addSeries({
          id: receivedPost.index,
          data: response.cutOffCurrentCurve,
          color: response.color,
          name: receivedPost.voltage,
          pointStart: 0,
          marker: {
            enabled: false,
          },
          lineWidth: response.lineWidth,
          dashStyle: response.lineStyle,
        });
        if (
          Math.ceil(Math.log10(this.chartData.ref.yAxis[0].dataMax)) ===
          Math.floor(Math.log10(this.chartData.ref.yAxis[0].dataMin)) + 1
        ) {
          this.setYAxisExtremes(
            Math.floor(Math.log10(this.chartData.ref.yAxis[0].dataMin)),
            Math.ceil(Math.log10(this.chartData.ref.yAxis[0].dataMax)) + 1
          );
        } else {
          this.setYAxisExtremes(
            Math.floor(Math.log10(this.chartData.ref.yAxis[0].dataMin)),
            Math.ceil(Math.log10(this.chartData.ref.yAxis[0].dataMax))
          );
        }
        serviceFactory.AuxillaryLineService.drawAuxillaryLineForIc(
          this.chartData,
          this.tempRenderer
        );
      }
    }
  }
  setYAxisExtremes(dataMin, dataMax) {
    dataMin = dataMin - 1;
    dataMax = dataMax + 1;
    this.chartData.ref.yAxis[0].setExtremes(
      Math.pow(10, dataMin),
      Math.pow(10, dataMax)
    );
  }
  async addMarksInGraph(item: MarkModel) {
    await serviceFactory.MarksHelper.addMarkOnChart(
      item,
      this.chartData,
      this.chartType
    );
  }
  addEmptySeries() {
    if (this.chartData) {
      this.chartData.addSeries({
        data: [
          [100, 100],
          [1000, 1000],
          [10000, 10000],
        ],
        showInLegend: false,
        visible: false,
      });
      this.chartType !== ChartType.TRIP ? (this.isShowDefaultText = true) : "";
      if (this.chartType === ChartType.CURRENT) {
        serviceFactory.AuxillaryLineService.drawAuxillaryLineForIc(
          this.chartData,
          this.tempRenderer
        );
      } else if (this.chartType === ChartType.LETTHROUGH) {
        serviceFactory.AuxillaryLineService.drawAuxillaryLineForI2t(
          this.chartData,
          this.tempRenderer
        );
      }
    }
  }
  createChartInstance(): Chart {
    let chart;
    const BLACK = "#000000",
      WHITE = "#FFFFFF",
      GRAY = "#808080",
      BOLD = "bold",
      CTYPE = "logarithmic";
    let style = {
      fontWeight: "bold",
      fontSize: "1rem",
      fontFamily: "Siemens Sans",
    };
    const H = Highcharts;
    const { wrap, pick } = H;
    H.wrap(H.SVGElement.prototype, "dashstyleSetter", function (proceed) {
      let value = arguments[1];
      let i,
        strokeWidth = this["stroke-width"];
      if (strokeWidth === "inherit") {
        strokeWidth = 1;
      }
      value = value && value.toLowerCase();
      if (value) {
        if (value.indexOf("round") !== -1) {
          this.element.setAttribute("stroke-linecap", "round");
        } else {
          this.element.setAttribute("stroke-linecap", "square");
        }
        const v = value
          .replace("longdashdotdot", "3, 2, 0.5, 2,0.5, 2,")
          .replace("longrounddotdot", "3, 2, 0.5, 2,0.5, 2,")
          .replace("longdashdot", "3, 2, 0.5, 2,")
          .replace("longrounddot", "3, 2, 0.5, 2,")
          .replace("longdash", "4,3,")
          .replace("longround", "4,3,")
          .replace("shortdash", "0.1,3")
          .replace("shortround", "0.1,3,")
          .replace("customrounddot", "0.1,2,0.1,2,0.1,4") // three dot
          .replace(/,$/, "")
          .split(","); // ending comma
        i = v.length;
        while (i--) {
          v[i] = "" + v[i] * pick(strokeWidth, NaN);
        }
        value = v.join(",").replace(/NaN/g, "none"); // #3226
        this.element.setAttribute("stroke-dasharray", value);
      }
    });
    if (this.chartType !== ChartType.TRIP) {
      chart = new Chart({
        chart: {
          type: "line",
          height: this.chartHeight,
          width: this.chartWidth,
          ignoreHiddenSeries: false,
          plotBorderWidth: 1,
          // plotBorderColor: "black",
          animation: false,
          events: {
            click: function (e: any) {
              if (this.curveTracingModel.isToolTipVisible) {
                let formatedXYValues = serviceFactory.MarksHelper.formatXYValue(
                  this.chartData,
                  e.offsetX,
                  e.offsetY
                );
                this.openAddMarkDialog(
                  formatedXYValues[0],
                  formatedXYValues[1]
                );
              }
            }.bind(this),
          },
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        legend: {
          title: {
            text: "",
            style: {
              fontStyle: BOLD,
            },
          },
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          borderWidth: 1,
          floating: true,
          backgroundColor: WHITE,
          itemStyle: {
            width: 200,
          },
        },
        xAxis: {
          startOnTick: true,
          endOnTick: true,
          title: {
            text: this.xLabel,
            style,
          },
          type: CTYPE,
          gridLineColor: BLACK,
          // min:
          //   this.chartData != undefined
          //     ? Math.log10(Math.ceil(Math.min(this.chartData.series) / 10) * 10)
          //     : this.xMin,
          // max: this.xMax,
          // min: this.xMin,
          gridLineWidth: 1,
          lineColor: BLACK,
          minorGridLineColor: GRAY,
          lineWidth: 1,
          tickLength: 0,
          tickInterval: 1,
          minorTickInterval: 0.1,
          minorGridLineWidth: 0.5,
          // animation: false,
          labels: {
            formatter: function () {
              var raise = require("superscript-text");
              let num = Math.log10(Number(this.value));
              let base = "10";
              let final = base + raise(num.toString());
              return final;
            },
            style: {
              fontWeight: BOLD,
              fontFamily: "Siemens Sans",
            },
          },
        },
        yAxis: {
          startOnTick: true,
          endOnTick: true,
          title: {
            text: this.yLabel,
            style,
          },
          // max: this.yMax,
          // min: this.yMin,
          type: CTYPE,
          lineColor: BLACK,
          lineWidth: 1,
          gridLineColor: BLACK,
          minorGridLineColor: GRAY,
          gridLineWidth: 1,
          tickLength: 0,
          tickInterval: 1,
          minorTickInterval: 0.1,
          minorGridLineWidth: 0.5,
          labels: {
            formatter: function () {
              var raise = require("superscript-text");
              let num = Math.log10(Number(this.value));
              let base = "10";
              let final = base + raise(num.toString());
              return final;
            },
            style: {
              fontWeight: BOLD,
            },
          },
        },
        plotOptions: {
          series: {
            stickyTracking: false,
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                opacity: 1,
              },
            },
            animation: false,
            visible: true,
          },
          line: {
            events: {
              legendItemClick: function (event) {
                event.preventDefault();
              },
            },
          },
          // animation: false,
        },
        tooltip: {
          enabled: false,
          formatter: function () {
            return "<b>x=" + this.y + "</b> y= <b>" + this.x + "</b>";
          },
        },
        series: [],
      });
    } else {
      chart = new Chart({
        chart: {
          type: "line",
          height: this.chartHeight,
          width: this.chartWidth,
          ignoreHiddenSeries: false,
          plotBorderWidth: 1,
          // plotBorderColor: "black",
          animation: false,
          events: {
            click: function (e: any) {
              if (this.curveTracingModel.isToolTipVisible) {
                let formatedXYValues = serviceFactory.MarksHelper.formatXYValue(
                  this.chartData,
                  e.offsetX,
                  e.offsetY
                );
                this.openAddMarkDialog(
                  formatedXYValues[0],
                  formatedXYValues[1]
                );
              }
            }.bind(this),
          },
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        legend: {
          title: {
            text: "",
            style: {
              fontStyle: BOLD,
            },
          },
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          borderWidth: 1,
          floating: true,
          backgroundColor: WHITE,
          itemStyle: {
            width: 200,
          },
        },
        xAxis: {
          startOnTick: true,
          endOnTick: true,
          title: {
            text: this.xLabel,
            style,
          },
          type: CTYPE,
          gridLineColor: BLACK,
          scrollbar: {
            enabled: false,
          },
          // min:
          //   this.chartData != undefined
          //     ? Math.log10(Math.ceil(Math.min(this.chartData.series) / 10) * 10)
          //     : this.xMin,
          // min: this.xMin,
          // max: this.xMax,
          // min: this.xMin,
          gridLineWidth: 1,
          lineColor: BLACK,
          minorGridLineColor: GRAY,
          lineWidth: 1,
          tickLength: 0,
          tickInterval: 1,
          minorTickInterval: 0.1,
          minorGridLineWidth: 0.5,
          // animation: false,
          labels: {
            formatter: function () {
              var raise = require("superscript-text");
              let num = Math.log10(this.value as number);
              let base = "10";
              let final = base + raise(num.toString());
              return final;
            },
          },
        },
        yAxis: {
          startOnTick: true,
          endOnTick: true,
          title: {
            text: this.yLabel,
            style,
          },
          max: this.yMax,
          min: this.yMin,
          type: CTYPE,
          lineColor: BLACK,
          lineWidth: 1,
          gridLineColor: BLACK,
          minorGridLineColor: GRAY,
          gridLineWidth: 1,
          tickLength: 0,
          tickInterval: 1,
          minorTickInterval: 0.1,
          minorGridLineWidth: 0.5,
          scrollbar: {
            enabled: false,
          },
          labels: {
            formatter: function () {
              var raise = require("superscript-text");
              let num = Math.log10(this.value as number);
              let base = "10";
              let final = base + raise(num.toString());
              return final;
            },
          },
        },
        plotOptions: {
          series: {
            stickyTracking: false,
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                opacity: 1,
              },
            },
            animation: false,
            visible: true,
          },
          line: {
            events: {
              legendItemClick: function (event) {
                let itemIndex = this.selectedItemList.findIndex(
                  (x) => x.product.index === event.target.options.id
                );
                this.selectedItemList[itemIndex].product.toggleSlashEye =
                  !this.selectedItemList[itemIndex].product.toggleSlashEye;
                // item.facadeListEntry.listEntry.visible = item.product.toggleSlashEye;
                this.eventBus.cast(
                  EventType.SHOW_HIDE_GRAPH,
                  this.selectedItemList[itemIndex]
                );
                // this.synchronizeDeviceData(this.protectiveDeviceMap.get(item.product.index))
                let count = 0;
                this.selectedItemList.forEach((item) => {
                  if (item.product.toggleSlashEye === false) {
                    count++;
                  }
                });
                if (count === this.selectedItemList.length) {
                  this.isShowDefaultText = true;
                } else {
                  this.isShowDefaultText = false;
                }
                return false;
              }.bind(this),
            },
          },

          // animation: false,
        },
        tooltip: {
          enabled: false,
          formatter: function () {
            return "<b>x=" + this.y + "</b> y= <b>" + this.x + "</b>";
          },
        },
        series: [],
      });
    }

    return chart;
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  openAddMarkDialog(x: any, y: any): void {
    let isMarkOnSeriesData =
      this.curveTracingModel.prevSnapPoint !== null ? true : false;
    if (isMarkOnSeriesData) {
      let formatedXYValues = serviceFactory.MarksHelper.formatXYValue(
        this.chartData,
        this.curveTracingModel.prevSnapPoint[0],
        this.curveTracingModel.prevSnapPoint[1]
      );
      y = formatedXYValues[1];
      x = formatedXYValues[0];
    }
    if (this.selectedViewMarksList.length < Constants.MAX_MARKS_COUNT) {
      let markModel = serviceFactory.MarksHelper.createMarkModel(
        x,
        y,
        this.chartType,
        isMarkOnSeriesData
      );
      // if (this.tablet) {
      // } else {
      // if (serviceFactory.MarksHelper.withinRange(x, y, this.chartType)) {
      const dialogRef = this.dialog.open(AddMarkComponentComponent, {
        width: "612px",
        height: "256px",
        data: markModel,
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe(async (updatedMark) => {
        if (updatedMark) {
          this.addMark(updatedMark);
        }
      });
      // } else {
      //   this.toasterService.showError("Not within Limit");
      // }
      // }
    } else {
      this.openDialogForMaxEntry();
    }
  }
  openDialogForMaxEntry() {
    let replaceValue;
    this.translator
      .get("Action_add_maxentries_message")
      .pipe(take(1))
      .subscribe(
        (value: string) => (replaceValue = value.replace("{0}", "10"))
      );
    this.dialogModel.content = replaceValue;
    this.dialogModel.header = "Information";
    this.dialogModel.actions = [
      {
        action: "OK",
        type: "primary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: this.dialogModel,
    });
  }
  async addMark(mark: MarkModel): Promise<MarkModel> {
    if (mark != null && mark.current != null && mark.time != null) {
      mark.ownerIndex = serviceFactory.MarksHelper.getOwnerIndex(
        this.selectedItemList,
        this.chartType,
        this.selectedProduct
      );
      if (
        serviceFactory.MarksHelper.withinRange(
          mark.current,
          mark.time,
          this.chartType
        )
      ) {
        await serviceFactory.MarkService.addMark(this.projectInfo.id, mark)
          .then((markModel: MarkModel[]) => {
            this.selectedViewMarksList = markModel;
            this.eventBus.cast(EventType.UPDATED_MARK_LIST, markModel);
          })
          .catch((err: AxiosError) => {
            if (err.response.data === Constants.SESSIONEXPIRED) {
              this.sessionExpiredDialog.openDialog();
            } else {
              this.toasterService.showError(err);
            }
          });
      } else {
        this.toasterService.showError("toaster-message.markNotWithinLimit");
      }
    }
    return null;
  }
  clickOnCursor(event) {
    this.openAddMarkDialog(
      this.curveTracingModel.xValue,
      this.curveTracingModel.yValue
    );
  }
}
