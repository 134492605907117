import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.css"],
})
export class TooltipComponent {
  @Input() tooltip: string = "";
  @Input() left: number;
  @Input() top: number;
  @Input() display: boolean;
  @Input() snapPoint;
  constructor() {}

  onMouseMoveOnToolTip(event) {
    if (!this.snapPoint) {
      this.left += 5;
      this.top += 5;
    }
  }
}
