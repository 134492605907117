<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M18.3361 5.66126L21.0081 2.98926H14.0081V9.98926L16.9261 7.07026C17.5291 7.66626 18.0321 8.36926 18.3901 9.17226C18.7881 10.0663 18.9891 11.0193 18.9891 12.0073C18.9891 15.8543 15.8591 18.9843 12.0131 18.9843C8.16613 18.9843 5.03613 15.8543 5.03613 12.0073C5.03613 8.50526 7.63313 5.60526 11.0001 5.11226V3.09226C6.52613 3.59726 3.03613 7.40026 3.03613 12.0073C3.03613 16.9573 7.06413 20.9843 12.0131 20.9843C16.9621 20.9843 20.9891 16.9573 20.9891 12.0073C20.9891 10.7383 20.7301 9.51126 20.2171 8.35926C19.7581 7.32826 19.1101 6.42526 18.3361 5.66126Z"
    fill="currentColor"
  />
</svg>
