export enum Constants {
  ICON_DOUBLE_RIGHT = "fa fa-angle-double-right",
  ICON_DOUBLE_LEFT = "fa fa-angle-double-left",
  TRIP = "Tripping Characteristics",
  LETTHROUGH = "Let Through",
  CURRENT = "Current",
  FORWARD = "Std_Fw",
  REVERSE = "Std_Rev",
  TIME = "Time",
  MEDIUM_VOlTAGE = "SettingsPanel_mediumvoltage",
  LOW_VOLTAGE = "SettingsPanel_lowvoltage",
  CURRENT_TRANSFORMER_RATIO = "SettingsPanel_currentchanger",
  PROJECT_OUTPUT = "Project Output",
  INVALID_ORDERNUMBER_ERROR = "Invalid OrderNumber",
  INVALID_PRODUCTGROUP_ERROR = "Invalid Productgroup",
  VALID_VALUE = "ValidValue",
  INVALID_VALUE = "InvalidValue",
  REQUESTED_VALUE = "RequestedValue",
  PRODUCT_GROUP = "ProductGroup",
  ORDER_NUMBER = "OrderNumber",
  PROTECTIVEDEVICE = "Protective devices",
  UPDATEPROJECT = "Edit Project",
  ADDPROJECT = "Add Project",
  ADD = "Add",
  UPDATE = "Update",
  SANDBOXURL = "sandbox",
  LISTENTRY = "listentry",
  DRAFT = "project-definition.draft",
  CONFIGURATIONFILENAME = "configuration.sc",
  AUTOSAVEFILENAME = "autosave.sc",
  HORIZONTALMULTIPLIER = "HORIZONTAL_MULTIPLIER",
  SESSIONEXPIRED = "This session has been expired.",
  SHORTCIRCUITLINE = "shortcircuitline",
  MARK = "mark",
  DEFAULT_NOMINALVOLTAGE = 400,
  EMPTYPRODUCTIMAGE = "../../assets/productGroups/empty.png",
  MAX_DEVICE_COUNT = 20,
  MAX_MARKS_COUNT = 10,
  SHORTCIRCUITLINE_DEFUALT = 0.01,
  HIGHCHART_OFFSETX = 74,
  HIGHCHART_OFFSETY = 10,
  MOVE_UP = "up",
  MOVE_DOWN = "down",
  PRODUCTGROUP_TO_ORDERNUMBER_MAP = "productGroupToOrderNumberMap",
  PRODUCT_GROUP_LENGTH = 3,
  LANGUAGE_PREFIX = "language.",
  COUNTRY_PREFIX = "country.",
  DEFAULT_LOCALE = "en",
  CATALOG_IMG_PATH = "../../../../assets/productGroups/",
}
