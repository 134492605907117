<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M14.4666 7.69167L15.5333 8.975L9.99992 13.5833L4.46659 8.975L5.53325 7.69167L9.16658 10.725V2.5H10.8333V10.725L14.4666 7.69167Z"
    fill="currentColor"
  />
  <path d="M16.6666 17.5V15.8333H3.33325V17.5H16.6666Z" fill="currentColor" />
</svg>
