<mat-sidenav-container
  class="list-entry"
  autosize="true"
  [hasBackdrop]="isOpenSaveProjectSideNav"
>
  <mat-sidenav
    opened
    mode="side"
    position="start"
    role="navigation"
    disableClose
    class="list-entry__device-view scroll-hide"
  >
    <app-device-view-sidebar
      [projectInfo]="projectInfo"
      [nominalVoltageList]="nominalVoltageList"
      [productGroupProposal]="productGroupProposal"
      [catalogTreeData]="catalogTreeData"
    ></app-device-view-sidebar>
  </mat-sidenav>

  <mat-sidenav
    #saveProject
    *ngIf="isOpenSaveProjectSideNav"
    opened
    mode="over"
    position="start"
    class="list-entry__project-save"
  >
    <app-project-details
      [header]="'project-details.saveCurves'"
      [action]="'Action_save'"
    ></app-project-details>
  </mat-sidenav>
  <mat-sidenav-content class="content-div">
    <mat-sidenav-container class="list-entry">
      <mat-sidenav
        #deviceSettingsCatalog
        mode="side"
        position="start"
        role="navigation"
        class="list-entry__device-add"
      >
        <app-device-settings-properties-view
          [selectedProduct]="selectedProduct"
          [projectinfo]="projectInfo"
          [selectedMark]="selectedMark"
          [catalogTreeData]="catalogTreeData"
          [isMarkSelected]="isMarkSelected"
        ></app-device-settings-properties-view>
      </mat-sidenav>
      <mat-sidenav-content
        [ngClass]="deviceSettingsCatalog.opened ? 'sidenav-left' : ''"
        class="list-entry__curves"
      >
        <div class="height_100">
          <!-- <div [hidden]="!isShowChartData">
            <app-info-banner [content]="'warning-banner.draftsSave'" *ngIf="folderId === '1'">
              <div class="flex">
                <a class="list-entry__save-draft" (click)="saveDraft()">{{
                  "warning-banner.chooseFolder" | translate
                  }}</a>
              </div>
            </app-info-banner>
            <app-info-banner [content]="'warning-banner.projectLocked'" [hidden]="!projectInfo.isLocked">
              <span class="bold">({{ "warning-banner.readOnly" | translate }})</span></app-info-banner>
          </div> -->

          <div
            class="highcharts"
            [ngClass]="projectInfo.isLocked ? 'height' : ''"
          >
            <div class="list-entry__curves-header">
              <div>
                <div
                  class="project-name"
                  *ngIf="projectInfo && projectInfo.name"
                >
                  {{ projectInfo.name }}
                </div>
              </div>

              <div>
                <button
                  [ngClass]="
                    isEnableExport()
                      ? 'list-entry__btn-export--disabled'
                      : 'list-entry__btn-export'
                  "
                  [disabled]="isEnableExport()"
                  type="submit"
                  mat-button
                  (click)="onExportClick()"
                >
                  {{ "export" | translate }}
                </button>
              </div>
            </div>
            <div class="charts">
              <app-curve-tab [projectInfo]="projectInfo"> </app-curve-tab>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-sidenav-content>
</mat-sidenav-container>
