<mat-sidenav-container class="sidenav-full" autosize="true">
  <mat-sidenav
    opened
    mode="side"
    position="start"
    role="navigation"
    class="scroll-hide sidenav"
  >
    <div class="flex flex-column justify-space-between h-100">
      <!-- Navigation items -->
      <div class="flex flex-column mt-5">
        <div
          class="position-relative sidenav-full__btn"
          [ngClass]="{ 'bg-active': selectProjDef() }"
          (click)="onProjectDefinitionClick()"
        >
          <span *ngIf="selectProjDef()" class="active-indicator"></span>
          <app-icons [iconName]="'home'"></app-icons>
          <span *ngIf="isSideBarExpanded" class="sidenav-full__text-margin">{{
            "ProjectDefinitionPerspective_name" | translate
          }}</span>
        </div>
        <div
          class="position-relative sidenav-full__btn cursor-default"
          [ngClass]="{ 'bg-active': selectCharCurv() }"
        >
          <span *ngIf="selectCharCurv()" class="active-indicator"></span>
          <app-icons [iconName]="'curves'"></app-icons>
          <span *ngIf="isSideBarExpanded" class="sidenav-full__text-margin">{{
            "CurvesPerspective_name" | translate
          }}</span>
        </div>
      </div>
      <!-- Bottom parts -->
      <div class="flex flex-column">
        <div
          [ngClass]="
            selectInformation() ? 'sidenav-full__btn btn--highlight' : ''
          "
          class="sidenav-full__btn"
          (click)="navigateToInfoPage()"
        >
          <app-icons [iconName]="'info'"></app-icons>
          <span *ngIf="isSideBarExpanded" class="sidenav-full__text-margin">{{
            "navbar.information" | translate
          }}</span>
        </div>
        <div class="sidenav-full__btn">
          <div (click)="onExpandCollapsebutton()">
            <img
              *ngIf="!isSideBarExpanded"
              src="../../assets/icon/arrow-double-right.svg"
            />
            <img
              *ngIf="isSideBarExpanded"
              src="../../assets/icon/arrow-double-left.svg"
            />
            <span *ngIf="isSideBarExpanded" class="sidenav-full__text-margin">{{
              "tooltip.collapseBar" | translate
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content">
      <app-operational-header class="header"></app-operational-header>
      <div class="h-100">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
