import { Injectable, Inject } from "@angular/core";
import { Observable, Subject, interval, Subscription, take } from "rxjs";
import { environment } from "../../../src/environments/environment";

@Injectable()
export class SessionTimerService {
  private readonly _timeoutSeconds: number;
  private _count: number = 0;
  private timerSubscription: Subscription;
  private timer: Observable<number> = interval(1000);
  resetNotifier = new Subject<string>();
  resetNotifier$ = this.resetNotifier.asObservable();

  constructor() {
    this._timeoutSeconds = environment.sessionTime;
  }

  startTimer() {
    this.stopTimer();
    this._count = this._timeoutSeconds;
    this.timerSubscription = this.timer.subscribe((n) => {
      if (this._count > 0) {
        this._count--;
        // this._remainSeconds.next(this._count);
      }
    });
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  resetTimer(value: string) {
    // this.startTimer();
    this.resetNotifier.next(value);
    // this.resetNotifier.complete();
  }
}
