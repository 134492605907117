import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { Constants } from "../../models/constants/Constants";
import { EventType } from "../../models/constants/eventType";
import ProjectInfo from "../../models/drive/projectInfo";
import SpinnerModel from "../../models/common/SpinnerModel";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../services/toaster.service";
@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.css"],
})
export class FileUploadComponent {
  projectInfo(projectInfo: any) {
    throw new Error("Method not implemented.");
  }
  onActionClick() {
    throw new Error("Method not implemented.");
  }
  file: File = null;
  isFileEmpty: boolean = false;
  invalidFile: boolean = false;
  selectedFileName: string | null = null;
  spinnerModel = new SpinnerModel();

  constructor(
    public eventBus: NgEventBus,
    public dialogRef: MatDialogRef<FileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private toasterService: ToasterService,
    private sessionExpiredDialog: SessionExpiredService
  ) {
    dialogRef.disableClose = true;
  }
  cancelEvent() {
    this.dialogRef.close(false);
  }
  delete() {
    this.dialogRef.close(true);
  }

  // ngOnInit(): void { }
  async onUpload() {
    let lockInfo;
    let projectId;

    const isReadable = await this.checkFileReadability(this.file);
    if (!isReadable) {
      let translatedErrorMsg = this.toasterService.toasterMessage(
        "toaster-message.fileWriteProtected"
      );
      this.toasterService.showError(translatedErrorMsg);
      return;
    }
    this.spinnerModel.show = true;
    this.spinnerModel.text = "loader.uploadProject";
    //TODO::Folder Id Removal Migration
    await serviceFactory.ProjectService.uploadFile(this.file, "1")
      .then(async (response: ProjectInfo) => {
        projectId = response.projectId;
        this.eventBus.cast(EventType.ONFILEUPLOADSUCCESS, true);
        this.toasterService.showSuccess("toaster-message.fileUploadSuccess");
      })
      .catch((e: AxiosError) => {
        if (e.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(e.message);
        }
      })
      .finally(async () => {
        this.spinnerModel.show = false;
        this.cancelEvent();
      });
  }

  checkFileReadability(file: File): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(true);
      };
      fileReader.onerror = () => {
        resolve(false);
      };
      fileReader.readAsDataURL(file);
    });
  }

  getfile(event: any) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.isFileEmpty = false;
      this.selectedFileName = this.file ? this.file.name : null;
      if (this.file.name.endsWith(".scu")) {
        this.invalidFile = true;
      } else {
        this.invalidFile = false;
        this.toasterService.showError(
          this.toasterService.toasterMessage(
            "project-definition.upload_invalidFormat"
          )
        );
      }
    } else {
      this.isFileEmpty = true;
    }
  }

  handleDrop(event: DragEvent) {
    event.preventDefault();
    this.file = event.dataTransfer?.files[0];
    if (this.file != null || this.file != undefined) {
      this.selectedFileName = this.file ? this.file.name : null;
      if (this.file.name.endsWith(".scu")) {
        this.invalidFile = true;
      } else {
        this.invalidFile = false;
        this.toasterService.showError(
          this.toasterService.toasterMessage(
            "project-definition.upload_invalidFormat"
          )
        );
      }
    } else {
      this.isFileEmpty = true;
    }
    console.log("File dropped:", this.file);
    console.log("this.isFileEmpty:", this.isFileEmpty);
    console.log("this.invalidFile:", this.invalidFile);
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
  }

  removeFile() {
    this.file = null;
    this.isFileEmpty = true;
    this.selectedFileName = null;
  }
}
