import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService, User } from "@auth0/auth0-angular";
import { Store } from "@ngrx/store";
import { from, Observable } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { LoginConstants } from "../models/constants/loginConstants";
import { serviceFactory } from "../services/serviceLayer/servicefactory/serviceFactory";
import { loadUserData } from "../store/cache.action";
import { UserState } from "../store/cache.reducer";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private store: Store<{ userState: UserState[] }>
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.redirectIfUnauthenticated(state);
  }
  private redirectIfUnauthenticated(
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.user$.pipe(
      mergeMap((user) => {
        if (user) {
          this.dispatchUserDetails(user);
          return from(
            serviceFactory.AuthService.checkUserRegistration(
              user[LoginConstants.GID]
            )
          ).pipe(
            tap((isUserRegistered) => {
              if (!isUserRegistered) {
                this.router.navigate(["/login"], {
                  queryParams: { returnUrl: state.url },
                });
              }
            })
          );
        } else {
          this.router.navigate(["/login"], {
            queryParams: { returnUrl: state.url },
          });
        }
      })
    );
  }
  dispatchUserDetails(user: User) {
    this.store.dispatch(
      loadUserData({
        key: LoginConstants.FIRSTNAME,
        value: user.given_name,
      })
    );
    this.store.dispatch(
      loadUserData({
        key: LoginConstants.LASTNAME,
        value: user.family_name,
      })
    );
    this.store.dispatch(
      loadUserData({
        key: LoginConstants.EMAILID,
        value: user.email,
      })
    );
  }
}
