<div *ngIf="type === 'right'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10.21 17.21L8.79004 15.79L13.09 11.5L8.79004 7.21004L10.21 5.79004L15.91 11.5L10.21 17.21Z"
      fill="currentColor"
    />
  </svg>
</div>

<div *ngIf="type === 'down'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 15.41L6.29004 9.71004L7.71004 8.29004L12 12.59L16.29 8.29004L17.71 9.71004L12 15.41Z"
      fill="currentColor"
    />
  </svg>
</div>
<div *ngIf="type === 'left'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M11.4917 14.3419L6.7417 9.58353L11.4917 4.8252L12.675 6.00853L9.0917 9.58353L12.675 13.1585L11.4917 14.3419Z"
      fill="currentColor"
    />
  </svg>
</div>

<div *ngIf="type === 'up'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M14.4876 12.2583L10.9126 8.67501L7.33763 12.2583L6.1543 11.075L10.9126 6.32501L15.671 11.075L14.4876 12.2583Z"
      fill="currentColor"
    />
  </svg>
</div>
