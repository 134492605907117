<div class="dialog">
  <div class="dialog__header">
    <span>
      {{ data.header | translate }}
    </span>
    <span
      class="icon-close"
      (click)="close()"
      *ngIf="data.header !== 'session-timeout-header'"
    >
      <app-icons [iconName]="'close'"></app-icons>
    </span>
  </div>
  <div class="dialog__content">
    {{ data.content | translate }}
  </div>
  <div>
    <button
      type="submit"
      mat-button
      *ngFor="let action of data.actions"
      [mat-dialog-close]="data"
      [ngClass]="
        action.type === 'primary' &&
        data.header === 'confirmation-dialog.delete'
          ? 'deleteButton'
          : action.type === 'primary'
          ? 'dialog__btn btn--save'
          : 'dialog__btn btn--cancel'
      "
      (click)="onActionClick(action.type)"
    >
      {{ action.action | translate }}
    </button>
  </div>
</div>
