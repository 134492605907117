import { Component, Input, OnInit } from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import BaseModel from "../../models/devices/BaseModel";
import { Constants } from "../../models/constants/Constants";
import { EventType } from "../../models/constants/eventType";
import DeviceSettingsModel from "../../models/device-settings/DeviceSettingsModel";

@Component({
  selector: "app-setting-radio",
  templateUrl: "./setting-radio.component.html",
  styleUrls: ["./setting-radio.component.css"],
})
export class SettingRadioComponent implements OnInit {
  @Input() radioLabels: Array<string>;
  @Input() isDisable: boolean;
  @Input() curveType: string;
  @Input() activeCharacteristic: string;
  @Input() productDetails: BaseModel;
  characteristicMap: Map<string, string> = new Map<string, string>();
  constructor(public eventBus: NgEventBus) {}

  ngOnInit() {
    for (let i = 0; i < this.radioLabels.length; i++) {
      this.characteristicMap.set(this.radioLabels[i], this.radioLabels[i]);
      if (this.radioLabels[i] === Constants.FORWARD) {
        this.characteristicMap.set("forward", Constants.FORWARD);
        this.radioLabels[i] = "forward";
      } else if (this.radioLabels[i] === Constants.REVERSE) {
        this.characteristicMap.set("reverse", Constants.REVERSE);
        this.radioLabels[i] = "reverse";
      }
    }
    if (this.activeCharacteristic === Constants.FORWARD) {
      this.activeCharacteristic = "forward";
    } else if (this.activeCharacteristic === Constants.REVERSE) {
      this.activeCharacteristic = "reverse";
    }
  }
  onRadioSelection(radioValue: string) {
    let characteristicValue: DeviceSettingsModel = new DeviceSettingsModel();
    characteristicValue.curveType = this.curveType;
    characteristicValue.selectedCharacteristic =
      this.characteristicMap.get(radioValue);
    characteristicValue.productDetails = this.productDetails;
    this.eventBus.cast(EventType.EMIT_RADIO_EVENT, characteristicValue);
  }
}
