import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { HttpLoaderFactory } from "../app.module";
import { CustomLoader } from "../language/custom.loader";
import { LanguageMissingTranslationHandler } from "../language/language.missing-translation-handler";
import { LoginPageComponent } from "./login-page.component";
import { UserSelfRegistrationComponent } from "./user-self-registration/user-self-registration.component";
import { IconsModule } from "../icons/icons.module";
import { LoginTermsConditionComponent } from "./login-terms-condition/login-terms-condition.component";
import { RegisterUserWindowComponent } from "./register-user-window/register-user-window.component";
import { EmailWindowComponent } from "./email-window/email-window.component";

@NgModule({
  declarations: [
    LoginPageComponent,
    UserSelfRegistrationComponent,
    LoginTermsConditionComponent,
    RegisterUserWindowComponent,
    EmailWindowComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        useFactory: HttpLoaderFactory,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler,
      },
    }),
  ],
  exports: [
    LoginPageComponent,
    UserSelfRegistrationComponent,
    LoginTermsConditionComponent,
    RegisterUserWindowComponent,
    EmailWindowComponent,
  ],
  providers: [],
  bootstrap: [],
  entryComponents: [],
})
export class LoginModule {}
