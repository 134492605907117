import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import BaseModel from "../../models/devices/BaseModel";

@Component({
  selector: "app-components-header",
  templateUrl: "./components-header.component.html",
  styleUrls: ["./components-header.component.css"],
})
export class ComponentsHeaderComponent {
  @Input() headerName: string;
  @Input() currentSelectedProduct: BaseModel;
  @Input() selectedItemList;
  @Input() bgColor: string;
  @Input() fontColor: string;
  @Input() fontSize: string;
  @Output() emitProductCatalogClick = new EventEmitter();
  @Input() margin: string;
  isExpandNode: boolean = false;
  constructor(private eventBus: NgEventBus) {}
  onProductCatalogClick(event) {
    this.emitProductCatalogClick.emit(event);
  }
}
