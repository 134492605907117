<div class="pad" [ngClass]="isDisable ? 'disabled' : ''">
  <label
    class="col-md-5 pad-2 label-font"
    [ngClass]="isDisable ? 'label--disabled' : ''"
  >
    {{ inputlabel }} {{ units }}</label
  >
  <input
    [ngClass]="isDisable ? 'input-disabled' : ''"
    [ngClass]="
      sliderDetails.minValue === sliderDetails.maxValue ? 'text-readonly' : ''
    "
    step="any"
    class="col-md-5 form-control form-control-sm input"
    [disabled]="isDisable"
    [(ngModel)]="sliderDetails.translatedInputValue"
    (change)="onValueChange($event.target.value)"
    [readonly]="sliderDetails.minValue === sliderDetails.maxValue"
    matTooltip="{{
      '[' +
        sliderDetails.translatedMinValue +
        ' ... ' +
        sliderDetails.translatedMaxValue +
        ']'
    }}"
  />
  <!-- <div
    *ngIf="!isValidNumber"
    class="col-md-1 fa fa-info-circle error-icon"
  ></div> -->
</div>
