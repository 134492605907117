<div
  id="markName"
  #markName
  class="prod-name"
  [ngClass]="selectedMark.isChecked ? 'mark_highlight' : ''"
  (click)="emitSelectedItem(selectedMark)"
>
  <div
    [ngClass]="
      selectedMark.isMarkConfigurationOpen ? 'color-picker-highlight' : ''
    "
  >
    <div
      matTooltip="{{ 'ObjectListView_color_tooltip' | translate }}"
      #colorpalette
      (click)="openConfiguration($event)"
      [style.background-color]="
        selectedMark.visible ? selectedMark.rgbValue : '#B3B3BE'
      "
      [ngClass]="selectedMark.isChecked ? 'prod-name__color-box_highlight' : ''"
      class="prod-name__color-box"
    ></div>
  </div>

  <div
    *ngIf="!selectedMark.isEditMarkLabel"
    class="prod-name__edit"
    (touchstart)="setEditingMode()"
    (dblclick)="setEditingMode()"
  >
    {{ selectedMark.translatedLabel }}
  </div>
  <div class="prod-name__edit-label">
    <input
      *ngIf="selectedMark.isEditMarkLabel"
      [(ngModel)]="markNameLabel"
      type="text"
      class="prod-name__editInput"
      (focusout)="editMarkLabel()"
      (keyup.enter)="editMarkLabel()"
      maxlength="100"
      spellcheck="false"
      style="user-select: none"
    />
  </div>
  <div *ngIf="!selectedMark.visible">
    <app-icons [iconName]="'eye'" [type]="'close'"></app-icons>
  </div>
</div>
