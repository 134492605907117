<div *ngIf="type === 'link'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10 3.33366C11.8409 3.33366 13.3333 4.82604 13.3333 6.66699L13.3333 9.16699L15 9.16699L15 6.66699C15 3.90557 12.7614 1.66699 10 1.66699C7.23858 1.66699 5 3.90557 5 6.66699L5 9.16699L6.66667 9.16699L6.66667 6.66699C6.66667 4.82604 8.15905 3.33366 10 3.33366Z"
      fill="currentColor"
    />
    <path
      d="M10.8333 6.66699L10.8333 13.3337L9.16667 13.3337L9.16667 6.66699L10.8333 6.66699Z"
      fill="currentColor"
    />
    <path
      d="M15 10.8337L15 13.3337C15 16.0951 12.7614 18.3337 10 18.3337C7.23858 18.3337 5 16.0951 5 13.3337L5 10.8337L6.66667 10.8337L6.66667 13.3337C6.66667 15.1746 8.15905 16.667 10 16.667C11.8409 16.667 13.3333 15.1746 13.3333 13.3337L13.3333 10.8337L15 10.8337Z"
      fill="currentColor"
    />
  </svg>
</div>

<div *ngIf="type === 'unlink'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="26"
    viewBox="0 0 20 26"
    fill="none"
  >
    <path
      d="M10 2.33366C11.8409 2.33366 13.3333 3.82604 13.3333 5.66699L13.3333 8.16699L15 8.16699L15 5.66699C15 2.90557 12.7614 0.666992 10 0.666992C7.23858 0.666992 5 2.90557 5 5.66699L5 8.16699L6.66667 8.16699L6.66667 5.66699C6.66667 3.82604 8.15905 2.33366 10 2.33366Z"
      fill="currentColor"
    />
    <path
      d="M15 17.8337L15 20.3337C15 23.0951 12.7614 25.3337 10 25.3337C7.23858 25.3337 5 23.0951 5 20.3337L5 17.8337L6.66667 17.8337L6.66667 20.3337C6.66667 22.1746 8.15905 23.667 10 23.667C11.8409 23.667 13.3333 22.1746 13.3333 20.3337L13.3333 17.8337L15 17.8337Z"
      fill="currentColor"
    />
  </svg>
</div>
