import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  mergeMap, withLatestFrom
} from "rxjs/operators";
import ProductService from "../services/serviceLayer/ProductService";
import {
  loadProduct,
  loadProductFailure,
  loadProductSuccess
} from "./cache.action";
import { ProductState } from "./cache.reducer";
@Injectable()
export class ProductEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProduct),
      withLatestFrom(this.store.select((state) => state.productState)),
      mergeMap(([prod, productState]) => {
        if (
          productState.length > 0 &&
          productState.find(
            (product) => product.productGroup === prod.productGroup
          )
        ) {
          let index = productState.findIndex(
            (product) => product.productGroup === prod.productGroup
          );
          return of(
            loadProductSuccess({
              product: prod.productGroup,
              orderNumber: productState[index].orderNumberList,
            })
          );
        } else {
          return this.productService
            .getOrderNumbersFromProductGroups(prod.productGroup)
            .then((res) => {
              return loadProductSuccess({
                product: prod.productGroup,
                orderNumber: res,
              });
            })
            .catch((err) => {
              return loadProductFailure({
                error: err.message,
                product: prod.productGroup,
              });
            });
        }
      })
    )
  );
  constructor(
    private actions$: Actions,
    private productService: ProductService,
    private store: Store<{ productState: ProductState[] }>
  ) {}
}
