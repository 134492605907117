import { Component, Input, OnChanges, OnInit } from "@angular/core";
import BaseModel from "../models/devices/BaseModel";
import ProductModel from "../models/devices/ProductModel";
import ProjectInfo from "../models/drive/projectInfo";
import MarkModel from "../models/marks/MarkModel";
import { NgEventBus } from "ng-event-bus";
import { EventType } from "../models/constants/eventType";

@Component({
  selector: "app-curve-tab",
  templateUrl: "./curve-tab.component.html",
  styleUrls: ["./curve-tab.component.css"],
})
export class CurveTabComponent {
  @Input() selectedProduct: BaseModel;
  @Input() projectInfo: ProjectInfo;
  superScript = require("superscript-text");
  selectedTab: number = 0;
  constructor(public eventBus: NgEventBus) {}

  changeTab(tabChangeEvent) {
    this.selectedTab = tabChangeEvent.index;
    this.eventBus.cast(EventType.TAB_CHANGE, this.selectedTab);
  }
}
