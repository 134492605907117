<div
  id="productName"
  #productName
  class="prod-name"
  [ngClass]="selectedProduct.isChecked ? 'device_highlight' : ''"
  (click)="emitSelectedItem(selectedProduct)"
>
  <div
    [ngClass]="
      selectedProduct.isLineConfigurationOpen ? 'color-picker-highlight' : ''
    "
  >
    <div
      matTooltip="{{ 'ObjectListView_curve_color_tooltip' | translate }}"
      *ngIf="productData && productData.protectiveDevice"
      #colorpicker
      (click)="openConfiguration($event)"
      [style.background-color]="
        selectedProduct.toggleSlashEye
          ? productData.protectiveDevice.color
          : '#B3B3BE'
      "
      class="prod-name__color-box"
      [ngClass]="
        selectedProduct.isChecked ? 'prod-name__color-box_highlight' : ''
      "
    ></div>
  </div>

  <div
    *ngIf="!selectedProduct.isEditProductLabel"
    class="prod-name__edit"
    (touchstart)="setEditingMode()"
    (dblclick)="setEditingMode()"
  >
    {{ selectedProduct.name }}
  </div>
  <div class="prod-name__edit-label">
    <input
      *ngIf="selectedProduct.isEditProductLabel"
      [(ngModel)]="productNameLabel"
      type="text"
      class="prod-name__editInput"
      (focusout)="editDeviceLabel()"
      (keyup.enter)="editDeviceLabel()"
      maxlength="100"
      spellcheck="false"
    />
  </div>
  <div *ngIf="!selectedProduct.toggleSlashEye">
    <app-icons [iconName]="'eye'" [type]="'close'"></app-icons>
  </div>
</div>
