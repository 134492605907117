import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { AgGridModule } from "ag-grid-angular";
import { HttpLoaderFactory } from "../app.module";
import { IconsModule } from "../icons/icons.module";
import { CustomLoader } from "../language/custom.loader";
import { LanguageMissingTranslationHandler } from "../language/language.missing-translation-handler";
import { MaterialModule } from "../material.module";
import { AgGridComponent } from "./ag-grid/ag-grid.component";
import { DefaultProjectComponent } from "./default-project/default-project.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { IconCellRendererComponentComponent } from "./icon-cell-renderer-component/icon-cell-renderer-component.component";
import { ProjectDefinitionsComponent } from "./project-definitions/project-definitions.component";
import { ProjectDetailsComponent } from "./project-details/project-details.component";
import { ProjectHeaderComponent } from "./project-header/project-header.component";
import { ProjectListComponent } from "./project-list/project-list.component";
import { CommonHelperModule } from "../common/common-helper.module";
import { CounterCharactersPipe } from "./helpers/CharacterCounterPipe";
@NgModule({
  declarations: [
    AgGridComponent,
    DefaultProjectComponent,
    FileUploadComponent,
    ProjectDefinitionsComponent,
    ProjectDetailsComponent,
    ProjectListComponent,
    IconCellRendererComponentComponent,
    ProjectHeaderComponent,
    CounterCharactersPipe,
  ],
  exports: [
    MaterialModule,
    IconsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridComponent,
    DefaultProjectComponent,
    FileUploadComponent,
    ProjectDefinitionsComponent,
    ProjectDetailsComponent,
    ProjectListComponent,
    IconCellRendererComponentComponent,
    ProjectHeaderComponent,
  ],
  imports: [
    MaterialModule,
    CommonHelperModule,
    IconsModule,
    AgGridModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        useFactory: HttpLoaderFactory,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler,
      },
    }),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [],
  entryComponents: [],
})
export class ProjectsModule {}
