import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { AxiosError } from "axios";
import { debounce } from "lodash";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  Subscription,
} from "rxjs";
import DialogModel from "../../models/common/DialogModel";
import { ProductNode } from "../../models/catalog/CatalogProductNodeModel";
import SpinnerModel from "../../models/common/SpinnerModel";
import { Constants } from "../../models/constants/Constants";
import { EventType } from "../../models/constants/eventType";
import BaseModel from "../../models/devices/BaseModel";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../services/toaster.service";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
@Component({
  selector: "app-product-catalog-tree",
  templateUrl: "./product-catalog-tree.component.html",
  styleUrls: ["./product-catalog-tree.component.css"],
})
export class ProductCatalogTreeComponent implements OnChanges, OnDestroy {
  @Input() catalogTreeData: ProductNode;
  TREE_DATA = new Array<ProductNode>();
  selectedNode: ProductNode;
  activeNode: ProductNode;
  isexpandedAll: boolean = true;
  isCollapsedAll: boolean = false;
  isSearchedNode: boolean = false;
  searchedText: string;
  subscriptions: Subscription = new Subscription();
  searchUpdate = new Subject<string>();
  spinnerModel = new SpinnerModel();

  constructor(
    public eventBus: NgEventBus,
    private sessionExpiredDialog: SessionExpiredService,
    private toasterService: ToasterService,
    public dialog: MatDialog
  ) {
    this.searchUpdate
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.onSearchTree(value);
      });
    const ADDPRODUCTFROMPRODUCTCATALOG = this.eventBus
      .on(EventType.ADD_PRODUCT_FROM_PRODUCTCATALOG)
      .subscribe((metadata: MetaData) => {
        if (metadata.data) {
          this.treeControl.collapseAll();
        }
      });
    const ONEDITPRODUCTDETAILS = this.eventBus
      .on(EventType.ONEDITPRODUCTDETAILS)
      .subscribe((metaData: MetaData) => {
        let product: BaseModel = metaData.data.product;
        this.expandNodeToBeEdited(this.dataSource.data, product);
      });
    const OPENADDDEVICESIDEBAR = this.eventBus
      .on(EventType.OPENADDDEVICESIDEBAR)
      .subscribe((metaData: MetaData) => {
        if (metaData.data) {
          this.treeControl.collapseAll();
          this.treeControl.expand(this.treeControl.dataNodes[0]);
        }
      });
    this.subscriptions.add(ADDPRODUCTFROMPRODUCTCATALOG);
    this.subscriptions.add(ONEDITPRODUCTDETAILS);
    this.subscriptions.add(OPENADDDEVICESIDEBAR);
  }
  async ngOnChanges() {
    if (this.catalogTreeData) {
      this.TREE_DATA.push(this.catalogTreeData);
      this.dataSource.data = this.TREE_DATA;
      this.treeControl.expand(this.treeControl.dataNodes[0]);
    }
  }
  ngOnDestroy() {
    this.searchUpdate.complete();
    this.subscriptions.unsubscribe();
  }
  public _transformer = (node: ProductNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      product_group: node.children.length > 0 ? "" : node.product_group,
      img_url: node.img_url,
      parent_id: node.parent_id,
      tree_id: node.tree_id,
    };
  };

  treeControl = new FlatTreeControl<ProductNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: ProductNode) => node.expandable;
  onNodeClick(event: ProductNode) {
    this.selectedNode = event;
    this.activeNode = event;
    this.activeNode.isSearched = false;
    event.expandable
      ? this.onParentNodeClick(event)
      : this.onLeafNodeClick(event);
  }
  expandNodeToBeEdited(nodeData, nodeToBeEdited: BaseModel) {
    nodeData.forEach((node) => {
      if (node.children && node.children.length > 0) {
        this.expandNodeToBeEdited(node.children, nodeToBeEdited);
      } else {
        if (
          node.product_group &&
          nodeToBeEdited.productGroup === node.product_group
        ) {
          this.treeControl.collapseAll();
          this.isSearchedNode = false;
          this.expandProductInTree(this.dataSource.data, node);
        }
      }
    });
  }
  expandProductInTree(nodeData: ProductNode[], matchedNodes: ProductNode) {
    nodeData.forEach((node) => {
      if (matchedNodes.name !== Constants.PROTECTIVEDEVICE) {
        if (
          matchedNodes.product_group &&
          node.children &&
          node.children.find((c) =>
            c.product_group.includes(matchedNodes.product_group)
          )
        ) {
          let selectedNode;
          if (matchedNodes.product_group) {
            selectedNode = node.children.find((c) =>
              c.product_group.includes(matchedNodes.product_group)
            );
          } else {
            selectedNode = node.children.find((c) =>
              c.name.toLowerCase().includes(matchedNodes.name.toLowerCase())
            );
          }
          if (!this.isSearchedNode) {
            this.isSearchedNode = true;
            this.activeNode = this.treeControl.dataNodes.find(
              (d) => d.product_group === selectedNode.product_group
            );
            this.eventBus.cast(EventType.ON_PARENTNODE_CLICK, this.activeNode);
          }
          this.treeControl.expand(
            this.treeControl.dataNodes.find(
              (d) => d.product_group === selectedNode.product_group
            )
          );
          this.treeControl.expand(
            this.treeControl.dataNodes.find(
              (d) => d.name.toLowerCase() === node.name.toLowerCase()
            )
          );
          this.expandProductInTree(this.dataSource.data, node);
        } else if (
          node.children &&
          node.children.find(
            (c) => c.name.toLowerCase() === matchedNodes.name.toLowerCase()
          )
        ) {
          let selectedNode = node.children.find((c) =>
            c.name.toLowerCase().includes(matchedNodes.name.toLowerCase())
          );
          this.treeControl.expand(
            this.treeControl.dataNodes.find(
              (d) => d.name.toLowerCase() === selectedNode.name.toLowerCase()
            )
          );
          this.treeControl.expand(
            this.treeControl.dataNodes.find(
              (d) => d.name.toLowerCase() === node.name.toLowerCase()
            )
          );
          this.expandProductInTree(this.dataSource.data, node);
        } else if (node.children && node.children.find((c) => c.children)) {
          this.expandProductInTree(node.children, matchedNodes);
        }
      }
    });
  }
  onLeafNodeClick(node: ProductNode) {
    this.eventBus.cast(EventType.ON_LEAFNODE_CLICK, node);
  }
  onParentNodeClick(node: ProductNode) {
    this.eventBus.cast(EventType.ON_PARENTNODE_CLICK, node);
  }
  onSearchTree = debounce(async (searchText: string) => {
    if (searchText.length > 0) {
      this.isSearchedNode = false;
      this.spinnerModel.show = true;
      this.treeControl.collapseAll();
      await this.fetchProductGroup();
    } else {
      this.clearProductAttributes();
    }
  }, 500);
  async fetchProductGroup() {
    await serviceFactory.ProductCatalogService.getProductGroupForSearchedPattern(
      this.searchedText
    )
      .then((producGroup) => {
        if (producGroup.length > 0) this.searchForProduct(producGroup);
        else {
          this.clearProductAttributes();
          this.openDialogForNoResults();
        }
      })
      .catch((error: AxiosError) => {
        if (error.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(error.message);
        }
      })
      .finally(() => {
        this.spinnerModel.show = false;
      });
  }
  searchForProduct(producGroup: string) {
    this.activeNode = this.treeControl.dataNodes.find(
      (node) => node.product_group === producGroup
    );
    if (this.activeNode) {
      this.activeNode.isSearched = true;
      this.treeControl.expand(this.activeNode);
      this.eventBus.cast(EventType.ON_LEAFNODE_CLICK, this.activeNode);
      this.expandParentNodesWithTreeId(this.activeNode);
    }
  }

  expandParentNodesWithTreeId(childNode: ProductNode) {
    let parentNode = this.treeControl.dataNodes.find(
      (node) => node.tree_id === childNode.parent_id
    );
    this.treeControl.expand(parentNode);
    if (parentNode && parentNode.parent_id) {
      this.expandParentNodesWithTreeId(parentNode);
    }
  }
  clearProductAttributes() {
    this.eventBus.cast(EventType.SEARCHPRODUCTFROMCATALOG, []);
    this.treeControl.collapseAll();
    this.treeControl.expand(this.treeControl.dataNodes[0]);
  }
  clearSearchTextCatalog() {
    this.searchedText = "";
    this.searchUpdate.next("");
    this.eventBus.cast(EventType.SEARCHPRODUCTFROMCATALOG, []);
  }
  openDialogForNoResults() {
    let dialogModel = new DialogModel();
    dialogModel.content = "grid.no-result";
    dialogModel.header = "CatalogSearchControl_SearchLink";
    dialogModel.actions = [
      {
        action: "OK",
        type: "primary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: dialogModel,
    });
  }
}
