import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class ToasterService {
  constructor(
    private toastr: ToastrService,
    private translator: TranslateService
  ) {}

  showError(message: any) {
    this.toastr.error(message);
  }

  toasterMessage(message: any) {
    let translatedMessage: string = "";
    this.translator
      .get(message)
      .pipe(take(1))
      .subscribe((value) => (translatedMessage = value));
    return translatedMessage;
  }

  showSuccess(message: any) {
    let translatedMessage: string = "";
    this.translator
      .get(message)
      .pipe(take(1))
      .subscribe((value) => (translatedMessage = value));
    this.toastr.success(translatedMessage);
  }
  showInfo(message: any) {
    this.toastr.info(message);
  }
}
