<svg
  width="20"
  height="20"
  viewBox="0 0 23 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M19.6555 3.74668H16.5418V2.9787C16.5418 1.33627 15.2031 0 13.5578 0H9.08176C7.4364 0 6.09775 1.33627 6.09775 2.9787V3.74668H2.98401C1.33865 3.74668 0 5.08295 0 6.72538V17.0213C0 18.6637 1.33865 20 2.98401 20H19.6555C21.3009 20 22.6395 18.6637 22.6395 17.0213V6.72538C22.6395 5.08321 21.3009 3.74668 19.6555 3.74668ZM7.9141 2.97895C7.9141 2.33633 8.43799 1.81338 9.08176 1.81338H13.5578C14.2015 1.81338 14.7254 2.33633 14.7254 2.97895V3.74694H7.9141V2.97895ZM20.8232 17.0213C20.8232 17.6639 20.2993 18.1869 19.6555 18.1869H2.98401C2.34024 18.1869 1.81635 17.6639 1.81635 17.0213V6.72538C1.81635 6.08276 2.34024 5.5598 2.98401 5.5598H19.6555C20.2993 5.5598 20.8232 6.08276 20.8232 6.72538V17.0213Z"
    fill="currentColor"
  />
</svg>
