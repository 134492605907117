<div
  id="searchList"
  class="overlay overlay-width"
  *ngIf="productsList.length > 0"
>
  <div class="search-results">
    <div
      id="deviceName"
      class="prod-name"
      *ngFor="let prod of productsList"
      (click)="onProductSelection(prod, $event)"
      infiniteScroll
      [infiniteScrollDistance]="0.5"
      (scrolled)="onScroll($event)"
      (wheel)="onScroll($event)"
      [scrollWindow]="false"
      [ngClass]="prod.name === selectedProduct.name ? 'device-highlight' : ''"
    >
      {{ prod.name }}
    </div>
  </div>
  <div class="nominal-voltage">
    <div class="header">
      <span>{{ nominalVoltageHeader }}</span>
      <div class="icon-close" (click)="closeConfig()">
        <app-icons [iconName]="'close'" [height]="16" [width]="16"></app-icons>
      </div>
    </div>
    <div class="chips">
      <mat-chip-list selectable [multiple]="false">
        <mat-chip
          (click)="changeNominalVoltage(voltage)"
          *ngFor="let voltage of nominalVoltageList"
          [selected]="voltage === selectedProduct.nominalVoltage"
        >
          {{ voltage }}</mat-chip
        >
      </mat-chip-list>
    </div>

    <div class="actions">
      <button
        type="submit"
        class="btn--primary btn-add"
        (click)="addDevice()"
        [disabled]="selectedProduct.productGroup === undefined"
        [ngClass]="
          selectedProduct.productGroup === undefined ? 'btn-add-disable' : ''
        "
      >
        {{ addDeviceBtnText }}
      </button>
    </div>
  </div>
</div>

<div
  id="searchListDefault"
  class="overlay no-result"
  *ngIf="productsList.length === 0"
>
  {{ noResultFountText }}
</div>
