import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icons",
  templateUrl: "./icons.component.html",
  styleUrls: ["./icons.component.css"],
})
export class IconsComponent {
  @Input() iconName: string;
  @Input() height: number = 20;
  @Input() width: number = 20;
  @Input() type: string;
  constructor() {}
}
