<div *ngIf="type === 'open'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 4V20H18.951L21.985 10H20V6H12.877L10.348 4H2ZM4 6H9.653L12.182 8.001H18V10H5.845L4 15.88V6ZM7.314 12H19.288L17.467 18H5.431L7.314 12Z"
      fill="currentColor"
    />
  </svg>
</div>
<div *ngIf="type === 'close'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 4V20H22V6H12.877L10.348 4H2ZM4 6H9.653L12.182 8.001H20V18H4V6Z"
      fill="currentColor"
    />
  </svg>
</div>
