import { Component, Inject, InjectionToken } from "@angular/core";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import KeyValueModel from "../models/common/KeyValueModel";
import { Constants } from "../models/constants/Constants";
import { EventType } from "../models/constants/eventType";
import LineConfigurationModel from "../models/devices/line-config/LineConfigurationModel";
import LineStyleModel from "../models/devices/line-config/LineStyleModel";
import ProjectInfo from "../models/drive/projectInfo";
import { serviceFactory } from "../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../services/toaster.service";

export const ORDERNUMBER_ID = new InjectionToken<{}>("ORDERNUMBER_ID");
export const ORDERNUMBER_POSITION = new InjectionToken<{}>(
  "ORDERNUMBER_POSITION"
);
export const ORDERNUMBER = new InjectionToken<{}>("ORDERNUMBER");
export const LINECOLOR = new InjectionToken<{}>("LINECOLOR");
export const LINEWIDTH = new InjectionToken<{}>("LINEWIDTH");
export const LINESTYLE = new InjectionToken<{}>("LINESTYLE");
export const PROJECTINFO = new InjectionToken<{}>("PROJECTINFO");
export const PRODUCT_TYPE = new InjectionToken<{}>("PRODUCT_TYPE");

@Component({
  selector: "app-color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.css"],
})
export class ColorPickerComponent {
  presetValues: string[] = [];
  lineStyles: LineStyleModel[];
  lineWidths: KeyValueModel[];
  lineConfig = new LineConfigurationModel();
  selectedLineStyle: string;
  selectedLineWidth = "";

  constructor(
    public eventBus: NgEventBus,
    private toasterService: ToasterService,
    public sessionExpiredDialog: SessionExpiredService,
    @Inject(ORDERNUMBER_ID) public orderNumberID: number,
    @Inject(LINECOLOR) public lineColor: string,
    @Inject(LINEWIDTH) public lineWidth: number,
    @Inject(LINESTYLE) public lineStyle: string,
    @Inject(ORDERNUMBER) public orderNumber: string,
    @Inject(ORDERNUMBER_POSITION) public orderNumberPosition: number,
    @Inject(PROJECTINFO) public projectInfo: ProjectInfo,
    @Inject(PRODUCT_TYPE) public productType: string
  ) {
    //Get list of values
    serviceFactory.LineConfigurationService.getPresetColor().then((data) => {
      this.presetValues = data.map((c) => c.value);
    });
    serviceFactory.LineConfigurationService.getLineStyles().then((data) => {
      this.lineStyles = data;
      this.selectedLineStyle = this.lineStyles.find(
        (x) => x.style === lineStyle
      ).key;
    });
    serviceFactory.LineConfigurationService.getLineWidth().then((data) => {
      this.lineWidths = data;
      this.selectedLineWidth = this.lineWidths.find(
        (x) => x.key === lineWidth.toString()
      ).value;
    });

    this.lineConfig.productIndex = orderNumberID;
    this.lineConfig.orderNumber = orderNumber;
    this.lineConfig.lineColor = lineColor;
    this.lineConfig.lineWidth = lineWidth;
    this.lineConfig.lineStyle = lineStyle;
    this.lineConfig.productType = productType;
  }
  async closeConfig() {
    //TODO::Folder Id Removal Migration
    await serviceFactory.ProductService.synchronizeCurveStyle(
      this.orderNumber,
      this.lineConfig.lineColor,
      this.lineConfig.lineStyle,
      this.lineConfig.lineWidth,
      this.orderNumberPosition,
      this.projectInfo.id
    )
      .then((updatedDevice) => {
        console.log(updatedDevice, "Device after update");
      })
      .catch((err: AxiosError) => {
        if (err.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(err);
          this.lineConfig.lineColor = this.lineColor;
          this.lineConfig.lineWidth = this.lineWidth;
          this.lineConfig.lineStyle = this.lineStyle;
          this.eventBus.cast(EventType.ONLINECONFIGCHANGE, this.lineConfig);
        }
      })
      .finally(() => {
        this.eventBus.cast(EventType.CLOSELINECONFIG, true);
      });
  }
  OnColorChange() {
    this.eventBus.cast(EventType.ONLINECONFIGCHANGE, this.lineConfig);
  }
  onLineStyleChange(lineStyle: LineStyleModel) {
    this.lineConfig.lineStyle = lineStyle.style;
    this.selectedLineStyle = lineStyle.key;
    this.eventBus.cast(EventType.ONLINECONFIGCHANGE, this.lineConfig);
  }
  onLineWidthChange(lineWidth: KeyValueModel) {
    this.lineConfig.lineWidth = parseInt(lineWidth.key);
    this.selectedLineWidth = lineWidth.value;
    this.eventBus.cast(EventType.ONLINECONFIGCHANGE, this.lineConfig);
  }
}
