<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M9.99992 2.5L15.5338 7.111L14.4661 8.39192L10.8333 5.36417V13.5848H9.16658V5.36417L5.53377 8.39192L4.46607 7.111L9.99992 2.5ZM3.33325 15.8333V17.5H16.6666V15.8333H3.33325Z"
    fill="currentColor"
  />
</svg>
