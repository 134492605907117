import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import BaseModel from "../../models/devices/BaseModel";
import { EventType } from "../../models/constants/eventType";
import DeviceSettingsModel from "../../models/device-settings/DeviceSettingsModel";

@Component({
  selector: "app-setting-check",
  templateUrl: "./setting-check.component.html",
  styleUrls: ["./setting-check.component.css"],
})
export class SettingCheckComponent {
  @Input() label: string;
  @Input() curveType: string;
  @Input() selectedProductIndex: number;
  @Input() productDetails: BaseModel;
  @Input() isEngaged: boolean;
  // isCheck: boolean = true;

  constructor(public eventBus: NgEventBus) {}
  onChecked(isChecked: boolean) {
    let setting: DeviceSettingsModel = new DeviceSettingsModel();
    setting.curveType = this.curveType;
    // setting.isDisable = isChecked;
    setting.isEngaged = isChecked;
    setting.productDetails = this.productDetails;
    this.eventBus.cast(EventType.EMIT_CHECKED_EVENT, setting);
  }
}
