<div
  class="d-flex align-items-center justify-content-between py-2 operational-header"
>
  <!-- Logo -->
  <div class="d-flex align-center app-logo">
    <img src="assets/icon/siemens-logo.svg" />
    <span class="application-name">SIMARIS curves</span>
  </div>

  <div class="d-flex align-items-center justify-content-between gap-2">
    <!-- FIXME: Do we need this? The template itself is commented out. -->
    <div class="timer">
      <app-session-timer></app-session-timer>
    </div>

    <!-- Language dropdown -->
    <mat-form-field class="language language-field">
      <div class="d-flex align-items-center gap-1">
        <!-- <span> -->
        <app-icons [iconName]="'language'" class="d-flex"></app-icons>
        <!-- </span> -->
        <span>{{
          langPreFixForTranslation + selectedValue | lowercase | translate
        }}</span>
        <mat-select disableOptionCentering panelClass="language-panel-class">
          <mat-option
            class="option"
            *ngFor="let lan of locale"
            (click)="changeLocaleLanguage(lan)"
          >
            {{ langPreFixForTranslation + lan | lowercase | translate }}
          </mat-option>
        </mat-select>
        <app-icons [iconName]="'arrow'" [type]="'down'"></app-icons>
      </div>
    </mat-form-field>

    <!-- User -->
    <ng-container *ngIf="user$ | async as user">
      <div class="d-flex align-items-center">
        <img
          src="{{ user.picture }}"
          class="navheader_user_img me-2 rounded-circle"
        />
        <span class="user-name"
          >{{ user.family_name }}, {{ user.given_name }}</span
        >
      </div>
    </ng-container>

    <div matTooltip="{{ 'tooltip.logout' | translate }}">
      <app-icons
        class="icon-close"
        [iconName]="'logout'"
        (click)="onLogout()"
      ></app-icons>
    </div>
  </div>
</div>
