<mat-sidenav-container class="sidenav-full" autosize="true">
  <mat-sidenav
    #projectDets
    mode="over"
    disableClose
    position="end"
    role="navigation"
    [autoFocus]="false"
  >
    <!-- <app-project-details></app-project-details> -->
    <router-outlet></router-outlet>
  </mat-sidenav>
  <mat-sidenav-content style="overflow: hidden">
    <div
      class="d-flex flex-column h-100"
      style="padding: 0px var(--main-padding)"
    >
      <div>
        <app-project-header
          [isShowSubHeader]="
            (gridRowData && gridRowData.length > 0) || searchText
          "
          [searchText]="searchText"
          (emitSearchTextEvent)="onSearchTree($event)"
          (emitUploadProjectEvent)="onUploadProject()"
        ></app-project-header>
      </div>
      <div style="flex-grow: 1">
        <app-ag-grid
          *ngIf="
            isTranslationAvailable() && (gridRowData.length > 0 || searchText)
          "
          [rowData]="gridRowData"
          [columnDefs]="gridColumnData"
          (clearFilterEvent)="clearSearchText()"
        ></app-ag-grid>
        <div
          class="h-100"
          *ngIf="
            !searchText || gridRowData === undefined || gridRowData.length === 0
          "
        >
          <app-default-project
            [folderName]="folderName"
            (uploadProjectClick)="onUploadProject()"
          >
          </app-default-project>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
