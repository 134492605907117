<div class="w-100">
  <input
    type="text"
    class="login-input mb-4 mt-0"
    [(ngModel)]="userProfileData.firstName"
    placeholder="*{{ 'pages.login.firstName' | translate }}"
  />
  <input
    type="text"
    class="login-input mb-4 mt-0"
    [(ngModel)]="userProfileData.lastName"
    placeholder="*{{ 'pages.login.lastName' | translate }}"
  />
  <input
    type="email"
    class="login-input mb-4 mt-0"
    placeholder="*{{ 'pages.login.email' | translate }}"
    [(ngModel)]="userProfileData.emailId"
    [disabled]="true"
  />
</div>

<div class="w-100">
  <button
    class="btn btn-dark-success w-100 my-4"
    type="button"
    (click)="registerNewUser()"
    [disabled]="!checkAllFieldsFilled()"
  >
    {{ buttonName.label | translate }}
    <span *ngIf="isRegisterUserLoading" class="spinner-grow spinnerSize"></span>
  </button>
  <div class="d-flex">
    <input
      id="flexCheckDefault"
      class="form-check-input dark me-2 mt-1"
      type="checkbox"
      value="isTermsChecked"
      [(ngModel)]="isTermsChecked"
      (click)="checkTheTermsCondition()"
    />
    <p>
      {{ "pages.login.agree" | translate }}
      <u class="pointer text-dark-success" (click)="showTermsCondition()">{{
        "imp_term_use_header" | translate
      }}</u>
      {{ "pages.login.cloudInfo" | translate }}
    </p>
  </div>
</div>
