<input
  type="email"
  class="login-input mt-5 mb-4"
  placeholder="*{{ 'pages.login.email' | translate }}"
  [(ngModel)]="userProfileData.emailId"
  (ngModelChange)="isUserEmailValidCheck()"
/>
<button
  class="btn btn-dark-success w-100 my-4"
  type="button"
  (click)="checkIfNewUser()"
  [disabled]="!isEmailValid"
>
  {{ buttonName.label | translate }}
  <span *ngIf="isNextButtonClicked" class="spinner-grow spinnerSize"></span>
</button>
