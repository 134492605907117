<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M16.7377 11.1382V9.13824L11.138 9.13824L11.138 3.53857L9.13799 3.53857V9.13824L3.53833 9.13824L3.53833 11.1382H9.13799L9.13799 16.7379H11.138L11.138 11.1382H16.7377Z"
    fill="currentColor"
  />
</svg>
