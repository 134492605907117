<div class="settings-view__header">
  <div class="action_header">
    <div class="settings-view__device-name">
      <div
        class="settings-view__back-btn"
        (click)="collapseSideBar()"
        matTooltip="{{ 'tooltip.collapseBar' | translate }}"
      >
        <app-icons
          class="icon-close"
          [iconName]="'arrow'"
          [type]="'left'"
        ></app-icons>
      </div>
    </div>
    <div class="settings-view__actions">
      <div class="deviceMark-color">
        <div
          *ngIf="
            !isMarkSelected &&
            selectedProduct &&
            selectedProduct.protectiveDevice
          "
          [style.background-color]="selectedProduct.protectiveDevice.color"
          class="col-md-3 settings-view__device-clr"
        ></div>
        <!-- Marks -->
        <div
          *ngIf="isMarkSelected && selectedMark && selectedMark.label"
          [style.background-color]="selectedMark.rgbValue"
          class="col-md-3 settings-view__device-clr"
        ></div>
      </div>
      <div
        class="action-buttons"
        color="primary"
        (click)="showHideDevice()"
        *ngIf="
          (!isMarkSelected &&
            selectedProduct &&
            selectedProduct.product &&
            selectedProduct.product.toggleSlashEye) ||
          (isMarkSelected && selectedMark.visible)
        "
      >
        <div
          class="icon-close"
          matTooltip="{{ 'tooltip.hideDevice' | translate }}"
        >
          <app-icons [iconName]="'eye'" [type]="'open'"></app-icons>
        </div>
      </div>
      <div
        class="col-md-3 action-buttons"
        color="primary"
        (click)="showHideDevice()"
        *ngIf="
          (!isMarkSelected &&
            selectedProduct &&
            selectedProduct.product &&
            !selectedProduct.product.toggleSlashEye) ||
          (isMarkSelected && !selectedMark.visible)
        "
      >
        <div
          class="icon-close"
          matTooltip="{{ 'tooltip.unHideDevice' | translate }}"
        >
          <app-icons [iconName]="'eye'" [type]="'close'"></app-icons>
        </div>
      </div>
      <div
        class="col-md-3 action-buttons"
        color="primary"
        *ngIf="
          (selectedProduct && selectedProduct.product) ||
          (selectedMark && selectedMark.label)
        "
        (click)="deleteDevice()"
      >
        <div class="icon-close" matTooltip="{{ 'Action_remove' | translate }}">
          <app-icons class="icon-delete" [iconName]="'delete'"></app-icons>
        </div>
      </div>
      <div
        class="col-md-3 action-buttons"
        color="primary"
        (click)="onEditProductDetails()"
        *ngIf="
          !isMarkSelected &&
          selectedProduct &&
          selectedProduct.product &&
          selectedProduct.product.type !== 'shortcircuitline'
        "
      >
        <div
          class="icon-close"
          matTooltip="{{ 'headers.editDevice' | translate }}"
        >
          <app-icons [iconName]="'edit'"></app-icons>
        </div>
      </div>
    </div>
  </div>
  <div class="device_name">
    <div class="setting_header">{{ settingsHeader | translate }}</div>
    <div
      *ngIf="
        !isMarkSelected &&
        selectedProduct &&
        selectedProduct.product &&
        !selectedProduct.product.isEditProductLabelInSettings
      "
      (touchstart)="setEditingMode()"
      (dblclick)="setEditingMode()"
      class="prod-name"
    >
      {{ selectedProduct.product.name }}
    </div>
    <div #deviceName class="edit-label">
      <input
        *ngIf="
          selectedProduct &&
          selectedProduct.product &&
          selectedProduct.product.isEditProductLabelInSettings
        "
        [(ngModel)]="productNameLabel"
        maxlength="100"
        type="text"
        class="form-control editName"
        (focusout)="editDeviceLabel()"
        (keyup.enter)="editDeviceLabel()"
        (blur)="
          selectedProduct.product.isEditProductLabelInSettings =
            !selectedProduct.product.isEditProductLabelInSettings
        "
      />
    </div>

    <!-- Marks -->
    <div
      *ngIf="
        isMarkSelected &&
        selectedMark &&
        selectedMark.label &&
        !selectedMark.isEditMarkLabelSetting
      "
      class="prod-name"
      (touchstart)="setMarkEditingMode()"
      (dblclick)="setMarkEditingMode()"
    >
      {{ selectedMark.translatedLabel }}
    </div>
    <div #markName class="edit-label">
      <input
        *ngIf="
          isMarkSelected &&
          selectedMark &&
          selectedMark.label &&
          selectedMark.isEditMarkLabelSetting
        "
        [(ngModel)]="markNameLabel"
        type="text"
        class="form-control editName"
        (focusout)="editmarkLabel()"
        (keyup.enter)="editmarkLabel()"
        maxlength="100"
      />
    </div>
  </div>
</div>
