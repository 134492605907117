import { IconCellRendererComponentComponent } from "../../project-main-view/icon-cell-renderer-component/icon-cell-renderer-component.component";

export const columnDef = [
  {
    headerName: "project",
    field: "name",
    sortable: true,
    unSortIcon: true,
    suppressSizeToFit: true,
    suppressMovable: true,
    minWidth: 300,
    pinned: "left",
    lockPinned: true,
    flex: 3,
    comparator: (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    },
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellRenderer: (params) => {
      if (
        params.data.projectDescription &&
        params.data.projectDescription.value
      )
        return (
          '<div class="h-100"><div class="grid-content"><div class="grid-textoverflow">' +
          params.data.name +
          '</div></div><div class="grid-subcontent"><div class="grid-textoverflow">' +
          params.data.projectDescription.value +
          "</div></div></div>"
        );
      else
        return (
          '<div class="h-100 flex align-center"><div class="grid-textoverflow"><b>' +
          params.data.name +
          "</b></div></div>"
        );
    },
  },
  {
    headerName: "customer",
    field: "client.value",
    sortable: true,
    unSortIcon: true,
    minWidth: 100,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    suppressMovable: true,
    comparator: (valueA, valueB) => {
      if (!valueA && !valueB) {
        return 0;
      } else if (!valueA) {
        return 1;
      } else if (!valueB) {
        return -1;
      } else {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      }
    },
    cellRenderer: (params) => {
      if (params.data.client && params.data.client.value)
        return (
          '<div class=" h-100 flex align-center"><div class="grid-textoverflow">' +
          params.data.client.value +
          "</div></div>"
        );
    },
    // flex: 1,
  },
  {
    headerName: "Country",
    field: "country.value",
    sortable: true,
    unSortIcon: true,
    wrapHeaderText: true,
    minWidth: 100,
    autoHeaderHeight: true,
    suppressMovable: true,
    comparator: (valueA, valueB) => {
      if (!valueA && !valueB) {
        return 0;
      } else if (!valueA) {
        return 1;
      } else if (!valueB) {
        return -1;
      } else {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      }
    },
    cellRenderer: function (params) {
      if (params.data.country && params.data.country.value)
        return (
          '<div class=" h-100 flex align-center">' +
          params.data.country.value +
          "</div>"
        );
    }.bind(this),
  },
  {
    headerName: "created_date",
    field: "createdDate",
    sortable: true,
    unSortIcon: true,
    wrapHeaderText: true,
    minWidth: 100,
    autoHeaderHeight: true,
    suppressMovable: true,
    cellRenderer: (params) => {
      return (
        '<div class="h-100 flex align-center"><div class="grid-textoverflow">' +
        new Date(params.value).toLocaleDateString(
          localStorage.getItem("i18nextLng")
        ) +
        "</div></div>"
      );
    },
    // flex: 1,
  },
  {
    headerName: "modified_date",
    field: "lastUpdatedDate",
    sortable: true,
    unSortIcon: true,
    minWidth: 100,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    suppressMovable: true,
    cellRenderer: (params) => {
      return (
        '<div class="h-100 flex align-center"><div class="grid-textoverflow">' +
        new Date(params.value).toLocaleDateString(
          localStorage.getItem("i18nextLng")
        ) +
        "</div></div>"
      );
    },
    // flex: 1,
  },
  {
    headerName: "ProjectData_UserOffice",
    field: "designOffice.value",
    minWidth: 100,
    sortable: true,
    unSortIcon: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    suppressMovable: true,
    comparator: (valueA, valueB) => {
      if (!valueA && !valueB) {
        return 0;
      } else if (!valueA) {
        return 1;
      } else if (!valueB) {
        return -1;
      } else {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      }
    },
    cellRenderer: (params) => {
      if (params.data.designOffice && params.data.designOffice.value)
        return (
          '<div class=" h-100 flex align-center"><div class="grid-textoverflow">' +
          params.data.designOffice.value +
          "</div></div>"
        );
    },
    // flex: 1,
  },
  {
    headerName: "",
    cellRenderer: IconCellRendererComponentComponent,
    cellRendererSelector: (params) => {},
    flex: 3,
    minWidth: 100,
    pinned: "right",
    lockPinned: true,
  },
];
