import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  ConnectionPositionPair,
  Overlay,
  OverlayRef,
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription, take } from "rxjs";
import { AddMarkComponentComponent } from "../../../../../add-mark-component/add-mark-component.component";
import { ColorPickerComponent } from "../../../../../color-picker/color-picker.component";
import { DeviceCatalogDialogComponent } from "../../../../../device-catalog-component/device-catalog-dialog/device-catalog-dialog.component";
import CatalogDialogModel from "../../../../../models/catalog/CatalogDialogModel";
import { ProductNode } from "../../../../../models/catalog/CatalogProductNodeModel";
import DialogModel from "../../../../../models/common/DialogModel";
import SpinnerModel from "../../../../../models/common/SpinnerModel";
import { ChartType } from "../../../../../models/constants/chartType";
import { Constants } from "../../../../../models/constants/Constants";
import { EventType } from "../../../../../models/constants/eventType";
import { MarkViewType } from "../../../../../models/constants/mark-view-type";
import BaseModel from "../../../../../models/devices/BaseModel";
import FacadeListEntryModel from "../../../../../models/devices/FacadeListEntry";
import ListEntry from "../../../../../models/devices/ListEntry";
import ProductModel from "../../../../../models/devices/ProductModel";
import ProjectInfo from "../../../../../models/drive/projectInfo";
import ListEntryMarkModel from "../../../../../models/marks/GetListEntryModel";
import MarkModel from "../../../../../models/marks/MarkModel";
import {
  NOMINALVOLTAGELIST,
  SearchDeviceOverlayComponent,
} from "../../../../../search-device-overlay/search-device-overlay.component";
import Product from "../../../../../services/../models/devices/product";
import ProtectiveDevice from "../../../../../services/../models/devices/protectiveDevice";
import { serviceFactory } from "../../../../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../../../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../../../../services/toaster.service";
import { ConfirmationDialogComponent } from "../../../../../shared/confirmation-dialog/confirmation-dialog.component";
const positions = [
  new ConnectionPositionPair(
    { originX: "start", originY: "bottom" },
    { overlayX: "start", overlayY: "top" }
  ),
  new ConnectionPositionPair(
    { originX: "start", originY: "top" },
    { overlayX: "start", overlayY: "bottom" }
  ),
];
@Component({
  selector: "app-device-view-sidebar",
  templateUrl: "./device-view-sidebar.component.html",
  styleUrls: ["./device-view-sidebar.component.css"],
  entryComponents: [ColorPickerComponent],
})
export class DeviceViewSidebarComponent implements OnDestroy, OnInit {
  addedProductsList: ProductModel[] = [];
  selectedProductModel: ProductModel = new ProductModel();
  overlayRef: OverlayRef;
  @ViewChild("searchDevice") searchDevice: ElementRef;
  @ViewChild("deviceList") deviceList: ElementRef;
  @ViewChild("markList") markList: ElementRef;
  injector: Injector;
  dialogModel = new DialogModel();
  isFromCatalog: boolean = false;
  selectedViewMarksList: MarkModel[] = [];
  tabChange = 0;
  superScript = require("superscript-text");
  selectedmark: MarkModel = new MarkModel();
  subscriptions: Subscription = new Subscription();
  productToBeEdited: BaseModel = new BaseModel();
  isScrollDeviceList = false;
  isScrollMarkList = false;
  searchedDeviceText: string;
  tablet = false;
  isSearchBoxOpened: boolean = false;
  spinnerModel = new SpinnerModel();
  @Output() emitAddedProductsList = new EventEmitter();
  @Output() emitAddedMarkList = new EventEmitter();
  listEntryList: ListEntry[];
  @Input() projectInfo: ProjectInfo;
  @Input() nominalVoltageList: Number[];
  @Input() productGroupProposal;
  @Input() catalogTreeData: ProductNode;
  constructor(
    private overlay: Overlay,
    public eventBus: NgEventBus,
    public dialog: MatDialog,
    public toasterService: ToasterService,
    private sessionExpiredDialog: SessionExpiredService,
    public translator: TranslateService,
    private responsive: BreakpointObserver
  ) {
    const onAddMark = this.eventBus
      .on(EventType.UPDATED_MARK_LIST)
      .subscribe((metaData: MetaData) => {
        this.addMark(metaData.data, true);
      });
    const onAddProduct = this.eventBus
      .on(EventType.ONADDPRODUCT)
      .subscribe(async (metaData: MetaData) => {
        this.isFromCatalog = false;
        this.onSelectingProductFromSuggestions(metaData.data);
        if (this.tabChange != 0) {
          this.selectedViewMarksList = [];
        }
      });
    const onAddProductFromCatalog = this.eventBus
      .on(EventType.ADD_PRODUCT_FROM_PRODUCTCATALOG)
      .subscribe((metaData: MetaData) => {
        this.isFromCatalog = true;
        this.onSelectingProductFromSuggestions(metaData.data);
      });
    const onDeletePrdSub = this.eventBus
      .on(EventType.EMIT_DELETE_EVENT)
      .subscribe((metadata: MetaData) => {
        let item: BaseModel = metadata.data;
        if (item) {
          this.deleteElement(item);
        }
      });
    const onDeleteMarkSub = this.eventBus
      .on(EventType.EMIT_DELETEMARK_EVENT)
      .subscribe((metadata: MetaData) => {
        let item: MarkModel = metadata.data;
        if (item) {
          this.deleteMark(item);
        }
      });
    const onEditProductDetails = this.eventBus
      .on(EventType.ONEDITPRODUCTDETAILS)
      .subscribe(async (metaData: MetaData) => {
        this.productToBeEdited = metaData.data.product;
        this.eventBus.cast(EventType.EMIT_SELECTED_PRODUCT, metaData.data);
      });
    const onUpdateProductDetails = this.eventBus
      .on(EventType.UPDATEDORDERNUMBERDETAILS)
      .subscribe((metaData: MetaData) => {
        this.onProductEdit(metaData.data);
      });
    const tabChange = this.eventBus
      .on(EventType.TAB_CHANGE)
      .subscribe((metaData: MetaData) => {
        this.tabChange = metaData.data;
        // if (
        //   !this.selectedProductModel.product &&
        //   this.addedProductsList.length > 0
        // ) {
        //   this.onProductSelect(
        //     this.addedProductsList[this.addedProductsList.length - 1]
        //   );
        // }
        this.onProductSelect(this.selectedProductModel);
        this.getMarkListBasedOnView(metaData.data);
      });
    const closeLineConfig = this.eventBus
      .on(EventType.CLOSELINECONFIG)
      .subscribe(() => {
        this.overlayRef.detach();
        this.isSearchBoxOpened = false;
        this.clearSearchText();
      });
    const onEmitListEntryMarkList = this.eventBus
      .on(EventType.EMIT_LISTENTRY_MARK_LIST)
      .subscribe((metaData: MetaData) => {
        this.listEntryList = metaData.data;
        if (this.listEntryList != undefined && this.listEntryList.length > 0) {
          this.createListEntryList();
        }
      });
    const onEmitSelectedMark = this.eventBus
      .on(EventType.HIGHLIGHT_SELECTED_MARK)
      .subscribe((metaData: MetaData) => {
        this.onMarkSelect(metaData.data);
      });
    this.eventBus
      .on(EventType.ORDERNUMBERSELECTEDFROMSEARCHLIST)
      .subscribe((metaData: MetaData) => {
        this.searchedDeviceText = metaData.data.name;
        this.onSearchChange(metaData.data.name);
      });
    this.subscriptions.add(onAddProduct);
    this.subscriptions.add(onAddProductFromCatalog);
    this.subscriptions.add(onDeletePrdSub);
    this.subscriptions.add(onEditProductDetails);
    this.subscriptions.add(onUpdateProductDetails);
    this.subscriptions.add(onAddMark);
    this.subscriptions.add(onDeleteMarkSub);
    this.subscriptions.add(tabChange);
    // this.subscriptions.add(emitUpdatedMark);
    // this.subscriptions.add(editLabelMark);
    this.subscriptions.add(closeLineConfig);
    this.subscriptions.add(onEmitListEntryMarkList);
    this.subscriptions.add(onEmitSelectedMark);
  }
  ngOnInit() {
    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.Tablet,
        Breakpoints.TabletLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.tablet = true;
        } else if (breakpoints[Breakpoints.Tablet]) {
          this.tablet = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.tablet = true;
        } else {
          this.tablet = false;
        }
      });
  }
  scrollToBottom() {
    setTimeout(() => {
      if (this.isScrollDeviceList) {
        this.deviceList.nativeElement.scrollTop =
          this.deviceList.nativeElement.scrollHeight;
      }
      if (this.isScrollMarkList) {
        this.markList.nativeElement.scrollTop =
          this.markList.nativeElement.scrollHeight;
      }
    }, 500);
  }
  scrollDeviceList() {
    this.isScrollDeviceList = true;
    this.isScrollMarkList = false;
    this.scrollToBottom();
  }
  scrollMarksList() {
    this.isScrollMarkList = true;
    this.isScrollDeviceList = false;
    this.scrollToBottom();
  }

  onUserDeviceScroll(event) {
    this.isScrollDeviceList = false;
  }
  onUserMarkScroll(event) {
    this.isScrollMarkList = false;
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  async createListEntryList(): Promise<boolean> {
    for (let i = 0; i < this.listEntryList.length; i++) {
      let product = new BaseModel();
      product.name = this.listEntryList[i].mLabel;
      product.productGroup = this.listEntryList[i].mProductGroupId;
      product.orderNumber = this.listEntryList[i].mlfb;
      product.voltage = this.listEntryList[i].voltage;
      product.ambientTemparature = this.listEntryList[i].ambientTemparature;
      product.ratedVoltage = this.listEntryList[i].ratedVoltage;
      product.productAttributes = this.listEntryList[i].mProduct;
      //To return after all the element is added to the list
      if (i === this.listEntryList.length - 1) {
        return await this.onSelectingProductFromSuggestions(
          product,
          this.listEntryList[i]
        );
      } else {
        this.onSelectingProductFromSuggestions(product, this.listEntryList[i]);
      }
    }
  }
  deleteElement(item: BaseModel) {
    this.dialogModel.content = "confirmation-dialog.content";
    this.dialogModel.header = "confirmation-dialog.delete";
    this.dialogModel.actions = [
      {
        action: "confirmation-dialog.deleteConfirmation",
        type: "primary",
      },
      {
        action: "CANCEL",
        type: "secondary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: this.dialogModel,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        let deletedProduct = this.addedProductsList.find(
          (x) => x.product.index === item.index
        );
        await serviceFactory.ProductService.deleteDevice(
          deletedProduct.facadeListEntry.listEntry.index,
          this.projectInfo.id
        )
          .then(async (response) => {
            await serviceFactory.ProjectService.getImportedDevices(
              this.projectInfo.id,
              Constants.CONFIGURATIONFILENAME
            )
              .then(async (res: ListEntry[]) => {
                this.eventBus.cast(
                  EventType.CONFIRM_DELETE_PRODUCT,
                  deletedProduct
                );
                this.listEntryList = res;
                this.addedProductsList = [];
                await this.createListEntryList().then(async (res: boolean) => {
                  await this.getMarkListBasedOnView(this.tabChange);
                });
                this.eventBus.cast(
                  EventType.EMIT_UPDATED_PRODUCTLIST,
                  this.addedProductsList
                );
              })
              .catch((err: AxiosError) => {
                if (err.response.data === Constants.SESSIONEXPIRED) {
                  this.sessionExpiredDialog.openDialog();
                } else {
                  this.toasterService.showError(err);
                }
              });
          })
          .catch((err: AxiosError) => {
            if (err.response.data === Constants.SESSIONEXPIRED) {
              this.sessionExpiredDialog.openDialog();
            } else {
              this.toasterService.showError(err);
            }
          });
      }
    });
  }

  async onProductSelect(item: ProductModel) {
    this.selectedProductModel = item;
    if (item.product.type !== Constants.SHORTCIRCUITLINE) {
      this.eventBus.cast(EventType.EMIT_SELECTED_PRODUCT, item);
    } else {
      this.eventBus.cast(EventType.ADDSHORTCIRCUITLINE, item);
    }
    this.eventBus.cast(EventType.ON_SETTINGS_ICON_CLICK, item);
    if (this.tabChange !== 0) {
      await this.getMarkListBasedOnView(this.tabChange);
    }
    // setTimeout(() => {
    this.changeCurrentSelectedDevice(item);
    // }, 300);
  }
  async onProductEdit(listEntry: ListEntry) {
    let indexToBeUpdated = this.addedProductsList.findIndex(
      (x) => x.product.index === this.productToBeEdited.index
    );
    if (indexToBeUpdated !== null && indexToBeUpdated !== -1) {
      let productData = this.addedProductsList[indexToBeUpdated];

      productData.product.name = listEntry.mLabel;
      productData.product.productGroup = listEntry.mProductGroupId;
      productData.product.orderNumber = listEntry.mlfb;
      productData.product.voltage = listEntry.voltage;
      productData.product.ambientTemparature = listEntry.ambientTemparature;
      productData.product.ratedVoltage = listEntry.ratedVoltage;
      productData.product.productAttributes = listEntry.mProduct;

      await serviceFactory.FacadeService.readListEntry(listEntry)
        .then(async (facadeListEntry: FacadeListEntryModel) => {
          productData.facadeListEntry = facadeListEntry;
          productData.facadeListEntry.listEntry.visible = listEntry.visible;
          await serviceFactory.FacadeService.getProtectiveDeviceCurve(
            facadeListEntry
          )
            .then((response: ProtectiveDevice) => {
              this.addedProductsList[indexToBeUpdated].protectiveDevice =
                response;

              this.eventBus.cast(
                EventType.CHARTDATATOBEUPDATED,
                this.addedProductsList[indexToBeUpdated]
              );

              this.eventBus.cast(
                EventType.ON_SETTINGS_ICON_CLICK,
                this.addedProductsList[indexToBeUpdated]
              );
            })
            .catch((error: AxiosError) => {
              this.toasterService.showError(error);
            });
        })
        .catch((error: AxiosError) => {
          this.toasterService.showError(error);
        });
    }
    if (this.tabChange != 0) {
      this.getMarkListBasedOnView(this.tabChange);
    }
  }
  async getProductAttributesForOrderNumber(
    item: BaseModel,
    isNewDeviceAdd?: boolean
  ): Promise<Product> {
    let productAttributes = new Product();
    await serviceFactory.ProductService.getProductAttributesFromOrderNumber(
      item.orderNumber,
      item.nominalVoltage.toString()
    )
      .then((attr: Product) => {
        productAttributes = attr;
        return productAttributes;
      })
      .catch((error: AxiosError) => {
        if (error.response?.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          if (isNewDeviceAdd) {
            this.toasterService.showError(
              this.toasterService.toasterMessage("Action_add_notfound_message")
            );
          } else {
            this.toasterService.showError(error.message);
          }
        }
        throw error;
      })
      .finally(() => {});
    return productAttributes;
  }
  changeCurrentSelectedDevice(selectedProduct: ProductModel) {
    this.addedProductsList.forEach((ele) => {
      ele.product.isChecked =
        ele.product.index !== selectedProduct.product.index ? false : true;
    });
    this.selectedViewMarksList.forEach((mark) => {
      mark.isChecked = false;
    });
    this.selectedmark = new MarkModel();
  }
  highLightSelectedDevicePosition(index: number) {
    this.addedProductsList.forEach((ele) => {
      ele.product.isChecked = false;
    });
    this.addedProductsList[index].product.isChecked = true;
  }
  async onSelectingProductFromSuggestions(
    item: BaseModel,
    listEntry?: ListEntry
  ): Promise<boolean> {
    this.spinnerModel.show = true;
    this.spinnerModel.text = "loader.addDevice";
    this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);

    let productData: ProductModel = new ProductModel();
    let isNewDeviceAdd: boolean = false;
    let isProductAdded: boolean = false;
    if (item.name && item.name.length > 3) {
      item.index = this.validateProductIndex();
      item.isChecked = true;
      item.isCardOpen = true;
      item.toggleSlashEye = true;
      item.nominalVoltage = item.nominalVoltage ? item.nominalVoltage : 400;
      item.orderNumber = item.orderNumber ? item.orderNumber : item.name;
      item.ratedVoltage = item.ratedVoltage
        ? item.ratedVoltage
        : item.nominalVoltage;

      if (listEntry === null || listEntry === undefined) {
        isNewDeviceAdd = true;
        //TODO::Folder Id Removal Migration
        await serviceFactory.ProductService.getListEntry(
          this.projectInfo.id,
          item.orderNumber,
          item.ratedVoltage,
          item.ambientTemparature,
          "device",
          this.isFromCatalog
        )
          .then((listEntryResponse) => {
            listEntry = listEntryResponse;
            item.voltage = listEntry.voltage;
            item.ambientTemparature = listEntry.ambientTemparature;
            item.ratedVoltage = listEntry.ratedVoltage;
            item.productAttributes = listEntry.mProduct;
            item.productGroup = listEntry.mProductGroupId;
          })
          .catch((error: AxiosError) => {
            if (error.response && error.response.status === 500) {
              this.toasterService.showError(
                this.toasterService.toasterMessage(
                  "Action_add_notfound_message"
                )
              );
            } else if (error.response.data === Constants.SESSIONEXPIRED) {
              this.sessionExpiredDialog.openDialog();
            } else {
              this.toasterService.showError(error);
            }
          })
          .finally(() => {
            this.spinnerModel.show = false;
            this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
          });
      }

      productData.product = item;
      // this.selectedProductModel.product = item;

      if (listEntry.mProduct.attributes === undefined) {
        productData.product.type = Constants.SHORTCIRCUITLINE;
      }
      if (
        listEntry &&
        this.addedProductsList.length < Constants.MAX_DEVICE_COUNT
      ) {
        this.addedProductsList.push(productData);
        this.scrollDeviceList();
        this.changeCurrentSelectedDevice(
          this.addedProductsList[this.addedProductsList.length - 1]
        );
        // this.emitAddedProductsList.emit(this.addedProductsList);
        this.eventBus.cast(
          EventType.EMIT_UPDATED_PRODUCTLIST,
          this.addedProductsList
        );
        await serviceFactory.FacadeService.readListEntry(listEntry)
          .then(async (facadeListEntry: FacadeListEntryModel) => {
            productData.facadeListEntry = facadeListEntry;
            // productData.facadeListEntry.label = productData.product.orderNumber;
            productData.facadeListEntry.listEntry.visible = listEntry.visible;
            productData.product.toggleSlashEye = listEntry.visible;
            // productData.facadeListEntry.index = facadeListEntry.listEntry.index;
            await serviceFactory.FacadeService.getProtectiveDeviceCurve(
              facadeListEntry
            )
              .then(async (protectiveDevice: ProtectiveDevice) => {
                productData.protectiveDevice = protectiveDevice;
                if (productData.product.type !== Constants.SHORTCIRCUITLINE) {
                  this.eventBus.cast(
                    EventType.EMIT_SELECTED_PRODUCT,
                    productData
                  );
                } else {
                  this.eventBus.cast(
                    EventType.ADDSHORTCIRCUITLINE,
                    productData
                  );
                }
                this.selectedProductModel = productData;
                this.eventBus.cast(
                  EventType.ON_SETTINGS_ICON_CLICK,
                  productData
                );
              })
              .catch((error: AxiosError) => {
                this.toasterService.showError(error);
                this.addedProductsList = this.addedProductsList.filter(
                  (x) => x.product.index !== productData.product.index
                );
                this.addedProductsList[
                  this.addedProductsList.length - 1
                ].product.isChecked = true;
              });
          })
          .catch((error: AxiosError) => {
            this.toasterService.showError(error);
            this.addedProductsList = this.addedProductsList.filter(
              (x) => x.product.index !== productData.product.index
            );
          })
          .finally(() => {
            this.spinnerModel.show = false;
            this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
            isProductAdded = true;
            return isProductAdded;
          });
      } else {
        if (
          this.addedProductsList.length === Constants.MAX_DEVICE_COUNT &&
          isNewDeviceAdd
        )
          this.openDialogForMaxEntry();
      }
    }
    return isProductAdded;
  }

  //to be discussed
  validateProductIndex() {
    let index = Math.floor(Math.random() * 1000) + 1;
    if (
      (this.addedProductsList.length == 0 &&
        this.selectedViewMarksList.length == 0) ||
      !this.addedProductsList.find(
        (item) =>
          item.product.index === index &&
          !this.selectedViewMarksList.find(
            (mark) => mark.markListIndex === index
          )
      )
    ) {
      return index;
    } else {
      this.validateProductIndex();
    }
  }
  createPortalInjector(nominalVoltageList: Number[]) {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: NOMINALVOLTAGELIST, useValue: nominalVoltageList },
      ],
    });
  }
  openDialogForMaxEntry() {
    let replaceValue;
    this.translator
      .get("Action_add_maxentries_message")
      .pipe(take(1))
      .subscribe(
        (value: string) =>
          (replaceValue = value.replace(
            "{0}",
            Constants.MAX_DEVICE_COUNT.toString()
          ))
      );
    this.dialogModel.content = replaceValue;
    this.dialogModel.header = "Information";
    this.dialogModel.actions = [
      {
        action: "OK",
        type: "primary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: this.dialogModel,
    });
  }
  openCatalogDialog() {
    let catalogDialogModel = new CatalogDialogModel();
    catalogDialogModel.catalogTreeData = this.catalogTreeData;
    catalogDialogModel.projectInfo = this.projectInfo;
    catalogDialogModel.isEditDevice = false;
    const dialogRef = this.dialog.open(DeviceCatalogDialogComponent, {
      width: "80%",
      height: "83%",
      data: catalogDialogModel,
      autoFocus: false,
    });
  }
  openSearchResultBox() {
    if (!this.isSearchBoxOpened) {
      this.searchedDeviceText
        ? this.onSearchChange(this.searchedDeviceText)
        : "";
      this.overlayRef = this.overlay.create({
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.searchDevice)
          .withPositions(positions),
        hasBackdrop: true,
        backdropClass: "cdk-overlay-transparent-backdrop",
      });
      const componentPortal = new ComponentPortal(
        SearchDeviceOverlayComponent,
        null,
        this.createPortalInjector(this.nominalVoltageList)
      );
      this.overlayRef.attach(componentPortal);
      this.overlayRef.backdropClick().subscribe(() => {
        this.overlayRef.detach();
        this.isSearchBoxOpened = false;
        this.searchedDeviceText = "";
      });
      this.isSearchBoxOpened = true;
    }
  }
  async addShortCircuitCurrentLine() {
    //TODO::Folder Id Removal Migration
    await serviceFactory.ProductService.getListEntry(
      this.projectInfo.id,
      "",
      null,
      null,
      Constants.SHORTCIRCUITLINE,
      false
    )
      .then(async (listEntry) => {
        let productData: ProductModel = new ProductModel();
        let device = new BaseModel();
        device.name = listEntry.mLabel;
        device.index = this.validateProductIndex();
        device.isChecked = true;
        device.type = Constants.SHORTCIRCUITLINE;
        productData.product = device;
        // this.selectedProductModel = new ProductModel();
        // this.selectedProductModel.product = device;
        await serviceFactory.FacadeService.readListEntry(listEntry)
          .then(async (facadeListEntry: FacadeListEntryModel) => {
            productData.facadeListEntry = facadeListEntry;
            productData.facadeListEntry.listEntry.visible = listEntry.visible;
            productData.product.toggleSlashEye = listEntry.visible;
            productData.facadeListEntry.listEntry.itemType =
              Constants.SHORTCIRCUITLINE;
            await serviceFactory.FacadeService.getProtectiveDeviceCurve(
              facadeListEntry
            )
              .then(async (protectiveDevice: ProtectiveDevice) => {
                productData.protectiveDevice = protectiveDevice;
                if (
                  this.addedProductsList.length < Constants.MAX_DEVICE_COUNT
                ) {
                  this.addedProductsList.push(productData);
                  this.scrollDeviceList();
                  this.selectedProductModel = productData;
                  this.addedProductsList.forEach((ele) => {
                    if (ele.product.index !== productData.product.index) {
                      ele.product.isChecked = false;
                    }
                  });
                  this.eventBus.cast(
                    EventType.EMIT_UPDATED_PRODUCTLIST,
                    this.addedProductsList
                  );
                  // this.emitAddedProductsList.emit(this.addedProductsList);
                  this.eventBus.cast(
                    EventType.ON_SETTINGS_ICON_CLICK,
                    productData
                  );
                  this.eventBus.cast(
                    EventType.ADDSHORTCIRCUITLINE,
                    productData
                  );
                } else {
                  if (this.addedProductsList.length === 20)
                    this.openDialogForMaxEntry();
                }
              })
              .catch((err: AxiosError) => {
                this.toasterService.showError(err);
              });
          })
          .catch((err: AxiosError) => {
            this.toasterService.showError(err);
          });
      })
      .catch((err: AxiosError) => {
        if (err.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(err);
        }
      });
  }
  async assignUpdatedListEntryList(
    listEntryList: ListEntryMarkModel,
    indexToBeMoved: number
  ) {
    this.eventBus.cast(EventType.REMOVE_ALL_SERIES, this.addedProductsList);
    this.addedProductsList = [];
    this.listEntryList = listEntryList.listEntry;
    await this.createListEntryList().then((res: boolean) => {
      this.highLightSelectedDevicePosition(indexToBeMoved);
      this.selectedProductModel = this.addedProductsList[indexToBeMoved];
      if (
        this.selectedProductModel.product.type !== Constants.SHORTCIRCUITLINE
      ) {
        this.eventBus.cast(
          EventType.EMIT_SELECTED_PRODUCT,
          this.selectedProductModel
        );
      } else {
        this.eventBus.cast(
          EventType.ADDSHORTCIRCUITLINE,
          this.selectedProductModel
        );
      }
      this.eventBus.cast(
        EventType.ON_SETTINGS_ICON_CLICK,
        this.selectedProductModel
      );
      this.addMark(listEntryList.markModel);
    });
  }
  async moveDevicePosition(position: string) {
    let currentIndex =
      this.selectedProductModel.facadeListEntry.listEntry.index;
    let currentPosition = this.addedProductsList.findIndex(
      (x) => x.product.index === this.selectedProductModel.product.index
    );
    let updatedPosition =
      position === Constants.MOVE_UP
        ? currentPosition - 1
        : currentPosition + 1;

    let updatedIndex =
      this.addedProductsList[updatedPosition].facadeListEntry.listEntry.index;
    await serviceFactory.ProductService.moveDevicePosition(
      this.projectInfo.id,
      currentIndex,
      updatedIndex,
      serviceFactory.MarksHelper.getViewBasedOnTab(this.tabChange)
    )
      .then(async (response) => {
        this.assignUpdatedListEntryList(response, updatedPosition);
      })
      .catch((err: AxiosError) => {
        if (err.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(err);
        }
      });
  }
  isDisabledUp() {
    let indexToBeUpdated =
      this.selectedProductModel && this.selectedProductModel.product
        ? this.addedProductsList.findIndex(
            (x) => x.product.index === this.selectedProductModel.product.index
          )
        : "";
    if (indexToBeUpdated == 0) {
      return true;
    }
    return false;
  }

  isDisabledDown() {
    let indexToBeUpdated =
      this.selectedProductModel && this.selectedProductModel.product
        ? this.addedProductsList.findIndex(
            (x) => x.product.index === this.selectedProductModel.product.index
          )
        : "";
    if (
      indexToBeUpdated == this.addedProductsList.length - 1 ||
      indexToBeUpdated == -1
    ) {
      return true;
    }
    return false;
  }
  deleteMark(item: MarkModel) {
    this.dialogModel.content = "confirmation-dialog.content";
    this.dialogModel.header = "confirmation-dialog.delete";
    this.dialogModel.actions = [
      {
        action: "confirmation-dialog.deleteConfirmation",
        type: "primary",
      },
      {
        action: "CANCEL",
        type: "secondary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: this.dialogModel,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        // let deletedMark = this.allMarksList.find(
        //   (x) => x.current === item.current && x.time === item.time
        // );
        await serviceFactory.MarkService.deleteMark(
          item.index,
          this.projectInfo.id
        )
          .then(async (res) => {
            await serviceFactory.ProjectService.getImportedDevices(
              this.projectInfo.id,
              Constants.CONFIGURATIONFILENAME
            )
              .then(async (res: ListEntry[]) => {
                this.eventBus.cast(EventType.REMOVE_ALL_DEVICE_MARKS, item);
                this.listEntryList = res;
                this.addedProductsList = [];
                await this.createListEntryList().then(async (res: boolean) => {
                  await this.getMarkListBasedOnView(this.tabChange);
                });
                this.eventBus.cast(
                  EventType.EMIT_UPDATED_PRODUCTLIST,
                  this.addedProductsList
                );
              })
              .catch((err: AxiosError) => {
                if (err.response.data === Constants.SESSIONEXPIRED) {
                  this.sessionExpiredDialog.openDialog();
                } else {
                  this.toasterService.showError(err);
                }
              });
          })
          .catch((err: AxiosError) => {
            if (err.response.data === Constants.SESSIONEXPIRED) {
              this.sessionExpiredDialog.openDialog();
            } else {
              this.toasterService.showError(err);
            }
          });
      }
    });
  }
  async getMarkListBasedOnView(selectedTab: number) {
    let view = serviceFactory.MarksHelper.getViewBasedOnTab(selectedTab);
    let ownerIndex = -1;
    if (view !== MarkViewType.TRIP) {
      ownerIndex = this.selectedProductModel.facadeListEntry.listEntry.index;
    }
    if (
      (this.addedProductsList.length > 0 && view !== MarkViewType.TRIP) ||
      view === MarkViewType.TRIP
    ) {
      await serviceFactory.MarkService.getMarkList(
        this.projectInfo.id,
        view,
        ownerIndex
      )
        .then((markList: MarkModel[]) => {
          this.selectedmark = new MarkModel();
          this.addMark(markList);
        })
        .catch((err: AxiosError) => {
          if (err.response.data === Constants.SESSIONEXPIRED) {
            this.sessionExpiredDialog.openDialog();
          } else {
            this.toasterService.showError(err);
          }
        });
    } else {
      this.addMark([]);
    }
  }
  onMarkSelect(index: number) {
    this.selectedmark = this.selectedViewMarksList.find(
      (x) => x.index === index
    );
    //this.selectedProductModel = new ProductModel();
    this.selectedViewMarksList.forEach((ele) => {
      ele.isChecked = ele.index !== index ? false : true;
    });
    this.addedProductsList.forEach((i) => {
      i.product.isChecked = false;
    });

    this.eventBus.cast(EventType.EMITSELECTEDMARK, this.selectedmark);
    // }
  }

  async addMark(markList: MarkModel[], isNewMarkAdd: boolean = false) {
    this.selectedViewMarksList.forEach((mark) => {
      this.eventBus.cast(EventType.CONFIRM_DELETE_MARK, mark);
    });
    this.selectedViewMarksList = [];
    this.selectedViewMarksList = markList;
    this.scrollMarksList();
    this.selectedViewMarksList.forEach((mark: MarkModel) => {
      mark.translatedLabel = mark.label;
      mark.markListIndex = this.validateProductIndex();
      this.eventBus.cast(EventType.ADD_MARK_IN_GRAPH, mark);
    });
    this.eventBus.cast(
      EventType.SELECTED_VIEW_MARKSLIST,
      this.selectedViewMarksList
    );
    if (
      (isNewMarkAdd &&
        this.selectedViewMarksList[this.selectedViewMarksList.length - 1]) ||
      this.addedProductsList.length === 0
    ) {
      this.selectedViewMarksList[this.selectedViewMarksList.length - 1]
        ? this.eventBus.cast(
            EventType.HIGHLIGHT_SELECTED_MARK,
            this.selectedViewMarksList[this.selectedViewMarksList.length - 1]
              .index
          )
        : "";
    }
  }
  //To be refactored
  addMarkInTab() {
    let markModel = new MarkModel();
    let chartType;
    if (this.tabChange == 0) {
      markModel.view = MarkViewType.TRIP;
      chartType = ChartType.TRIP;
    } else if (this.tabChange == 1) {
      markModel.view = MarkViewType.CURRENT;
      chartType = ChartType.CURRENT;
    } else {
      markModel.view = MarkViewType.ENERGY;
      chartType = ChartType.LETTHROUGH;
    }
    const dialogRef = this.dialog.open(AddMarkComponentComponent, {
      width: "612px",
      height: "256px",
      data: markModel,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        let updatedMark = result;
        if (
          updatedMark.current != null ||
          updatedMark.time != null ||
          updatedMark != null
        ) {
          let orderNumberIndex;
          let owner;
          if (this.tabChange != 0) {
            owner = this.addedProductsList.find(
              (x) => x.product.index === this.selectedProductModel.product.index
            );
            orderNumberIndex = owner.facadeListEntry.listEntry.index;
          } else {
            orderNumberIndex = 0;
          }

          if (orderNumberIndex != -1 || orderNumberIndex != null) {
            if (
              serviceFactory.MarksHelper.withinRange(
                updatedMark.current,
                updatedMark.time,
                chartType
              )
            ) {
              await serviceFactory.MarkService.addMark(
                this.projectInfo.id,
                updatedMark
              ).then((markModel: MarkModel[]) => {
                this.addMark(markModel, true);
              });
            } else {
              this.toasterService.showError(
                "toaster-message.markNotWithinLimit"
              );
            }
          } else {
            this.toasterService.showError("Please Select the Product First");
          }
        }
        return null;
      }
    });
  }
  onSearchChange(searchText: string) {
    this.openSearchResultBox();

    this.searchedDeviceText = searchText;
    this.spinnerModel.show = true;
    this.spinnerModel.text = "loader.searchDevice";
    this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);

    if (this.productGroupProposal) {
      let searchResult =
        serviceFactory.ProductService.mapOrderNumberToBaseModel(
          this.productGroupProposal.getProposalsFor(searchText),
          Constants.ORDER_NUMBER
        );
      this.eventBus.cast(EventType.SEARCHEDPRODUCTRESULTS, searchResult);
    }
  }
  clearSearchText() {
    this.searchedDeviceText = "";
    this.eventBus.cast(EventType.SEARCHEDPRODUCTRESULTS, []);
  }
}
