import { Component, Input, OnInit } from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import { EventType } from "../../models/constants/eventType";
import loginButtonModel from "../user-registration/loginButtonModel";
import UserRegistrationUIModel from "../user-registration/userRegistrationUIModel";
import { serviceFactory } from "../../../app/services/serviceLayer/servicefactory/serviceFactory";
import Registration from "../../models/login/Registration";
import Profile from "../..//models/login/Profile";
import { AuthZTypes } from "../../models/login/AuthZTypes";
import { ToasterService } from "../../../app/services/toaster.service";
import { AxiosError } from "axios";
import { take } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { LoginConstants } from "../../models/constants/loginConstants";

@Component({
  selector: "app-register-user-window",
  templateUrl: "./register-user-window.component.html",
  styleUrls: ["./register-user-window.component.css"],
})
export class RegisterUserWindowComponent {
  @Input() userProfileData: UserRegistrationUIModel =
    new UserRegistrationUIModel();
  @Input() buttonName = new loginButtonModel();
  isTermsChecked = false;
  isRegisterUserLoading: boolean = false;
  constructor(
    public eventBus: NgEventBus,
    private toasterMessage: ToasterService,
    public translator: TranslateService
  ) {}

  async registerNewUser() {
    let newUser: Profile = new Profile();
    newUser.email = this.userProfileData.emailId;
    newUser.givenName = this.userProfileData.firstName;
    newUser.surName = this.userProfileData.lastName;

    this.isRegisterUserLoading = true;
    this.userProfileData.isExternal = this.userProfileData.emailId.includes(
      LoginConstants.INTERNAL_USER_ID
    )
      ? false
      : true;
    this.userProfileData.isExternal
      ? this.registerExternalUser(newUser)
      : this.registerInternalUser(newUser);
  }
  async registerExternalUser(newUser: Profile) {
    await serviceFactory.AuthService.registerExternalNewUser(newUser)
      .then((response: Registration) => {
        this.showToasterBasedOnRegistrationStatus(
          response.registrationStatus,
          newUser.email
        );
      })
      .catch((err: AxiosError) => {
        this.toasterMessage.showError(err);
      })
      .finally(() => {
        this.isRegisterUserLoading = false;
      });
  }
  async registerInternalUser(newUser: Profile) {
    await serviceFactory.AuthService.registerInternalNewUser(newUser)
      .then((response: Registration) => {
        this.showToasterBasedOnRegistrationStatus(
          response.registrationStatus,
          newUser.email
        );
      })
      .catch((err: AxiosError) => {
        this.toasterMessage.showError(err);
      })
      .finally(() => {
        this.isRegisterUserLoading = false;
      });
  }
  checkAllFieldsFilled() {
    return (
      this.userProfileData.firstName &&
      this.userProfileData.lastName &&
      this.isTermsChecked
    );
  }
  showTermsCondition() {
    this.eventBus.cast(EventType.LOGIN_SHOW_TERMS_COND, true);
  }
  checkTheTermsCondition() {
    this.isTermsChecked = !this.isTermsChecked;
  }
  showToasterBasedOnRegistrationStatus(status: string, emailId: string) {
    let pendingVerificationToasterMsg;
    this.translator
      .get("toaster-message.pendingVerification")
      .pipe(take(1))
      .subscribe(
        (value: string) =>
          (pendingVerificationToasterMsg = value.replace("{0}", emailId))
      );
    switch (status) {
      case AuthZTypes.SUBMITTED:
        this.toasterMessage.showSuccess(
          this.toasterMessage.toasterMessage(
            "toaster-message.registrationSuccess"
          )
        );
        this.toasterMessage.showInfo(
          this.toasterMessage.toasterMessage(pendingVerificationToasterMsg)
        );
        break;

      case AuthZTypes.ALREADY_REGISTERED:
        this.toasterMessage.showInfo(
          this.toasterMessage.toasterMessage(
            "toaster-message.alreadyRegistered"
          )
        );
        break;

      case AuthZTypes.PENDING_VERIFICATION:
        this.toasterMessage.showInfo(
          this.toasterMessage.toasterMessage(pendingVerificationToasterMsg)
        );
        break;
      case AuthZTypes.ACCOUNT_ACTIVATED:
        this.toasterMessage.showSuccess(
          this.toasterMessage.toasterMessage(
            "toaster-message.registrationSuccess"
          )
        );
        break;
    }
  }
}
