import KeyValueModel from "../../models/common/KeyValueModel";
import LineStyleModel from "../../models/devices/line-config/LineStyleModel";
import colorList from "../../../app/models/json/preset-colors.json";
import lineWidth from "../../../app/models/json/linewidths.json";
import lineStyle from "../../../app/models/json/lineStyles.json";
export default class LineConfigPresetValueService {
  getPresetColor(): Array<KeyValueModel> {
    return colorList.colorList;
  }
  getLineStyles(): Array<LineStyleModel> {
    return lineStyle.lineStyle;
  }
  getLineWidth(): Array<KeyValueModel> {
    return lineWidth.lineWidth;
  }
}
