<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.99997 5.33301C6.52711 5.33301 5.3333 6.52682 5.3333 7.99967C5.3333 9.47253 6.52711 10.6663 7.99997 10.6663C9.47282 10.6663 10.6666 9.47253 10.6666 7.99967C10.6666 6.52682 9.47282 5.33301 7.99997 5.33301ZM7.99997 6.66634C8.73644 6.66634 9.3333 7.2632 9.3333 7.99967C9.3333 8.73615 8.73644 9.33301 7.99997 9.33301C7.26349 9.33301 6.66663 8.73615 6.66663 7.99967C6.66663 7.2632 7.26349 6.66634 7.99997 6.66634Z"
    fill="currentColor"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.3997 1.33301V2.94367C10.6717 3.07234 10.9324 3.22234 11.1797 3.39301L11.4184 3.25434L12.5737 2.58834L14.973 6.74501L13.819 7.41167L13.5804 7.54834C13.5937 7.69967 13.6004 7.84967 13.6004 7.99967C13.6004 8.14967 13.5937 8.29967 13.5804 8.45101L13.819 8.58767L14.973 9.25434L12.5737 13.411L11.4184 12.745L11.1797 12.6063C10.9324 12.777 10.6717 12.927 10.3997 13.0557V14.6663H5.60037V13.0557C5.32837 12.927 5.0677 12.777 4.82037 12.6063L4.58103 12.745L3.42637 13.411L1.02637 9.25434L2.18103 8.58767L2.41903 8.45101C2.40637 8.29967 2.3997 8.14967 2.3997 7.99967C2.3997 7.84967 2.40637 7.69967 2.41903 7.54834L2.18103 7.41167L1.02637 6.74501L3.42637 2.58834L4.58103 3.25434L4.82037 3.39301C5.0677 3.22234 5.32837 3.07234 5.60037 2.94367V1.33301H10.3997ZM9.06637 3.87301V2.66634H6.9337V3.87301C6.1717 4.06901 5.4917 4.46701 4.95703 5.01167L3.91437 4.40901L2.8477 6.25701L3.8917 6.85967C3.7917 7.22301 3.73303 7.60434 3.73303 7.99967C3.73303 8.39501 3.7917 8.77634 3.8917 9.13967L2.8477 9.74234L3.91437 11.5903L4.95703 10.9877C5.4917 11.5323 6.1717 11.9303 6.9337 12.1263V13.333H9.06637V12.1263C9.82837 11.9303 10.5084 11.5323 11.043 10.9877L12.085 11.5903L13.1524 9.74234L12.1084 9.13967C12.2084 8.77634 12.267 8.39501 12.267 7.99967C12.267 7.60434 12.2084 7.22301 12.1084 6.85967L13.1524 6.25701L12.085 4.40901L11.043 5.01167C10.5084 4.46701 9.82837 4.06901 9.06637 3.87301Z"
    fill="currentColor"
  />
</svg>
