import { Component, HostListener, OnDestroy } from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription } from "rxjs";
import { EventType } from "../../models/constants/eventType";
import SpinnerModel from "../../models/common/SpinnerModel";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
@Component({
  selector: "app-project-definitions",
  templateUrl: "./project-definitions.component.html",
  styleUrls: ["./project-definitions.component.css"],
})
export class ProjectDefinitionsComponent implements OnDestroy {
  isShowSpinner: boolean = false;
  spinnerText: string = "";
  subscriptions: Subscription = new Subscription();
  lastPing = null;

  constructor(
    private eventBus: NgEventBus,
    public idle: Idle,
    private keepalive: Keepalive
  ) {
    const SHOWSPINNER = this.eventBus
      .on(EventType.SHOW_HIDE_SPINNER)
      .subscribe((metadata: MetaData) => {
        let data: SpinnerModel = metadata.data;
        this.isShowSpinner = data.show;
        this.spinnerText = data.text;
      });
    const SESSIONTIMEDOUT = this.eventBus
      .on(EventType.SESSIONTIMEDOUT)
      .subscribe((meta: MetaData) => {
        this.idle.stop();
      });
    idle.setIdle(6);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleStart.subscribe(() => {
      console.log("You've gone idle!");
      this.eventBus.cast(EventType.ISUSERIDLE, true);
    });
    idle.onIdleEnd.subscribe(() => {
      console.log("No Longer Idle!!");
      this.eventBus.cast(EventType.ISUSERIDLE, false);
    });
    keepalive.interval(5);

    keepalive.onPing.subscribe(() => {
      let current = new Date();
      let count = +current - +this.lastPing;
      let lastPingedOn = Math.floor(count / 1000);
      if (lastPingedOn >= 60 || this.lastPing === null) {
        this.lastPing = current;
        serviceFactory.SessionService.keepAliveSession().then((res) => {});
        console.log("Pinged on ", new Date());
      }
    });
    this.idle.watch();
    this.subscriptions.add(SESSIONTIMEDOUT);
    this.subscriptions.add(SHOWSPINNER);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  @HostListener("document:click", ["$event"])
  @HostListener("touchstart", ["$event"])
  async reset() {
    this.idle.watch();
    await serviceFactory.SessionTimerService.resetTimer("reset");
  }

  @HostListener("document:keyup", ["$event"])
  async resetOnKeyPress() {
    this.idle.watch();
    await serviceFactory.SessionTimerService.resetTimer("reset");
  }
}
