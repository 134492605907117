import AxiosClient from "../client/AxiosClient";
import { ResultType } from "../models/Result";
import serviceUrlConstants from "./serviceUrlConstants";

export default class SessionService {
  async keepAliveSession() {
    let url = serviceUrlConstants.getKeepAliveUrl();

    await AxiosClient.post(
      url,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": localStorage.i18nextLng,
        },
        timeout: 30000,
      },
      ""
    )
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            return response;
          }
          case ResultType.FAIL: {
            throw response;
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }
}
