import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgEventBus } from "ng-event-bus";
import { Constants } from "../../models/constants/Constants";
import DialogModel from "../../models/common/DialogModel";
import { EventType } from "../../models/constants/eventType";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { AuthService } from "@auth0/auth0-angular";

@Component({
  selector: "app-operational-header",
  templateUrl: "./operational-header.component.html",
  styleUrls: ["./operational-header.component.css"],
})
export class OperationalHeaderComponent implements OnInit {
  locale: Array<string> = [];
  selectedValue: string = "EN";
  selectedValue1: string = "EN";
  dialogModel = new DialogModel();
  user$: any;
  langPreFixForTranslation = Constants.LANGUAGE_PREFIX;
  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    public router: Router,
    public eventBus: NgEventBus,
    private authService: AuthService
  ) {
    this.user$ = this.authService.user$;
    this.locale = this.getLocaleList();
  }

  ngOnInit() {
    if (!localStorage.getItem("i18nextLng")) {
      this.setApplicationLocale();
    }
    this.translate.setDefaultLang(localStorage.getItem("i18nextLng"));
    this.selectedValue = localStorage.getItem("i18nextLng").toUpperCase();
  }
  setApplicationLocale() {
    let locale = navigator.language.split("-");
    if (locale.length > 0 && locale[0]) {
      let isLocaleExist = this.locale.findIndex(
        (x) => x === locale[0].toUpperCase()
      );
      isLocaleExist !== -1
        ? localStorage.setItem("i18nextLng", locale[0])
        : localStorage.setItem("i18nextLng", Constants.DEFAULT_LOCALE);
    }
  }
  changeLocaleLanguage(locale: string) {
    this.selectedValue = localStorage.getItem("i18nextLng").toUpperCase();
    this.dialogModel.content =
      this.router.url.includes(Constants.LISTENTRY) &&
      this.router.url.includes("/project")
        ? "Action_save_message"
        : "confirmation-dialog.confirmContent";
    this.dialogModel.header = "confirmation-dialog.header";
    // this.dialogModel.component = "confirmation"
    this.dialogModel.actions = [
      {
        action: "confirmation-dialog.proceed",
        type: "primary",
      },
      {
        action: "confirmation-dialog.discard",
        type: "secondary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: this.dialogModel,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedValue = locale;
        localStorage.setItem("i18nextLng", locale.toLowerCase());
        if (
          this.router.url.includes(Constants.LISTENTRY) &&
          this.router.url.includes("/project")
        ) {
          this.eventBus.cast(EventType.SAVECONFIGONLOCALECHANGE, result);
        } else {
          location.reload();
        }
      } else {
        this.selectedValue = localStorage.getItem("i18nextLng").toUpperCase();
      }
    });
  }

  async onLogout() {
    if (
      this.router.url.includes(Constants.LISTENTRY) &&
      this.router.url.includes("/project")
    ) {
      this.eventBus.cast(EventType.AUTOSAVEONLOGOUT, true);
    }
    this.authService.logout();
  }

  getLocaleList() {
    return [
      "BG",
      "CS",
      "DA",
      "DE",
      "EL",
      "EN",
      "ES",
      "FI",
      "FR",
      "HR",
      "IT",
      "LT",
      "NL",
      "PL",
      "PT",
      "RO",
      "RU",
      "SL",
      "SR",
      "TR",
      "ZH",
    ];
  }
}
