<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M13.9996 2.00018H2.0006L2 22H13.999L13.9996 18.0002H11.9996V20.0002H3.9996L4 3.99998L11.9996 4.00018V6.00018H13.9996V2.00018Z"
    fill="currentColor"
  />
  <path
    d="M18.1706 11L15.8786 8.70698L17.2926 7.29298L21.9996 12L17.2926 16.707L15.8786 15.293L18.1706 13H7V11H18.1706Z"
    fill="currentColor"
  />
</svg>
