import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { NgEventBus } from "ng-event-bus";
import CatalogDialogModel from "../../models/catalog/CatalogDialogModel";
import SpinnerModel from "../../models/common/SpinnerModel";

@Component({
  selector: "app-device-catalog-dialog",
  templateUrl: "./device-catalog-dialog.component.html",
  styleUrls: ["./device-catalog-dialog.component.css"],
})
export class DeviceCatalogDialogComponent implements OnInit {
  selectedTab: number = 0;
  searchedText: string;
  header: string = "curves-view.addDevice";
  spinnerModel = new SpinnerModel();

  constructor(
    public dialogRef: MatDialogRef<DeviceCatalogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CatalogDialogModel,
    public eventBus: NgEventBus
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.header = this.data.isEditDevice
      ? "curves-view.editDevice"
      : "curves-view.addDevice";
  }
  // onTabClick(tabChangeEvent: MatTabChangeEvent) {
  //   this.selectedTab = tabChangeEvent.index;
  // }
  close() {
    this.dialogRef.close();
  }
  showSpinner(spinner: SpinnerModel) {
    this.spinnerModel = spinner;
  }
}
