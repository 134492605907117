<div class="parent" [hidden]="data.length === 0">
  <div class="product">
    <div class="product__left">
      <div>
        <app-product-image> </app-product-image>
      </div>
      <div class="product-name" *ngIf="selectedNode && selectedNode.name">
        <div class="product__left__selectedNode-name">
          {{ selectedNode.name }}
        </div>
        <div
          *ngIf="
            selectedNode &&
            selectedNode.name &&
            data.length > 0 &&
            catalogEnvironmentVariables
          "
          class="mlfb"
        >
          <span *ngIf="catalogEnvironmentVariables.mlfb.includes('_')">
            <img height="20" width="18" src="../../assets/icon/warning.svg" />
          </span>
          {{ catalogEnvironmentVariables.mlfb }}
        </div>
      </div>
    </div>
    <div class="attributes">
      <div
        class="attributes_header"
        *ngIf="selectedNode && selectedNode.name && data.length > 0"
      >
        {{ "CatalogPropertyControl_AttributeValue" | translate }}
        <div
          class="reset--btn"
          matTooltip="{{ 'CatalogSearchControl_ResetLink' | translate }}"
          (click)="resetAttributes()"
        >
          <app-icons [iconName]="'reset'"></app-icons>
        </div>
      </div>
      <div class="attributes-list">
        <div *ngFor="let d of data" class="d-flex align-center margin-l">
          <div class="warning d-flex">
            <span [hidden]="d.requested_value.displayValue">
              <img height="20" width="18" src="../../assets/icon/warning.svg" />
            </span>
          </div>
          <div class="w-100">
            <mat-label class="attributes__label">
              <div>
                {{ d.key }} <span *ngIf="d.unit">[{{ d.unit }}]</span>
              </div>
            </mat-label>
            <mat-form-field
              appearance="outline"
              class="w-100"
              *ngIf="
                d.values ||
                (d.values &&
                  d.requested_value &&
                  d.requested_value.displayValue)
              "
            >
              <mat-select
                [(ngModel)]="d.requested_value.displayValue"
                disableOptionCentering
                panelClass="attributes-panel-class"
              >
                {{ val }}
                <mat-option
                  *ngFor="let val of d.values"
                  [value]="val.displayValue"
                  (click)="onAttributesSelection(d, val)"
                  [ngClass]="
                    val.type === CONSTANT.INVALID_VALUE
                      ? 'attributes__invalid-values'
                      : val.type === CONSTANT.REQUESTED_VALUE
                      ? 'attributes__requested-value'
                      : ''
                  "
                  >{{ val.displayValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <mat-form-field
              appearance="outline"
              class="w-100"
             
            > -->
            <input
              class="text-readonly"
              *ngIf="d.requested_value && !d.values"
              type="text"
              [value]="d.requested_value.displayValue"
              [readonly]="true"
            />
            <!-- </mat-form-field> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="attributes__action"
    *ngIf="selectedNode && selectedNode.name && data.length > 0"
  >
    <button
      type="submit"
      mat-button
      class="attributes__btn btn--secondary"
      (click)="onCancelCatalog()"
    >
      {{ "CANCEL" | translate }}
    </button>
    <button
      type="submit"
      mat-button
      class="attributes__btn btn--primary"
      (click)="addOrderNumber()"
      [disabled]="!isValidOrderNumber && !isGetMlfbCompleted"
      [ngClass]="
        isValidOrderNumber && isGetMlfbCompleted
          ? 'attributes__btn btn--primary'
          : 'attributes__btn btn--disabled'
      "
    >
      {{ mode | translate }}
    </button>
  </div>
</div>
<div class="default-text" [hidden]="data.length > 0">
  {{ "CatalogView_hint" | translate }}
</div>
