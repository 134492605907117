import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { NgEventBus } from "ng-event-bus";
import { EventType } from "../../models/constants/eventType";
import loginButtonModel from "../user-registration/loginButtonModel";
import UserRegistrationUIModel from "../user-registration/userRegistrationUIModel";

@Component({
  selector: "app-user-self-registration",
  templateUrl: "./user-self-registration.component.html",
  styleUrls: ["./user-self-registration.component.css"],
})
export class UserSelfRegistrationComponent implements OnInit{
  buttonName = new loginButtonModel();
  userProfileData: UserRegistrationUIModel = new UserRegistrationUIModel();
  @Input() userPreFillData: UserRegistrationUIModel =
    new UserRegistrationUIModel();
  @Input() isNewUser = false;

  constructor(public eventBus: NgEventBus, private auth: AuthService) {
    this.buttonName.label = "pod_Message_buttonContinue";
    this.buttonName.value = "NEXT";
  }

  ngOnInit() {
    if (
      this.userPreFillData.firstName != undefined &&
      this.userPreFillData.lastName != undefined &&
      this.userPreFillData.emailId != undefined
    ) {
      this.isNewUser = true;
      this.userProfileData.firstName = this.userPreFillData.firstName;
      this.userProfileData.lastName = this.userPreFillData.lastName;
      this.userProfileData.emailId = this.userPreFillData.emailId;
      this.buttonName = {
        label: "pages.login.register",
        value: "REGISTER",
      };
      // } else {
      //   this.emptyFields();
      // }
    }
  }
  // emptyFields() {
  //   this.userProfileData = new UserRegistrationUIModel();
  //   this.isTermsChecked = false;
  //   this.firstName = "";
  //   this.lastName = "";
  // }
  navigateBack() {
    if (this.isNewUser) {
      this.isNewUser = false;
      this.buttonName.label = "pod_Message_buttonContinue";
      this.buttonName.value = "NEXT";
    } else {
      this.eventBus.cast(EventType.LOGIN_NAVIGATE_BACK, true);
    }
  }
  setUserProfileData(newUserProfile: UserRegistrationUIModel) {
    this.userProfileData = newUserProfile;
    this.isNewUser = this.userProfileData.isNewUser;
    if (this.isNewUser) {
      this.buttonName.value = "REGISTER";
      this.buttonName.label = "pages.login.register";
    }
  }
  redirectToLogin() {
    this.auth.loginWithRedirect();
  }
}
