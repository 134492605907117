<div>
  <div class="mainHeader">
    <app-icons [iconName]="'info'"></app-icons> Information
  </div>
  <div class="footer__font">
    <img src="../../assets/icon/Copyrights.svg" />
    {{ siemens | translate }}
    <img src="../../assets/icon/Dot.svg" />
    {{ webSCUVersion | translate }} {{ version }}
  </div>
  <div>
    <div class="innerContents">
      <div class="hyper-link" (click)="onCorporateInfoClick()">
        {{ "imp_corp_info_header" | translate }}
        <app-icons [iconName]="'arrow'" [type]="'right'"></app-icons>
      </div>
    </div>
    <div class="innerContents">
      <div class="hyper-link" (click)="onPrivacyPolicyClick()">
        {{ "imp_privacy_header" | translate }}
        <app-icons [iconName]="'arrow'" [type]="'right'"></app-icons>
      </div>
    </div>
    <div class="innerContents">
      <div class="hyper-link" (click)="onCookiePolicyClick()">
        {{ cookiePolicy | translate }}
        <app-icons [iconName]="'arrow'" [type]="'right'"></app-icons>
      </div>
    </div>
    <div class="innerContents">
      <div class="hyper-link" (click)="onTermsOfUseClick()">
        {{ "imp_term_use_header" | translate }}
        <app-icons [iconName]="'arrow'" [type]="'right'"></app-icons>
      </div>
    </div>
    <div class="innerContents">
      <div class="hyper-link" (click)="onDigitalIDClick()">
        {{ digitalID | translate }}
        <app-icons [iconName]="'arrow'" [type]="'right'"></app-icons>
      </div>
    </div>
  </div>
</div>
