import { LocationStrategy } from "@angular/common";
import { Component, HostListener, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { CookieService } from "ngx-cookie-service";
import { Observable, Observer, Subscription, take } from "rxjs";
import { CanComponentDeactivate } from "src/app/guards/routing.guard";
import { ProductNode } from "src/app/models/catalog/CatalogProductNodeModel";
import DialogModel from "../../../../../models/common/DialogModel";
import SpinnerModel from "../../../../../models/common/SpinnerModel";
import { Constants } from "../../../../../models/constants/Constants";
import { EventType } from "../../../../../models/constants/eventType";
import { MarkViewType } from "../../../../../models/constants/mark-view-type";
import OrderNumber from "../../../../../models/devices/orderNumber";
import ProductGroupsModel from "../../../../../models/devices/ProductGroupsModel";
import ProductModel from "../../../../../models/devices/ProductModel";
import ProjectInfo from "../../../../../models/drive/projectInfo";
import MarkModel from "../../../../../models/marks/MarkModel";
import LockInfo from "../../../../../services/models/LockInfo";
import { serviceFactory } from "../../../../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../../../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../../../../services/toaster.service";
import { ConfirmationDialogComponent } from "../../../../../shared/confirmation-dialog/confirmation-dialog.component";
@Component({
  selector: "app-device-main-view",
  templateUrl: "./device-main-view.component.html",
  styleUrls: ["./device-main-view.component.css"],
})
export class DeviceMainViewComponent
  implements CanComponentDeactivate, OnDestroy
{
  @ViewChild("deviceSettingsCatalog") deviceSettingsCatalogSideNav: MatSidenav;
  @ViewChild("saveProject") saveProjectSideNav: MatSidenav;
  header: string;
  selectedProduct: ProductModel = new ProductModel();
  selectedProductsList: ProductModel[] = [];
  selectedViewMarksList: MarkModel[] = [];
  isOpenSaveProjectSideNav: boolean = false;
  subscriptions: Subscription = new Subscription();
  projectId: string = "";
  projectInfo = new ProjectInfo();
  productGroupProposal;
  nominalVoltageList: Number[] = [];
  lockInfo = new LockInfo();
  catalogTreeData: ProductNode;
  selectedMark: MarkModel = new MarkModel();
  isMarkSelected: boolean;
  spinnerModel = new SpinnerModel();

  constructor(
    private activatedRoute: ActivatedRoute,
    public eventBus: NgEventBus,
    public dialog: MatDialog,
    public toasterService: ToasterService,
    public cookieService: CookieService,
    public sessionExpiredDialog: SessionExpiredService,
    private translator: TranslateService,
    private locationStrategy: LocationStrategy
  ) {
    this.getNominalVoltage();
    this.activatedRoute.params.subscribe(async (param) => {
      this.translator
        .get("headers.draft")
        .pipe(take(1))
        .subscribe((value) => (this.header = value));

      if (param.projectId) {
        this.projectInfo.id = param.projectId;

        history.pushState(null, null, window.location.href);
        this.locationStrategy.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });
        this.spinnerModel.show = true;
        this.spinnerModel.text = "loader.loadConfiguration";
        this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);

        await serviceFactory.ProjectService.getProjectByID(param.projectId)
          .then(async (projDets: ProjectInfo) => {
            this.projectInfo = projDets;
            this.header = this.projectInfo.name;

            await this.setProjectSession();
            await this.getProductGroups();
            await this.getMarksData();
            await this.getDeviceData();
          })
          .catch((err: AxiosError) => {
            if (err.response.data === Constants.SESSIONEXPIRED) {
              this.sessionExpiredDialog.openDialog();
            } else {
              this.toasterService.showError(err);
            }
          })
          .finally(async () => {
            this.spinnerModel.show = false;
            this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
          });
      }
    });
    const OPENADDDEVICESIDEBAR = this.eventBus
      .on(EventType.OPENADDDEVICESIDEBAR)
      .subscribe((metaData: MetaData) => {
        if (metaData.data) {
          this.deviceSettingsCatalogSideNav
            ? this.deviceSettingsCatalogSideNav.open()
            : "";
        } else {
          this.deviceSettingsCatalogSideNav
            ? this.deviceSettingsCatalogSideNav.close()
            : "";
        }
      });
    const CONFIRM_DELETE_PRODUCT = this.eventBus
      .on(EventType.CONFIRM_DELETE_PRODUCT)
      .subscribe((metaData: MetaData) => {
        this.deviceSettingsCatalogSideNav
          ? this.deviceSettingsCatalogSideNav.open()
          : "";
      });
    const UPDATEDORDERNUMBERDETAILS = this.eventBus
      .on(EventType.CHARTDATATOBEUPDATED)
      .subscribe((metaData: MetaData) => {
        // this.isShowProductSettings = true;
        this.selectedProduct = metaData.data;
        this.deviceSettingsCatalogSideNav
          ? this.deviceSettingsCatalogSideNav.open()
          : "";
      });
    this.eventBus
      .on(EventType.ON_SETTINGS_ICON_CLICK)
      .subscribe((metaData: MetaData) => {
        this.selectedProduct = metaData.data;
        this.isMarkSelected = false;
        this.deviceSettingsCatalogSideNav
          ? this.deviceSettingsCatalogSideNav.open()
          : "";
        setTimeout(() => {
          this.eventBus.cast(EventType.AFTERSIDEBARNAVIGATION, true);
        }, 300);
      });
    const EMIT_SELECTED_PRODUCT = this.eventBus
      .on(EventType.EMIT_SELECTED_PRODUCT)
      .subscribe((metaData: MetaData) => {
        // this.isShowProductSettings = true;
        this.selectedProduct = metaData.data;
        this.isMarkSelected = false;
        this.deviceSettingsCatalogSideNav
          ? this.deviceSettingsCatalogSideNav.open()
          : "";
        setTimeout(() => {
          this.eventBus.cast(EventType.AFTERSIDEBARNAVIGATION, true);
        }, 300);
      });
    const AUTOSAVEONLOGOUT = this.eventBus
      .on(EventType.AUTOSAVEONLOGOUT)
      .subscribe((metadata) => {
        if (!this.projectInfo.isLocked) {
          this.autoSaveOnlogout();
        } else {
          this.cookieService.delete("JSESSIONID", "/");
          window.location.href = "/";
        }
      });
    const AUTOSAVEONSESSIONEXPIRATION = this.eventBus
      .on(EventType.AUTOSAVEONSESSIONEXPIRATION)
      .subscribe((metadata) => {
        if (!this.projectInfo.isLocked)
          this.saveConfiguration(metadata.data, "", true);
      });
    const SAVECONFIGONLOCALECHANGE = this.eventBus
      .on(EventType.SAVECONFIGONLOCALECHANGE)
      .subscribe((metadata) => {
        if (!this.projectInfo.isLocked) {
          this.saveConfiguration(metadata.data).then((res) => {
            location.reload();
          });
        }
      });
    const EMIT_SELECTED_MARK = this.eventBus
      .on(EventType.EMITSELECTEDMARK)
      .subscribe((metaData: MetaData) => {
        this.selectedMark = metaData.data;
        this.isMarkSelected = true;
        this.deviceSettingsCatalogSideNav
          ? this.deviceSettingsCatalogSideNav.open()
          : "";
        setTimeout(() => {
          this.eventBus.cast(EventType.AFTERSIDEBARNAVIGATION, true);
        }, 300);
      });
    const onProductListUpdate = this.eventBus
      .on(EventType.EMIT_UPDATED_PRODUCTLIST)
      .subscribe((metaData: MetaData) => {
        this.selectedProductsList = metaData.data;
        this.closeSideNavOnEmptyList();
      });
    const onMarkListUpdate = this.eventBus
      .on(EventType.SELECTED_VIEW_MARKSLIST)
      .subscribe((metaData: MetaData) => {
        this.selectedViewMarksList = metaData.data;
        this.closeSideNavOnEmptyList();
      });
    this.subscriptions.add(OPENADDDEVICESIDEBAR);
    this.subscriptions.add(CONFIRM_DELETE_PRODUCT);
    this.subscriptions.add(EMIT_SELECTED_PRODUCT);
    this.subscriptions.add(UPDATEDORDERNUMBERDETAILS);
    this.subscriptions.add(AUTOSAVEONLOGOUT);
    this.subscriptions.add(AUTOSAVEONSESSIONEXPIRATION);
    this.subscriptions.add(SAVECONFIGONLOCALECHANGE);
    this.subscriptions.add(EMIT_SELECTED_MARK);
    this.subscriptions.add(onProductListUpdate);
  }
  async setProjectSession() {
    if (this.projectInfo.id) {
      //TODO::Folder Id Removal Migration
      await serviceFactory.ProjectService.setProjectInfoInSession(
        this.projectInfo.id
      ).catch((err: AxiosError) => {
        if (err.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(err);
        }
      });
    }
  }
  async getNominalVoltage() {
    await serviceFactory.ProductService.getNominalVoltage()
      .then((nominalVoltageList: Number[]) => {
        this.nominalVoltageList = nominalVoltageList;
      })
      .catch((error: AxiosError) => {
        if (error.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(error.message);
        }
      });
  }

  async getProductGroups() {
    await serviceFactory.ProductService.getProductGroups()
      .then((productGroupsToOrderNoJson) => {
        this.facadeOrderNumberProposal(productGroupsToOrderNoJson);
      })
      .catch((error: AxiosError) => {
        if (error.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(error.message);
        }
      });
  }
  facadeOrderNumberProposal(productGroupToOrderNumberJson) {
    this.productGroupProposal =
      serviceFactory.FacadeService.getOrderNumberProposals(
        productGroupToOrderNumberJson
      );
  }
  async getCatalogTreeData() {
    await serviceFactory.ProductCatalogService.getCatalogTree()
      .then((res: ProductNode) => {
        this.catalogTreeData = res;
      })
      .catch((error: AxiosError) => {
        if (error.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(error.message);
        }
      });
  }

  async getDeviceData() {
    //TODO::Folder Id Removal Migration
    await serviceFactory.ProjectService.getImportedDevices(
      this.projectInfo.id,
      Constants.CONFIGURATIONFILENAME
    )
      .then((listEntry) => {
        this.eventBus.cast(EventType.EMIT_LISTENTRY_MARK_LIST, listEntry);
        // this.listEntryList = listEntry.listEntry;
        // this.markModelList = listEntry.markModel;
      })
      .catch(async (e) => {
        //TODO::Folder Id Removal Migration
        await serviceFactory.ProjectService.getImportedDevices(
          this.projectInfo.id,
          Constants.AUTOSAVEFILENAME
        )
          .then((listEntry) => {
            this.eventBus.cast(EventType.EMIT_LISTENTRY_MARK_LIST, listEntry);
            // this.listEntryList = listEntry.listEntry;
          })
          .catch((err: AxiosError) => {
            if (err.response.data === Constants.SESSIONEXPIRED) {
              this.sessionExpiredDialog.openDialog();
            } else {
              this.toasterService.showError(err);
            }
          });
      })
      .finally(async () => {
        await this.getCatalogTreeData();
      });
  }
  async getMarksData() {
    await serviceFactory.MarkService.getMarkList(
      this.projectInfo.id,
      MarkViewType.TRIP
    )
      .then((markList: MarkModel[]) => {
        if (markList.length > 0)
          this.eventBus.cast(EventType.UPDATED_MARK_LIST, markList);
      })
      .catch((err: AxiosError) => {
        if (err.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(err);
        }
      });
  }
  closeSideNavOnEmptyList() {
    if (
      this.selectedProductsList.length === 0 &&
      this.selectedViewMarksList.length === 0
    ) {
      this.deviceSettingsCatalogSideNav
        ? this.deviceSettingsCatalogSideNav.close()
        : "";
      setTimeout(() => {
        this.eventBus.cast(EventType.AFTERSIDEBARNAVIGATION, true);
      }, 300);
    }
  }
  @HostListener("window:unload", ["$event"])
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.projectInfo.isLocked) {
      return new Observable((observer: Observer<boolean>) => {
        let dialogHeight = "13rem";
        let dialogWidth = "37.5rem";
        let dialogModel = new DialogModel();
        dialogModel.content = "Action_save_message";
        // dialogModel.component = 'confirmation';
        dialogModel.header = "confirmation-dialog.header";
        dialogModel.actions = [
          {
            action: "confirmation-dialog.proceed",
            type: "primary",
          },
          {
            action: "confirmation-dialog.discard",
            type: "secondary",
          },
        ];
        let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: dialogModel,
          height: dialogHeight,
          width: dialogWidth,
        });

        dialogRef.afterClosed().subscribe(async (result) => {
          if (result == undefined) {
            //On Close dialog click restoring navbar
            //selection to listentry and disabling progress bar
            // this.eventBus.cast(EventType.ONLISTENTRYSELECTION, true);
          } else {
            this.saveConfiguration(result, observer);
          }
        });
      });
    } else {
      return true;
    }
  }
  async autoSaveOnlogout() {
    let dialogHeight = "13rem";
    let dialogWidth = "37.5rem";
    let dialogModel = new DialogModel();
    dialogModel.content = "Action_save_message";
    dialogModel.header = "confirmation-dialog.header";
    dialogModel.actions = [
      {
        action: "confirmation-dialog.proceed",
        type: "primary",
      },
      {
        action: "confirmation-dialog.discard",
        type: "secondary",
      },
    ];
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogModel,
      height: dialogHeight,
      width: dialogWidth,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result !== undefined) {
        let fileName: string = Constants.CONFIGURATIONFILENAME;
        if (result) {
          this.spinnerModel.show = true;
          this.spinnerModel.text = "loader.saveConfiguration";
          this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
          //TODO::Folder Id Removal Migration
          await serviceFactory.ProjectService.saveConfiguration(
            this.projectInfo.id,
            fileName
          )
            .then(async (response) => {
              await serviceFactory.ProjectService.logout()
                .then((response) => {
                  this.cookieService.delete("JSESSIONID", "/");
                  window.location.href = "/";
                })
                .catch((error: AxiosError) => {
                  if (error.response.data === Constants.SESSIONEXPIRED) {
                    this.sessionExpiredDialog.openDialog();
                  } else {
                    this.toasterService.showError(error.message);
                  }
                });
            })
            .catch((error: AxiosError) => {
              if (error.response.data === Constants.SESSIONEXPIRED) {
                this.sessionExpiredDialog.openDialog();
              } else {
                this.toasterService.showError(error);
              }
            })
            .finally(async () => {
              this.spinnerModel.show = false;
              this.eventBus.cast(
                EventType.SHOW_HIDE_SPINNER,
                this.spinnerModel
              );
            });
        } else {
          await serviceFactory.ProjectService.logout()
            .then((response) => {
              this.cookieService.delete("JSESSIONID", "/");
              window.location.href = "/";
            })
            .catch((error: AxiosError) => {
              if (error.response.data === Constants.SESSIONEXPIRED) {
                this.sessionExpiredDialog.openDialog();
              } else {
                this.toasterService.showError(error.message);
              }
            });
        }
      }
    });
  }
  async saveConfiguration(
    result: boolean,
    observer?: any,
    isAutoSaveOnBufferTime?: boolean
  ): Promise<any> {
    let fileName: string = Constants.CONFIGURATIONFILENAME;
    result
      ? (fileName = Constants.CONFIGURATIONFILENAME)
      : (fileName = Constants.AUTOSAVEFILENAME);
    if (result || (!result && isAutoSaveOnBufferTime)) {
      this.spinnerModel.show = true;
      this.spinnerModel.text = "loader.saveConfiguration";
      this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
      await serviceFactory.ProjectService.saveConfiguration(
        this.projectInfo.id,
        fileName,
        isAutoSaveOnBufferTime
      )
        .then(async (response) => {
          // this.listEntryStore.dispatch(restoreListEntryInfo());
          this.cookieService.delete("JSESSIONID", "/");
          if (observer) {
            observer.next(true);
            observer.complete();
          }
          return response;
        })
        .catch((error: AxiosError) => {
          if (error.response.data === Constants.SESSIONEXPIRED) {
            this.sessionExpiredDialog.openDialog();
          } else {
            this.toasterService.showError(error);
          }
        })
        .finally(async () => {
          this.spinnerModel.show = false;
          this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
        });
    } else {
      if (observer) {
        observer.next(true);
        observer.complete();
      }
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  async onExportClick() {
    this.spinnerModel.show = true;
    this.spinnerModel.text = "loader.export";
    this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
    await serviceFactory.ProjectService.exportProject(
      this.projectInfo.id,
      this.projectInfo.name
    )
      .then((res) => {
        this.toasterService.showSuccess(
          "toaster-message.projectDownloadSuccess"
        );
      })
      .catch((err: AxiosError) => {
        if (err.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          let translatedErrorMsg = this.toasterService
            .toasterMessage("toaster-message.projectDownloadError")
            .replace(/(\<.*?\>)/gi, err.message);
          this.toasterService.showError(translatedErrorMsg);
        }
      })
      .finally(() => {
        this.spinnerModel.show = false;
        this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
      });
  }

  isEnableExport() {
    let visibleListEnteries = this.selectedProductsList.find(
      (x) =>
        x.product.toggleSlashEye === true &&
        x.product.type !== Constants.SHORTCIRCUITLINE
    );

    return (
      this.selectedProductsList.length === 0 ||
      visibleListEnteries === undefined
    );
  }
}
