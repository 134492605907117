<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.33341 10.8334L12.5001 1.66675L16.6667 5.83342L7.50008 15.0001H3.33341V10.8334ZM14.3101 5.83342L12.5001 4.02341L5.00008 11.5234V13.3334H6.81008L14.3101 5.83342Z"
    fill="currentColor"
  />
  <path
    d="M18.3334 18.3334V16.6667H1.66675V18.3334H18.3334Z"
    fill="currentColor"
  />
</svg>
