<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14 3H5V21H19V8L14 3ZM17 19H7V5H11V11H17V19ZM13 9H17V8.83L13.17 5H13V9Z"
    fill="currentColor"
  />
</svg>
