import { MarkViewType } from "../constants/mark-view-type";

export default class MarkModel {
  rgbValue: any;
  current: any;
  time: any;
  view: MarkViewType;
  ownerIndex: number;
  label: string;
  visible: boolean;
  itemType: string;
  index: number;
  markListIndex: number;
  isChecked: boolean = false;
  mlabel: string;
  isEditMarkLabel: boolean;
  isEditMarkLabelSetting: boolean;
  translatedCurrentValue: string;
  translatedTimeValue: string;
  translatedLabel: string;
  isMarkConfigurationOpen?: boolean = false;
  isMarkOnSeriesData?: boolean;
  isMarkLabelIsEdited?: boolean = false;
}
