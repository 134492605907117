import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgEventBus } from "ng-event-bus";
import { EventType } from "../../models/constants/eventType";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.css"],
})
export class NavBarComponent {
  isSideBarExpanded: boolean = false;
  isDeviceSelectionBarExpanded: boolean = true;
  isSettingsBarExpanded: boolean = false;
  isProjDefSelected: boolean = true;
  isCharCurveSelected: boolean = false;
  isInformationSelected: boolean = false;
  constructor(public router: Router, public eventBus: NgEventBus) {}
  onProjectDefinitionClick() {
    console.log(environment.appVersion, "version");
    this.router.navigate(["/projects"]);
  }
  onExpandCollapsebutton() {
    this.isSideBarExpanded = !this.isSideBarExpanded;
    setTimeout(() => {
      this.eventBus.cast(EventType.AFTERSIDEBARNAVIGATION, true);
    }, 300);
  }
  selectProjDef() {
    return this.router.url.startsWith("/projects") &&
      !this.router.url.includes("/listentry")
      ? (this.isProjDefSelected = true)
      : false;
  }
  selectCharCurv() {
    return this.router.url.startsWith("/projects") &&
      this.router.url.includes("/listentry")
      ? (this.isCharCurveSelected = true)
      : false;
  }
  selectInformation() {
    return this.router.url.startsWith("/information")
      ? (this.isInformationSelected = true)
      : false;
  }
  navigateToInfoPage() {
    this.router.navigate(["information"]);
  }
}
