import {
  loadProduct,
  loadProductFailure,
  loadProductSuccess,
} from "../store/cache.action";
import { ProductEffects } from "../store/cache.effects";
import {
  countryReducer,
  productReducer,
  userReducer,
} from "../store/cache.reducer";
import { getStateSelectedData } from "../store/cache.selector";

export const fromRoot = {
  loadProduct,
  loadProductFailure,
  loadProductSuccess,
  userReducer,
  productReducer,
  countryReducer,
  ProductEffects,
  getStateSelectedData,
};
