export default class AuxillaryLineService {
  drawAuxillaryLineForIc(chartData, renderer) {
    if (chartData && chartData.ref) {
      let yMax = chartData.ref.yAxis[0].max;
      let yMin = chartData.ref.yAxis[0].min;
      let xMin = chartData.ref.xAxis[0].min;
      let xMax = chartData.ref.xAxis[0].max;
      let aX, aY, bX, bY;
      let x1, y1, x2, y2;
      let factor = [1.8, 1];
      if (chartData.customPaths) {
        chartData.customPaths.forEach((path) => {
          path.element.remove();
        });
      } else {
        chartData.customPaths = [];
      }
      factor.forEach((factorX) => {
        let xMinValue = Math.pow(10, yMin) / (factorX * Math.sqrt(2));
        let xMaxValue = Math.pow(10, yMax) / (factorX * Math.sqrt(2));
        if (xMinValue < Math.pow(10, xMin)) {
          x1 = Math.pow(10, xMin);
          y1 = Math.pow(10, xMin) * factorX * Math.sqrt(2);
        } else {
          y1 = Math.pow(10, yMin);
          x1 = xMinValue;
        }

        if (xMaxValue > Math.pow(10, xMax)) {
          x2 = Math.pow(10, xMax);
          y2 = Math.pow(10, xMax) * factorX * Math.sqrt(2);
        } else {
          x2 = xMaxValue;
          y2 = Math.pow(10, yMax);
        }

        aX = chartData.ref.xAxis[0].toPixels(x1);
        aY = chartData.ref.yAxis[0].toPixels(y1);
        bX = chartData.ref.xAxis[0].toPixels(x2);
        bY = chartData.ref.yAxis[0].toPixels(y2);

        let angleRadians = Math.atan((bY - aY) / (bX - aX));
        let labelAngle = angleRadians * 57.2958;

        let labelPos = this.getScalingFactor(
          x2,
          y2,
          xMin,
          xMax,
          yMin,
          yMax,
          chartData
        );
        let labelXPos, labelYPos;
        let labelToBeShown = this.getIcLabel(factorX);

        if (bX > labelPos[0]) {
          labelXPos = labelPos[0];
          labelYPos = aY + ((labelPos[0] - aX) * (bY - aY)) / (bX - aX);
        } else {
          labelXPos = bX;
          labelYPos = bY;
        }
        let label = renderer
          .text(labelToBeShown, 100, 100)
          .attr({
            rotation: labelAngle,
          })
          .css({
            fontSize: "14px",
            fontWeight: "600",
          })
          .add();

        let labelWidth = label.getBBox().width;
        let labelHeight = label.getBBox().height;
        label.destroy();

        if (factorX === 1.8) {
          labelWidth = labelWidth + 13;
        } else {
          labelWidth = labelWidth + 5;
        }
        chartData.customPaths.push(
          chartData.ref.renderer
            .path([
              ["M", aX, aY],
              ["L", bX, bY],
            ])
            .attr({
              stroke: "#CCCCD4",
            })
            .add()
        );
        chartData.customPaths.push(
          chartData.ref.renderer
            .text(
              labelToBeShown,
              labelXPos - 5 - Math.cos(angleRadians) * labelWidth,
              labelYPos - Math.sin(angleRadians) * labelWidth
            )
            .attr({
              rotation: labelAngle,
            })
            .css({
              fontSize: "14px",
              fontWeight: "600",
            })
            .add()
        );
      });
    }
  }
  getIcLabel(factor: number) {
    return factor === 1
      ? "<span>&radic;2</span>"
      : factor.toLocaleString(localStorage.getItem("i18nextLng")) +
          "* <span>&radic;2</span>";
  }
  getI2tLabel(factor: number) {
    return (
      factor.toLocaleString(localStorage.getItem("i18nextLng"), {
        maximumFractionDigits: 4,
      }) + " s"
    );
  }
  drawAuxillaryLineForI2t(chartData, renderer) {
    if (chartData && chartData.ref) {
      let yMax = chartData.ref.yAxis[0].max;
      let yMin = chartData.ref.yAxis[0].min;
      let xMin = chartData.ref.xAxis[0].min;
      let xMax = chartData.ref.xAxis[0].max;
      let isMaxXValueExceeded = false;
      let factor = [
        10.0, 5.0, 2.0, 1.0, 0.4, 0.2, 0.1, 0.04, 0.02, 0.01, 0.004, 0.002,
        0.001, 0.0004, 0.0002, 0.0001,
      ];
      if (chartData.customPaths) {
        chartData.customPaths.forEach((path) => {
          path.element.remove();
        });
      } else {
        chartData.customPaths = [];
      }

      factor.forEach((factorX) => {
        let xMinValue = Math.sqrt(Math.pow(10, yMin) / factorX);
        let xMaxValue = Math.sqrt(Math.pow(10, yMax) / factorX);
        let aX, aY, bX, bY;
        let x1, y1, x2, y2;
        if (xMinValue < Math.pow(10, xMin)) {
          x1 = Math.pow(10, xMin);
          y1 = Math.pow(Math.pow(10, xMin), 2) * factorX;
        } else {
          x1 = xMinValue;
          y1 = Math.pow(10, yMin);
        }

        if (xMaxValue > Math.pow(10, xMax)) {
          x2 = Math.pow(10, xMax);
          y2 = Math.pow(Math.pow(10, xMax), 2) * factorX;
          isMaxXValueExceeded = true;
        } else {
          x2 = xMaxValue;
          y2 = Math.pow(10, yMax);
          isMaxXValueExceeded = false;
        }

        aX = chartData.ref.xAxis[0].toPixels(x1);
        aY = chartData.ref.yAxis[0].toPixels(y1);
        bX = chartData.ref.xAxis[0].toPixels(x2);
        bY = chartData.ref.yAxis[0].toPixels(y2);

        let angleRadians = Math.atan((bY - aY) / (bX - aX));
        let labelAngle = angleRadians * 57.2958;

        let labelXPos, labelYPos;
        let labelPos = this.getScalingFactor(
          x2,
          y2,
          xMin,
          xMax,
          yMin,
          yMax,
          chartData
        );

        let labelToBeShown = factorX + " s";
        let label = renderer
          .text(labelToBeShown, 10, 10)
          .attr({
            rotation: labelAngle,
          })
          .css({
            fontSize: "14px",
            fontWeight: "600",
          })
          .add();
        let labelWidth = label.getBBox().width;
        let labelHeight = label.getBBox().height;
        label.destroy();

        if (!isMaxXValueExceeded) {
          if (labelToBeShown.length >= 4)
            labelWidth = labelWidth + 2 * labelToBeShown.length;
          if (labelToBeShown.length >= 7) {
            labelWidth = labelWidth + labelToBeShown.length;
          }
        } else {
          labelWidth = labelWidth - 5;
        }

        // let translateXPos, translateYPos
        // if (bX > labelPos[0]) {
        //     translateXPos = - (labelWidth - 15 / angleRadians);
        //     translateYPos = - (labelHeight * 0.2);
        // } else {
        //     translateXPos = -(labelWidth - 15 / angleRadians);
        //     translateYPos = (labelHeight * 0.2)
        // }

        if (bX > labelPos[0]) {
          labelXPos = labelPos[0];
          labelYPos = aY + ((labelPos[0] - aX) * (bY - aY)) / (bX - aX);
        } else {
          labelXPos = bX;
          labelYPos = bY;
        }
        if (x1 != x2 && x1 < x2 && y1 != y2) {
          chartData.customPaths.push(
            chartData.ref.renderer
              .path([
                ["M", aX, aY],
                ["L", bX, bY],
              ])
              .attr({
                stroke: "#CCCCD4",
              })
              .add()
          );
          chartData.customPaths.push(
            chartData.ref.renderer
              .text(
                this.getI2tLabel(factorX),
                labelXPos - 5 - Math.cos(angleRadians) * labelWidth,
                labelYPos - Math.sin(angleRadians) * labelWidth
              )
              .attr({
                rotation: labelAngle,
                // translateX: translateXPos,
                // translatY: translateYPos
              })
              .css({
                fontSize: "14px",
                fontWeight: "600",
              })
              .add()
          );
        }
      });
    }
  }

  getScalingFactor(xEnd, yEnd, xMinExp, xMaxExp, yMinExp, yMaxExp, chartData) {
    let xPos =
      chartData.ref.xAxis[0].toPixels(Math.pow(10, xMinExp)) +
      this.scale(
        Math.log10(xEnd),
        xMinExp,
        this.getGridScale(xMinExp, xMaxExp, chartData.ref.xAxis[0].width)
      );
    let yPos =
      chartData.ref.yAxis[0].toPixels(Math.pow(10, yMinExp)) +
      chartData.ref.xAxis[0].height -
      (isFinite(Math.log10(yEnd))
        ? 0
        : this.scale(
            Math.log10(yEnd),
            yMinExp,
            this.getGridScale(yMinExp, yMaxExp, chartData.ref.xAxis[0].height)
          ));
    return [xPos, yPos];
  }
  getGridScale(minExp, maxExp, gridScaleArea) {
    return (gridScaleArea - 1) / (maxExp - minExp);
  }
  scale(d, base, scaleFactor) {
    return (d - base) * scaleFactor;
  }
}
