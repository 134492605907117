<div #overlay id="overlay" class="overlay">
  <div class="overlay__header">
    <div>
      {{ "line-configuration.lineConfiguration" | translate }}
    </div>
    <div
      style="color: var(--grid-header-color)"
      class="icon-close"
      (click)="closeConfig()"
    >
      <app-icons [iconName]="'close'" [height]="18" [width]="18"></app-icons>
    </div>
  </div>
  <div class="overlay__config">
    <mat-accordion class="color-config">
      <mat-expansion-panel class="expansion-panel" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="overlay__config-header">
              <div>
                {{ "line-configuration.lineColor" | translate }}
              </div>
              <div
                [style.background-color]="lineConfig.lineColor"
                class="overlay__config-color"
              ></div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-panel-description>
          <div class="flex flex-direction-column">
            <div class="color">
              <button
                class="color-picker"
                matButton
                [(colorPicker)]="lineConfig.lineColor"
                [style.background]="lineConfig.lineColor"
                [cpPresetColors]="presetValues"
                [cpToggle]="true"
                [cpColorMode]="'presets'"
                [cpDialogDisplay]="'inline'"
                [cpPresetLabel]="''"
                (colorPickerChange)="OnColorChange()"
              ></button>
            </div>

            <div class="more-color-picker">
              <button
                class="flex color-picker-button"
                matButton
                [(colorPicker)]="lineConfig.lineColor"
                (colorPickerChange)="OnColorChange()"
                [cpPosition]="'bottom-right'"
              >
                {{ "line-configuration.more" | translate }}
              </button>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel>

      <mat-expansion-panel class="expansion-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="overlay__config-header">
              <div>{{ "line-configuration.lineStyle" | translate }}</div>
              <div class="overlay__config-style">
                <img class="w-100" [src]="selectedLineStyle" />
              </div>
              <!-- <div
                [style.border-top]="selectedLineStyle"
                class="overlay__config-style"
              >
                {{selectedLineStyle.}}
              </div> -->
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-description class="line-style">
          <div
            class="line-config"
            *ngFor="let style of lineStyles"
            (click)="onLineStyleChange(style)"
          >
            <!-- <div class="col-md-6 content">{{ style.value }}</div> -->
            <div><img [src]="style.key" /></div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel>

      <mat-expansion-panel class="expansion-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="overlay__config-header">
              <div class="style_header">
                {{ "line-configuration.lineWidth" | translate }}
              </div>
              <div
                [style.border-top]="selectedLineWidth"
                class="lineWidthHeader rounded-5"
              ></div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-description class="line-style">
          <div
            class="line-config"
            *ngFor="let width of lineWidths"
            (click)="onLineWidthChange(width)"
          >
            <div class="w-50 rounded-5" [style.border-top]="width.value"></div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
