import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription } from "rxjs";
import { ProductNode } from "../../../../../models/catalog/CatalogProductNodeModel";
import ProjectInfo from "../../../../../models/drive/projectInfo";
import { EventType } from "../../../../../models/constants/eventType";
import MarkModel from "../../../../../models/marks/MarkModel";
import ProductModel from "../../../../../models/devices/ProductModel";

@Component({
  selector: "app-device-settings-properties-view",
  templateUrl: "./device-settings-properties-view.component.html",
  styleUrls: ["./device-settings-properties-view.component.css"],
})
export class DeviceSettingsPropertiesViewComponent implements OnDestroy {
  @Input() selectedProduct: ProductModel = new ProductModel();
  @Input() projectinfo: ProjectInfo;
  @Input() selectedMark: MarkModel = new MarkModel();
  @Input() isMarkSelected: boolean;
  @Input() catalogTreeData: ProductNode;
  color: string;
  selectedTab: number = 0;
  checkValue: boolean = false;
  subscriptions: Subscription = new Subscription();
  confirmDeleteMark: boolean = false;
 
  constructor(
    public eventBus: NgEventBus,
    public dialog: MatDialog,
  ) {
    const OnColorChange = this.eventBus
      .on(EventType.ON_MARK_COLORCHANGE)
      .subscribe((metaData: MetaData) => {
        let mark = new MarkModel();
        mark = metaData.data;
        if (
          metaData.data &&
          this.selectedMark.markListIndex == mark.markListIndex
        ) {
          this.selectedMark.rgbValue = mark.rgbValue;
          // this.colorChange();
        }
      });
    const ConfirmDeleteMark = this.eventBus
      .on(EventType.REMOVE_ALL_DEVICE_MARKS)
      .subscribe((metaData: MetaData) => {
        this.eventBus.cast(EventType.OPENADDDEVICESIDEBAR, false);
      });
    this.subscriptions.add(ConfirmDeleteMark);
    this.subscriptions.add(OnColorChange);
  }

  changeTab(tabChangeEvent) {
    this.selectedTab = tabChangeEvent.index;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
