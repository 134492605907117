export enum EventType {
  EMIT_DELETE_EVENT = "emitDeleteEvent",
  CONFIRM_DELETE_PRODUCT = "confirmDeleteProduct",
  SHOW_HIDE_GRAPH = "showHideGraph",
  ON_SETTINGS_ICON_CLICK = "onSettingsIconClick",
  EMIT_SELECTED_PRODUCT = "emitSelectedProduct",
  EMIT_SLIDER_CHANGES = "emitSliderChanges",
  CHANGED_RANGE_VALUE = "changedRangeValue",
  EMIT_CHECKED_EVENT = "emitChecked",
  EMIT_RADIO_EVENT = "emitRadioEvent",
  EMITINPUTEVENT = "emitValueFromInput",
  UPDATE_SLIDER_VALUE = "UpdateSliderValue",
  EMIT_HORIZONTALSLIDER_VALUE_CHANGE = "emitHorizontalSliderValueChange",
  EMIT_RELAYSETTING_CHANGE = "emitRelaySettingChange",
  CHANGE_SLIDER_VALUE = "changeSliderValue",
  ONCANCELCATALOG = "onCancelCatalogClick",
  SHOW_HIDE_SPINNER = "showHideSpinner",
  ON_LEAFNODE_CLICK = "OnProductGroupFromLeafNode",
  ADD_PRODUCT_FROM_PRODUCTCATALOG = "AddProductFromProductCatalog",
  ON_PARENTNODE_CLICK = "OnProductNodeClick",
  CREATENEWPROJECT = "OnCreateNewProject",
  ADDPROJECTSUCCESS = "OnAddProjectSuccess",
  EDITPROJECTSUCCESS = "OnEditProjectSuccess",
  CANCELPROJECTCREATION = " OnCancelProjectCreation",
  EDITPROJECT = "OnEditProject",
  DELETEPROJECTSUCCESS = "OnDeleteProjectSuccess",
  ONLINECONFIGCHANGE = "OnLineConfigChange",
  CLOSELINECONFIG = "closeLineConfig",
  SEARCHPRODUCTFROMCATALOG = "SearchProductFromCatalog",
  ONADDPRODUCT = "OnProductAdd",
  SEARCHEDPRODUCTRESULTS = "SearchedProductResults",
  OPENADDDEVICESIDEBAR = "OpenDeviceSelectionSideBar",
  ADDEDPRODUCTSLIST = "AddedProductsList",
  ONFILEUPLOADSUCCESS = "OnFileUploadSuccess",
  ONEDITPRODUCTDETAILS = "OnEditProductDetails",
  UPDATEDORDERNUMBERDETAILS = "updatedOrderNumberDetails",
  CHARTDATATOBEUPDATED = "chartDataToBeUpdated",
  ORDERNUMBERSELECTEDFROMSEARCHLIST = "OnProductSelectedFromList",
  EMITSLIDERCHANGESONMOUSELEAVE = "emitSliderChangesOnMouseLeave",
  AFTERSIDEBARNAVIGATION = "AfterSideBarNavigation",
  ONDEVICELABELEDIT = "OnDeviceLabelEdit",
  AUTOSAVEONLOGOUT = "AutosaveOnLogout",
  AUTOSAVEONSESSIONEXPIRATION = "AutoSaveOnSessionExpiration",
  SAVECONFIGONLOCALECHANGE = "SaveConfigOnLocaleChange",
  UPDATESETTINGAFTERSLIDERCHANGE = "UpdateSettingAfterSliderChange",
  SESSIONTIMEDOUT = "SessionTimedOut",
  ISUSERIDLE = "IsUserIdle",
  ADDSHORTCIRCUITLINE = "AddShortCircuitLine",
  REMOVE_ALL_SERIES = "RemoveAllSeries",
  EMIT_UPDATED_PRODUCTLIST = "EmitUpdatedProductList",
  UPDATED_MARK_LIST = "updatedMarkList",
  ORDERLIST = "orderList",
  EMITSELECTEDMARK = "emitSelectedMark",
  EMIT_DELETEMARK_EVENT = "emitDeleteMarkEvent",
  ON_MARK_COLORCHANGE = "onMarkColorChange",
  ADD_MARK_IN_GRAPH = "addMarkInGraph",
  CONFIRM_DELETE_MARK = "confirmDeleteMark",
  TAB_CHANGE = "tabChange",
  LINK_MARK_VALUE_CHANGEX = "linkValueChange",
  UPDATED_VALUE = "updatedValue",
  LINK_MARK_VALUE_CHANGEY = "linkValueChangeY",
  REMOVE_ALL_DEVICE_MARKS = "removeAllDeviceMarks",
  SELECTED_VIEW_MARKSLIST = "selectedViewMarksList",
  EMIT_LISTENTRY_MARK_LIST = "emitListEntryMarkList",
  HIGHLIGHT_SELECTED_MARK = "highLightSelectedMark",
  LOGIN_NAVIGATE_BACK = "navigateBackToLogin",
  LOGIN_SHOW_TERMS_COND = "showTermsAndCondtion",
}
