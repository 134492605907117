import { createSelector } from "@ngrx/store";
import { ProductState } from "../store/cache.reducer";

const getSelectedData = (state: ProductState): any => state;

const getStateSelectedData = createSelector(
  (state: { productState: ProductState }) => state.productState,
  getSelectedData
);

export { getStateSelectedData };
