<div class="header px-4 py-4">
  {{ header | translate }}
</div>

<div class="form px-4">
  <div>
    <mat-label
      class="form__label"
      [ngClass]="
        isValidCountryValue
          ? isCountryFieldActive
            ? 'form__label_active'
            : 'activeStateCountry'
          : 'errorStateLabel'
      "
    >
      {{ "Country" | translate }}
    </mat-label>
    <mat-form-field
      appearance="outline"
      class="form__field"
      [ngClass]="
        isValidCountryValue
          ? isCountryFieldActive && !isValidCountryValue
            ? 'activeStateCountry'
            : 'countryNormalState'
          : 'errorState'
      "
    >
      <input
        type="text"
        matInput
        class="textFormat"
        [matAutocomplete]="auto"
        [formControl]="countryFormControl"
        (focus)="onFocus('isCountryFieldActive')"
        (blur)="onBlur('isCountryFieldActive')"
        (keyup)="inputChange($event.target.value)"
      />
      <mat-icon matSuffix>arrow_drop_down</mat-icon>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let country of filteredOptions | async"
          [value]="country.name"
          (click)="saveSelectedCountry(country)"
        >
          {{ country.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="errorMessage" *ngIf="!isValidCountryValue">
      {{ "error-message-countryname" | translate }}
    </div>
  </div>

  <div>
    <div>
      <mat-label
        class="form__label"
        [ngClass]="isNameFieldActive ? 'form__label_active' : ''"
      >
        *{{ "project" | translate }}
      </mat-label>
      <span *ngIf="isNameFieldActive" class="counter">
        {{ "(" }}{{ this.name.value | counterCharacters }}/100{{ ")" }}</span
      >
    </div>
    <mat-form-field appearance="outline" class="form__field">
      <input
        type="text"
        matInput
        [(ngModel)]="projectInfo.name"
        class="textFormat"
        [value]="projectInfo.name"
        #name
        maxlength="100"
        (focus)="onFocus('isNameFieldActive')"
        (blur)="onBlur('isNameFieldActive')"
      />
    </mat-form-field>
  </div>

  <div>
    <div class="mt-2">
      <mat-label
        class="form__label"
        [ngClass]="isDescriptionFieldActive ? 'form__label_active' : ''"
        >{{ "projectdesc" | translate }}</mat-label
      >
      <span *ngIf="isDescriptionFieldActive" class="counter"
        >{{ " (" }}{{ this.desc.value | counterCharacters }}/500{{ ")" }}</span
      >
    </div>
    <mat-form-field appearance="outline" class="form__field">
      <input
        type="text"
        matInput
        class="textFormat"
        class="textFormat"
        [(ngModel)]="projectInfo.projectDescription.value"
        [value]="
          projectInfo.projectDescription
            ? projectInfo.projectDescription.value
            : null
        "
        #desc
        maxlength="500"
        (focus)="onFocus('isDescriptionFieldActive')"
        (blur)="onBlur('isDescriptionFieldActive')"
      />
    </mat-form-field>
  </div>

  <div>
    <div class="mt-2">
      <mat-label
        class="form__label"
        [ngClass]="isClientFieldActive ? 'form__label_active' : ''"
      >
        {{ "customer" | translate }}
      </mat-label>
      <span *ngIf="isClientFieldActive" class="counter"
        >{{ "(" }}{{ this.customer.value | counterCharacters }}/500{{
          ")"
        }}</span
      >
    </div>
    <mat-form-field appearance="outline" class="form__field">
      <input
        type="text"
        matInput
        class="textFormat"
        [(ngModel)]="projectInfo.client.value"
        [value]="projectInfo.client ? projectInfo.client.value : null"
        #customer
        maxlength="500"
        (focus)="onFocus('isClientFieldActive')"
        (blur)="onBlur('isClientFieldActive')"
      />
    </mat-form-field>
  </div>

  <div>
    <div class="mt-2">
      <mat-label
        class="form__label"
        [ngClass]="isLocationFieldActive ? 'form__label_active' : ''"
        >{{ "location" | translate }}</mat-label
      >
      <span *ngIf="isLocationFieldActive" class="counter"
        >{{ " (" }}{{ this.location.value | counterCharacters }}/500{{
          ")"
        }}</span
      >
    </div>
    <mat-form-field appearance="outline" class="form__field">
      <input
        type="text"
        matInput
        class="textFormat"
        [(ngModel)]="projectInfo.location.value"
        [value]="projectInfo.location.value"
        #location
        maxlength="500"
        (focus)="onFocus('isLocationFieldActive')"
        (blur)="onBlur('isLocationFieldActive')"
      />
    </mat-form-field>
  </div>

  <div>
    <div class="mt-2">
      <mat-label
        class="form__label"
        [ngClass]="isDesignOfficeFieldActive ? 'form__label_active' : ''"
        >{{ "ProjectData_UserOffice" | translate }}</mat-label
      >
      <span *ngIf="isDesignOfficeFieldActive" class="counter"
        >{{ " (" }}{{ this.designoffice.value | counterCharacters }}/500{{
          ")"
        }}</span
      >
    </div>
    <mat-form-field appearance="outline" class="form__field">
      <input
        type="text"
        matInput
        class="textFormat"
        [(ngModel)]="projectInfo.designOffice.value"
        [value]="projectInfo.designOffice.value"
        #designoffice
        maxlength="500"
        (focus)="onFocus('isDesignOfficeFieldActive')"
        (blur)="onBlur('isDesignOfficeFieldActive')"
      />
    </mat-form-field>
  </div>
  <div>
    <div class="mt-2">
      <mat-label
        class="form__label"
        [ngClass]="isCommentFieldActive ? 'form__label_active' : ''"
      >
        {{ "comment" | translate }}
      </mat-label>
      <span *ngIf="isCommentFieldActive" class="counter"
        >{{ "(" }}{{ this.comment.value | counterCharacters }}/500{{
          ")"
        }}</span
      >
    </div>

    <mat-form-field appearance="outline" class="form__textarea">
      <textarea
        matInput
        rows="8"
        style="resize: none"
        class="textFormat"
        [(ngModel)]="projectInfo.comment.value"
        [value]="projectInfo.comment.value"
        #comment
        maxlength="500"
        (focus)="onFocus('isCommentFieldActive')"
        (blur)="onBlur('isCommentFieldActive')"
      ></textarea>
    </mat-form-field>
  </div>
</div>

<div class="d-flex px-4 py-4">
  <!-- FIXME: type="submit does nothing if it is not related to a form." -->
  <button
    type="submit"
    mat-button
    class="form__btn btn--cancel"
    (click)="onCancelProject()"
  >
    {{ "CANCEL" | translate }}
  </button>
  <!-- FIXME: there should only be one button with type="submit". Other buttons could be assigned to type="button" -->
  <button
    type="submit"
    mat-button
    class="form__btn btn--save"
    (click)="onActionClick()"
    [disabled]="validateProjectInfo()"
    [ngClass]="validateProjectInfo() ? 'btn--disabled' : ''"
  >
    {{ "ConfirmButton_TEXT" | translate }}
  </button>
</div>
