export default class DocumentService {
  async getTermsAndCondition(language: string) {
    let result;
    await fetch(`assets/termsAndCondition/${language}/Terms.html`)
      .then((resp) => {
        result = resp.text();
      })
      .catch((e) => {
        throw e;
      });
    return result;
  }
}
