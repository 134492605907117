import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-home-icon",
  templateUrl: "./home-icon.component.html",
  styles: [":host { display: grid; place-content: center }"],
})
export class HomeIconComponent {
  constructor() {}
}
