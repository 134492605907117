<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path d="M12.5 1.66675V3.33341H7.5V1.66675H12.5Z" fill="currentColor" />
  <path d="M9.16667 15.0001V8.33342H7.5V15.0001H9.16667Z" fill="currentColor" />
  <path d="M12.5 8.33342V15.0001H10.8333V8.33342H12.5Z" fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.5 5.00008V6.66675H4.16667V18.3334H15.8333V6.66675H17.5V5.00008H2.5ZM14.1667 6.66675H5.83333V16.6667H14.1667V6.66675Z"
    fill="currentColor"
  />
</svg>
