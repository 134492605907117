<div class="deviceMarksideBar">
  <div #searchDevice class="search">
    <mat-form-field class="device-add__search-input" appearance="outline">
      <input
        type="text"
        placeholder="{{ 'input_mrpd' | translate }}"
        matInput
        (ngModelChange)="onSearchChange($event)"
        [(ngModel)]="searchedDeviceText"
        (click)="openSearchResultBox()"
      />
      <mat-icon matSuffix *ngIf="!searchedDeviceText">search</mat-icon>
      <app-icons
        class="pointer"
        *ngIf="searchedDeviceText"
        (click)="clearSearchText()"
        [iconName]="'close'"
        [height]="16"
        [width]="16"
      ></app-icons>
    </mat-form-field>
  </div>
  <div class="device-view">
    <div class="device-view__sub-header">
      <div>{{ "tooltip.devices" | translate }}</div>
      <div
        class="icons-div"
        [ngClass]="
          addedProductsList.length === 0
            ? 'justify-content-end'
            : 'justify-content-between'
        "
      >
        <div
          matTooltip="{{ 'Action_moveup_tooltip' | translate }}"
          [hidden]="addedProductsList.length === 0"
          (click)="moveDevicePosition('up')"
          [ngClass]="
            selectedmark.label || isDisabledUp() ? 'icon-disable' : 'icon-close'
          "
        >
          <app-icons [iconName]="'move'" [type]="'up'"></app-icons>
        </div>
        <div
          matTooltip="{{ 'Action_movedown_tooltip' | translate }}"
          [hidden]="addedProductsList.length === 0"
          (click)="moveDevicePosition('down')"
          [ngClass]="
            selectedmark.label || isDisabledDown()
              ? 'icon-disable'
              : 'icon-close'
          "
        >
          <app-icons [iconName]="'move'" [type]="'down'"></app-icons>
        </div>
        <div
          class="icon-close"
          (click)="addShortCircuitCurrentLine()"
          matTooltip="{{
            'Action_add_shortcurcuitcurrent_tooltip' | translate
          }}"
        >
          <app-icons [iconName]="'scl'"></app-icons>
        </div>
      </div>
    </div>
    <div
      [ngClass]="addedProductsList.length > 0 ? 'devicelist' : 'default-text'"
    >
      <div
        #deviceList
        id="elementDevice"
        class="scrolDevice"
        (scroll)="onUserDeviceScroll($event)"
      >
        <div
          class="content-wrapper_defaut_message"
          *ngIf="addedProductsList.length === 0"
        >
          {{ "empty-devices-message" | translate }}
        </div>
        <div
          class="device-view__device-list"
          *ngFor="let prod of addedProductsList"
          [ngClass]="{
            'device-card__disable': prod.product.toggleSlashEye === false,
            'device-highlight': prod.product.isChecked === true
          }"
        >
          <app-device-card
            class="w-100"
            [projectInfo]="projectInfo"
            [selectedProduct]="prod.product"
            [productData]="prod"
            (selectedItemOnClick)="onProductSelect(prod)"
          ></app-device-card>
        </div>
      </div>
      <div class="d-flex justify-content-center pad-b">
        <button
          class="btn--primary device-view__btn-add-bottom"
          (click)="openCatalogDialog()"
        >
          <app-icons [iconName]="'plus'"></app-icons>
          <span>
            {{ "curves-view.addDevice" | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="mark-view" *ngIf="!tablet">
    <div class="device-view__header">
      {{ "curves-view.marks" | translate }}
    </div>
    <div
      class="content-wrapper_defaut_message"
      *ngIf="selectedViewMarksList && selectedViewMarksList.length === 0"
    >
      {{ "empty-marks-message" | translate }}
    </div>
    <div
      #markList
      id="elementMark"
      class="scrolMark"
      (scroll)="onUserMarkScroll($event)"
    >
      <div
        class="marks__List"
        *ngFor="let mark of selectedViewMarksList"
        [ngClass]="{
          'device-card__disable': mark.visible === false,
          'mark-highlight': mark.isChecked === true
        }"
      >
        <app-mark-card
          [folderId]="folderId"
          [projectInfo]="projectInfo"
          class="w-100"
          [selectedMark]="mark"
          [isDraft]="isDraft"
          (selectedItemOnClick)="onMarkSelect(mark.index)"
        ></app-mark-card>
      </div>
    </div>
  </div>
  <div class="mark-view" *ngIf="tablet">
    <div
      class="device-view__header d-flex justify-content-between align-center"
      *ngIf="selectedViewMarksList && selectedViewMarksList.length > 0"
    >
      {{ "curves-view.marks" | translate }}
      <button type="submit" class="icon-close" (click)="addMarkInTab()">
        <app-icons [iconName]="'plus'"></app-icons>
      </button>
    </div>
    <div
      class="d-flex justify-content-center"
      *ngIf="selectedViewMarksList && selectedViewMarksList.length === 0"
    >
      <button class="add_mark" type="submit" (click)="addMarkInTab()">
        <app-icons [iconName]="'plus'"></app-icons>
        {{ "Action_addMark_tooltip" | translate }}
      </button>
    </div>
    <div id="elementMark" class="scrolMark" (scroll)="onUserScroll($event)">
      <div
        class="content-wrapper_defaut_message"
        *ngIf="selectedViewMarksList && selectedViewMarksList.length === 0"
      >
        {{ "empty-marks-message" | translate }}
      </div>
      <div
        class="marks__List"
        *ngFor="let mark of selectedViewMarksList"
        [ngClass]="{
          'device-card__disable': mark.visible === false,
          'mark-highlight': mark.isChecked === true
        }"
      >
        <app-mark-card
          [folderId]="folderId"
          [projectInfo]="projectInfo"
          style="width: 98.5%"
          [selectedMark]="mark"
          [isDraft]="isDraft"
          (selectedItemOnClick)="onMarkSelect(mark.index)"
        ></app-mark-card>
      </div>
    </div>
  </div>
</div>
