import RelaySetting, { RelaySettingValueList } from "../../models/device-settings/DeviceRelaySettingModel";
import DeviceSettingsModel from "../../models/device-settings/DeviceSettingsModel";
import { Constants } from "../../../app/models/constants/Constants";
import SettingsSliderModel from "../../../app/models/device-settings/DeviceSettingsSliderModel";
import BaseModel from "../../../app/models/devices/BaseModel";
import FacadeListEntryModel from "../../../app/models/devices/FacadeListEntry";
import ProductModel from "../../../app/models/devices/ProductModel";
import ListEntry from "../../models/devices/ListEntry";
import ProtectiveDevice from "../../models/devices/protectiveDevice";

declare let Facade;

export default class FacadeService {
  async getSettingsObject(
    product: BaseModel,
    device: ProtectiveDevice
  ): Promise<Array<DeviceSettingsModel>> {
    let settingsRange: Array<DeviceSettingsModel> =
      new Array<DeviceSettingsModel>();
    // let settingsObject = (await this.getProtectiveDeviceCurve(product.name))
    //   .currentTimesettings;
    if (device) {
      Object.values(device.currentTimesettings).forEach((setting) => {
        settingsRange.push(this.createSettingObject(setting, product, device));
      });
    }
    return settingsRange;
  }

  async updateSettingObject(
    selectedSettingValue: DeviceSettingsModel,
    protectiveDevice: ProtectiveDevice,
    product: BaseModel
  ): Promise<DeviceSettingsModel> {
    let settingsObject: DeviceSettingsModel = new DeviceSettingsModel();
    protectiveDevice.currentTimesettings.forEach((setting) => {
      if (setting.getPartType() === selectedSettingValue.curveType) {
        settingsObject = this.createSettingObject(
          setting,
          product,
          protectiveDevice
        );
      }
    });
    return settingsObject;
  }
  createSettingObject(setting, product: BaseModel, device?: ProtectiveDevice) {
    let settings: DeviceSettingsModel = new DeviceSettingsModel();
    settings.curveType = setting.getPartType();
    settings.productDetails = product;
    settings.isDisengageable = setting.getIsDisengageable();
    settings.isEngaged = Facade.isActive(device.device, settings.curveType);
    settings.radioActions = setting.getRadioActions().g;
    if (settings.radioActions.length > 1) {
      this.getActiveCharacterisic(settings, device).then(
        (activeChar: string) => {
          settings.activeCharacteristic = activeChar;
        }
      );
    }

    setting.getSettingsObject().g.forEach((value) => {
      let settingValue: SettingsSliderModel = new SettingsSliderModel();
      settingValue.curveType = setting.getPartType();
      settingValue.inputLabel = value.getSettingKey();
      // settingValue.inputLabel =  settingValue.inputLabel.replace(/gt/g, ">");
      if (
        value.getSliderObject().getMin() === Infinity ||
        Number.isNaN(value.getSliderObject().getMin())
      ) {
        settingValue.minValue = null;
        settingValue.maxValue = null;
        settingValue.inputValue = null;
        settingValue.steps = null;
      } else {
        settingValue.minValue = value.getSliderObject().getMin();
        settingValue.maxValue = value.getSliderObject().getMax();
        if (settingValue.minValue !== settingValue.maxValue) {
          settingValue.steps = value.getSliderObject().getCount();
          // settingValue.steps =
          //   (settingValue.maxValue - settingValue.minValue) /
          //   (value.getSliderObject().getCount() - 1);
        } else {
          settingValue.steps = 1;
        }

        if (settingValue.steps === Infinity) settingValue.steps = 1;
        settingValue.type = value.getType();
        if (settingValue.type === Constants.CURRENT.toUpperCase()) {
          settingValue.units = "[A]";
        } else if (settingValue.type === Constants.TIME.toUpperCase()) {
          settingValue.units = "[s]";
        }
        settingValue.inputValue = value.getSliderObject().getValue();
        settingValue.inputIndex = value.getSliderObject().getIndex();
        // if (
        //   value.m_getSliderObject__()
        //     .f_controlType__com_siemens_simaris_pt_web_scu_SliderObject_ ===
        //   "ESET"
        // )
        settingValue.controlType = value
          .getSliderObject()
          .getActiveControlType();
        settingValue.controlTypeList = value.getSliderObject().getControlTypes()
          ? value.getSliderObject().getControlTypes().g
          : [];
      }
      if (settingValue.type !== Constants.HORIZONTALMULTIPLIER) {
        settings.currentTime.push(settingValue);
      } else {
        settings.currentRef = settingValue;
      }
    });
    return settings;
  }

  async getActiveCharacterisic(
    characteristic: DeviceSettingsModel,
    protectiveDev: ProtectiveDevice
  ): Promise<string> {
    let activeChar = Facade.getActiveCharacterisic(
      protectiveDev.device,
      characteristic.curveType
    );
    return activeChar;
  }
  async getRadioAction(
    characteristic: DeviceSettingsModel,
    protectiveDev: ProtectiveDevice
  ): Promise<ProtectiveDevice> {
    let deviceData = protectiveDev.device;
    let device;
    // if(characteristic.productDetails.name.includes('7SJ')){
    //   deviceData = protectiveDev.device.mvDeviceData;
    // }
    device = Facade.setCharacteristic(
      deviceData,
      characteristic.curveType,
      characteristic.selectedCharacteristic
    );
    let updatedData = this.getChartData(
      device,
      protectiveDev.color,
      protectiveDev.lineStyle,
      protectiveDev.lineWidth
    );
    return updatedData;
  }
  async setActive(
    characteristic: DeviceSettingsModel,
    protectiveDev: ProtectiveDevice
  ): Promise<ProtectiveDevice> {
    //isActive - device and protectiveFunction

    let device = Facade.setActive(
      protectiveDev.device,
      characteristic.curveType,
      characteristic.isEngaged
    );
    return this.getChartData(
      device,
      protectiveDev.color,
      protectiveDev.lineStyle,
      protectiveDev.lineWidth
    );
  }
  async onESetClick(
    sliderValue: SettingsSliderModel,
    protectiveDev: ProtectiveDevice,
    productData: BaseModel
  ) {
    let protectiveDevice: any;
    let settingObject: DeviceSettingsModel = new DeviceSettingsModel();
    // settingObject.currentTime.push(sliderValue);
    settingObject.curveType = sliderValue.curveType;
    let controlType;
    if (sliderValue.controlType === "STD") {
      controlType = "ESET";
    } else if (sliderValue.controlType === "ESET") {
      controlType = "STD";
    }
    if (sliderValue.type === "CURRENT") {
      protectiveDevice = Facade.setActiveCurrentControl(
        protectiveDev.device,
        sliderValue.curveType,
        sliderValue.inputLabel,
        controlType
      );
    } else if (sliderValue.type == "TIME") {
      protectiveDevice = Facade.setActiveTimeControl(
        protectiveDev.device,
        sliderValue.curveType,
        sliderValue.inputLabel,
        controlType
      );
    }
    return this.getChartData(
      protectiveDevice,
      protectiveDev.color,
      protectiveDev.lineStyle,
      protectiveDev.lineWidth
    );
  }
  async onHorizontalSliderValueChange(
    protectiveDev: ProtectiveDevice,
    sliderObject: SettingsSliderModel
  ): Promise<any> {
    let protectiveDevice = Facade.setRefPointToNearest(
      protectiveDev.device,
      sliderObject.curveType,
      "Isd",
      sliderObject.inputValue
    );
    return this.getChartData(
      protectiveDevice,
      protectiveDev.color,
      protectiveDev.lineStyle,
      protectiveDev.lineWidth
    );
  }
  async onRelaySettingClick(
    relaySetting: RelaySetting,
    protectiveDev: ProtectiveDevice
  ) {
    let device;
    if (relaySetting.key === Constants.MEDIUM_VOlTAGE) {
      device = Facade.setMediumVoltage(
        protectiveDev.device,
        relaySetting.selectedValue
      );
    } else if (relaySetting.key === Constants.LOW_VOLTAGE) {
      device = Facade.setLowVoltage(
        protectiveDev.device,
        relaySetting.selectedValue
      );
    } else if (relaySetting.key === Constants.CURRENT_TRANSFORMER_RATIO) {
      device = Facade.setCuVoltage(
        protectiveDev.device,
        relaySetting.selectedValue
      );
    }
    return this.getChartData(
      device,
      protectiveDev.color,
      protectiveDev.lineStyle,
      protectiveDev.lineWidth
    );
  }
  async getChangedSliderObject(
    sliderValue: SettingsSliderModel,
    protectiveDev: ProtectiveDevice
  ) {
    let protectiveDevice: ProtectiveDevice;
    if (sliderValue.type === "CURRENT") {
      protectiveDevice = Facade.setCurveMultiplier(
        protectiveDev.device,
        sliderValue.curveType,
        sliderValue.inputLabel,
        sliderValue.inputValue
      );
    } else if (sliderValue.type == "TIME") {
      protectiveDevice = Facade.setTimeMultiplier(
        protectiveDev.device,
        sliderValue.curveType,
        sliderValue.inputLabel,
        sliderValue.inputValue
      );
    }

    return this.getChartData(
      protectiveDevice,
      protectiveDev.color,
      protectiveDev.lineStyle,
      protectiveDev.lineWidth
    );
  }
  async getSliderObjectByIndex(
    sliderValue: SettingsSliderModel,
    protectiveDev: ProtectiveDevice
  ) {
    let protectiveDevice: ProtectiveDevice;
    if (sliderValue.type === "CURRENT") {
      protectiveDevice = Facade.selectNthCurrentValue(
        protectiveDev.device,
        sliderValue.curveType,
        sliderValue.inputLabel,
        sliderValue.inputIndex
      );
    } else if (sliderValue.type == "TIME") {
      protectiveDevice = Facade.selectNthTimeValue(
        protectiveDev.device,
        sliderValue.curveType,
        sliderValue.inputLabel,
        sliderValue.inputIndex
      );
    }

    return this.getChartData(
      protectiveDevice,
      protectiveDev.color,
      protectiveDev.lineStyle,
      protectiveDev.lineWidth
    );
  }
  async getRelaySettings(product: ProductModel): Promise<any> {
    try {
      let facadeResponse = Facade.getDeviceSettingsDropdown(
        product.protectiveDevice.device,
        product.facadeListEntry.listEntry.minVoltage,
        product.facadeListEntry.listEntry.maxVoltage
      );
      let relayResponse;
      if (facadeResponse) {
        relayResponse = facadeResponse.g;
        if (
          product.facadeListEntry.listEntry.mProductGroupId === "7SJ" ||
          product.facadeListEntry.listEntry.mProductGroupId === "7SR"
        ) {
          let mvCurrentChangerData =
            product.facadeListEntry.facade.getProtectiveDevice();
          relayResponse = Facade.getDeviceSettingsDropdown(
            mvCurrentChangerData,
            product.facadeListEntry.listEntry.minVoltage,
            product.facadeListEntry.listEntry.maxVoltage
          ).g;
        }

        let relaySettingList = new Array<RelaySetting>();
        relayResponse.forEach((settings) => {
          let relaySetting: RelaySetting = new RelaySetting();
          relaySetting.key = settings.getKey();
          relaySetting.valuesList = settings.getValueList();
          settings.getValueList().forEach((val) => {
            let list = new RelaySettingValueList();
            list.value = val;
            list.translatedValue = val.toLocaleString(
              localStorage.getItem("i18nextLng")
            );
            relaySetting.translatedvaluesList.push(list);
          });
          if (product.protectiveDevice) {
            if (relaySetting.key === Constants.MEDIUM_VOlTAGE) {
              (relaySetting.selectedValue = Facade.getMediumVoltage(
                product.protectiveDevice.device
              )),
                (relaySetting.translatedSelectedValue =
                  relaySetting.selectedValue.toLocaleString(
                    localStorage.getItem("i18nextLng")
                  ));
            } else if (relaySetting.key === Constants.LOW_VOLTAGE) {
              (relaySetting.selectedValue = Facade.getLowVoltage(
                product.protectiveDevice.device
              )),
                (relaySetting.translatedSelectedValue =
                  relaySetting.selectedValue.toLocaleString(
                    localStorage.getItem("i18nextLng")
                  ));
            } else if (
              relaySetting.key === Constants.CURRENT_TRANSFORMER_RATIO
            ) {
              (relaySetting.selectedValue = Facade.getCuVoltage(
                product.protectiveDevice.device
              )),
                (relaySetting.translatedSelectedValue =
                  relaySetting.selectedValue.toLocaleString(
                    localStorage.getItem("i18nextLng")
                  ));
            }
          }
          relaySettingList.push(relaySetting);
        });
        return relaySettingList;
      }
    } catch (err) {
      throw err;
    }
  }
  async getCProducts(): Promise<BaseModel[]> {
    let searchItems = Facade.getCProductList();

    let searchItemList: BaseModel[] = new Array<BaseModel>();
    // @ts-ignore
    Object.values(searchItems)[0].forEach((element: any) => {
      let searchItem: BaseModel = new BaseModel();
      //@ts-ignore
      searchItem.id = element.getId();
      searchItem.name = element.getOrderNumber();
      searchItemList.push(searchItem);
    });
    return searchItemList;
  }
  async readListEntry(listEntry: ListEntry): Promise<FacadeListEntryModel> {
    try {
      let facadeListEntryModel = new FacadeListEntryModel();
      facadeListEntryModel.listEntry = listEntry;

      let facadeListEntry = new ListEntry();
      // facadeListEntry.mProduct = listEntry.mProduct;
      facadeListEntry.mProtectiveDevice = listEntry.mProtectiveDevice;
      facadeListEntry.deps = listEntry.deps;

      let facade = Facade.readListEntry(JSON.stringify(facadeListEntry));
      facadeListEntryModel.facade = facade;
      return facadeListEntryModel;
    } catch (error) {
      throw error;
    }
  }
  async writeListEntry(facade: FacadeListEntryModel): Promise<string> {
    let listEntry: string;
    try {
      listEntry = Facade.writeListEntry(facade.facade);
      console.log(
        Facade.getCurrentSettingsFromDevice(
          facade.facade.getProtectiveDevice()
        ),
        "yuhu"
      );
      return listEntry;
    } catch (err) {
      throw err;
    }
  }
  async getProtectiveDeviceCurve(
    facadeListEntry: FacadeListEntryModel
  ): Promise<ProtectiveDevice> {
    let protectiveDevice: any;
    if (
      facadeListEntry.listEntry.mlfb &&
      facadeListEntry.listEntry.mlfb.includes("7SJ")
    ) {
      let mvDeviceData = Facade.getMVCurrentChanger(
        facadeListEntry.facade.getProtectiveDevice()
      );
      protectiveDevice = Facade.getProtectiveDeviceWrapper(mvDeviceData);
      protectiveDevice.mvCurrentChangerData =
        facadeListEntry.facade.getProtectiveDevice();
      protectiveDevice.mvDeviceData = mvDeviceData;
      // protectiveDevice.mvDeviceData =
      //   facade.getProtectiveDevice().f_devWrapper__com_siemens_simaris_tripchar_model_device_MVCurrentChangerImpl;
      return this.getChartData(
        protectiveDevice.mvCurrentChangerData,
        facadeListEntry.listEntry.color,
        facadeListEntry.listEntry.lineStyle,
        facadeListEntry.listEntry.lineWidth
      );
    } else {
      protectiveDevice = facadeListEntry.facade.getProtectiveDevice();
      return this.getChartData(
        protectiveDevice,
        facadeListEntry.listEntry.color,
        facadeListEntry.listEntry.lineStyle,
        facadeListEntry.listEntry.lineWidth
      );
    }
  }
  getChartData(
    protectiveDevice: any,
    color: string,
    lineStyle: string,
    lineWidth: number
  ) {
    // let color = ColorUtil.getDarkColor();
    let map = Facade.getTrippingCurve(protectiveDevice);
    let letThroughMap = Facade.getLetThroughCurve(protectiveDevice);
    let cutOffMap = Facade.getCurrentCurve(protectiveDevice);
    let protective = new ProtectiveDevice();
    protective.device = protectiveDevice;

    // protective.facadeListEntry = facadeListEntry;
    // protective.facadeListEntry.facade.h = protectiveDevice;
    // protective.facadeListEntry.label = facadeListEntry.listEntry.mProduct["m_strMlfb"]
    // protective.facadeListEntry.index = 0;

    protective.lowerCurveSeriesTrippingChar =
      map.get("Lower") != undefined ? map.get("Lower").g : null;
    protective.upperCurveSeriesTrippingChar =
      map.get("Upper") != undefined ? map.get("Upper").g : null;
    protective.letThroughEnergyCurve =
      letThroughMap != null && letThroughMap.get("LetThroughCurve") != null
        ? letThroughMap.get("LetThroughCurve").g
        : null;
    protective.cutOffCurrentCurve =
      cutOffMap != null && cutOffMap.get("CurrentCurve") != null
        ? cutOffMap.get("CurrentCurve").g
        : null;
    protective.color = color;
    protective.mvCurrentChangerData = protectiveDevice.mvCurrentChangerData;
    protective.mvDeviceData = protectiveDevice.mvDeviceData;
    protective.currentTimesettings =
      Facade.getCurrentSettingsFromDevice(protectiveDevice).g;
    protective.lineStyle = lineStyle;
    protective.lineWidth = lineWidth;

    return protective;
  }
  getSnapPoint(pointData) {
    try {
      let point = Facade.getSnapPoint(pointData);
      return point;
    } catch (err) {
      throw err;
    }
  }
  getNearestCurve(values) {
    try {
      let returnValue = Facade.findNearestCurve(values);
      return returnValue;
    } catch (err) {
      throw err;
    }
  }
  getYvalue(curveDetails, xValue) {
    try {
      let yValue = Facade.interpolateY(curveDetails, xValue);
      return yValue;
    } catch (err) {
      throw err;
    }
  }
  getXvalue(curveDetails, yValue) {
    try {
      let xValue = Facade.interpolateX(curveDetails, yValue);
      return xValue;
    } catch (err) {
      throw err;
    }
  }

  getOrderNumberProposals(productGrpToOrderNumJson) {
    try {
      return Facade.getOrderNumberProposals(productGrpToOrderNumJson);
    } catch (err) {
      throw err;
    }
  }

  getProposalsForSearch(productGrpToOrderNumJson, searchText: string) {
    try {
      return productGrpToOrderNumJson.getProposalsFor(searchText);
    } catch (err) {
      throw err;
    }
  }
}
