export default class CurveTracingModel {
  mouseEvent: any;
  prevChartX: number;
  prevChartY: number;
  prevSnapPoint;
  formattedXValue: any;
  formattedYValue: any;
  xValue;
  yValue;
  tooltipLeftPos: number;
  tooltipTopPos: number;
  isToolTipVisible: boolean;
}
