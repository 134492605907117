export default class SettingsSliderModel {
  curveType: string = "";
  inputLabel: string;
  inputValue: number;
  inputIndex: number;
  translatedInputValue: string;
  translatedMinValue: string;
  translatedMaxValue: string;
  minValue: number;
  maxValue: number;
  steps: number;
  type: string = "";
  units: string;
  controlType: string;
  controlTypeList: Array<String> = [];
  isSliderValueChanged: boolean = false;
}
