import { Component, OnInit, Input } from "@angular/core";
import DeviceSettingsModel from "../../models/device-settings/DeviceSettingsModel";
import RelaySetting, {
  RelaySettingValueList,
} from "../../models/device-settings/DeviceRelaySettingModel";
import { NgEventBus } from "ng-event-bus";
import { EventType } from "../../models/constants/eventType";
import BaseModel from "../../models/devices/BaseModel";

@Component({
  selector: "app-setting-dropdown",
  templateUrl: "./setting-dropdown.component.html",
  styleUrls: ["./setting-dropdown.component.css"],
})
export class SettingDropdownComponent {
  @Input() relaySetting: RelaySetting;
  @Input() characteristicList;
  @Input() curveType: string;
  @Input() activeCharacteristic: string;
  @Input() productDetails: BaseModel;
  @Input() isDisable: boolean;

  constructor(public eventBus: NgEventBus) {}
  onSelect(val: RelaySettingValueList) {
    this.relaySetting.selectedValue = val.value;
    this.eventBus.cast(EventType.EMIT_RELAYSETTING_CHANGE, this.relaySetting);
  }
  onCharacteristicChange(val: string) {
    let characteristicValue: DeviceSettingsModel = new DeviceSettingsModel();
    characteristicValue.curveType = this.curveType;
    characteristicValue.selectedCharacteristic = val;
    characteristicValue.productDetails = this.productDetails;
    this.eventBus.cast(EventType.EMIT_RADIO_EVENT, characteristicValue);
  }
}
