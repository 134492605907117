import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-info-banner",
  templateUrl: "./info-banner.component.html",
  styleUrls: ["./info-banner.component.css"],
})
export class InfoBannerComponent {
  @Input() content: string;
  @Output() emitSaveCurvesEvent = new EventEmitter();
  constructor() {}

  // ngOnInit(): void {
  // }
  saveDraft() {
    this.emitSaveCurvesEvent.emit("");
  }
}
