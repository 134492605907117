<svg
  xmlns="http://www.w3.org/2000/svg"
  width="17"
  height="16"
  viewBox="0 0 17 16"
  fill="none"
>
  <path
    d="M3.5612 2.6665V5.33384H2.89453V6.6665H3.5612V13.3332H4.89453V6.6665H5.5612V5.33384H4.89453V2.6665H3.5612Z"
    fill="currentColor"
  />
  <path
    d="M7.5614 2.6665L7.56126 9.3333H6.8946V10.6666H7.56126L7.5614 13.3332H8.89473L8.8946 10.6666H9.56126V9.3333H8.8946L8.89473 2.6665H7.5614Z"
    fill="currentColor"
  />
  <path
    d="M11.5606 6.66664V2.66664L12.895 2.6665L12.8946 6.66664H13.5613L13.5617 7.99984H12.895V13.3332H11.561V7.99984H10.8943L10.8939 6.66664H11.5606Z"
    fill="currentColor"
  />
</svg>
