<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M14.8801 5.75977H11.0401L7.68005 11.9312H10.5601L8.16005 17.2798L15.3601 9.87405H12.0001L14.8801 5.75977Z"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <circle
    cx="11.52"
    cy="11.52"
    r="11.02"
    stroke="currentColor"
    stroke-dasharray="2 2"
  />
  <circle cx="19.2" cy="19.1999" r="4.8" fill="currentColor" />
  <path
    d="M22.0799 19.6362V18.7634L19.6363 18.7634V16.3198L18.7636 16.3198V18.7634L16.3199 18.7634L16.3199 19.6362H18.7636L18.7636 22.0798H19.6363V19.6362H22.0799Z"
    fill="white"
  />
</svg>
