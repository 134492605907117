import {
  ConnectionPositionPair,
  Overlay,
  OverlayRef,
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription } from "rxjs";
import {
  ColorPaletteComponent,
  PROJECTINFO,
  SELECTEDMARK,
} from "../color-palette/color-palette.component";
import { Constants } from "../models/constants/Constants";
import ProjectInfo from "../models/drive/projectInfo";
import { EventType } from "../models/constants/eventType";
import MarkModel from "../models/marks/MarkModel";
import { serviceFactory } from "../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../services/toaster.service";

const positions = [
  new ConnectionPositionPair(
    { originX: "end", originY: "bottom" },
    { overlayX: "end", overlayY: "top" }
  ),
  new ConnectionPositionPair(
    { originX: "end", originY: "top" },
    { overlayX: "end", overlayY: "bottom" }
  ),
];
@Component({
  selector: "app-mark-card",
  templateUrl: "./mark-card.component.html",
  styleUrls: ["./mark-card.component.css"],
})
export class MarkCardComponent implements OnDestroy {
  @Input() projectInfo: ProjectInfo;
  @Input() selectedMark: MarkModel;
  @Output() selectedItemOnClick = new EventEmitter();
  markNameLabel: string;
  color: string = "";
  subscriptions: Subscription = new Subscription();
  @ViewChild("markName") markName: ElementRef;
  overlayRef: OverlayRef;
  @ViewChild("colorpalette") colorpalette: any;
  injector: Injector;
  constructor(
    public overlay: Overlay,
    public eventBus: NgEventBus,
    private toasterService: ToasterService,
    private renderer: Renderer2,
    private sessionExpiredDialog: SessionExpiredService
  ) {
    const OnColorChange = this.eventBus
      .on(EventType.ON_MARK_COLORCHANGE)
      .subscribe((metaData: MetaData) => {
        let mark = new MarkModel();
        mark = metaData.data;
        if (
          metaData.data &&
          this.selectedMark.markListIndex == mark.markListIndex
        ) {
          this.color = mark.rgbValue;
          // this.colorChange();
        }
      });
    const closeLineConfig = this.eventBus
      .on(EventType.CLOSELINECONFIG)
      .subscribe(() => {
        if (this.overlayRef) {
          this.overlayRef.detach();
          this.selectedMark.isMarkConfigurationOpen = false;
        }
      });

    this.renderer.listen("window", "click", (e: Event) => {
      if (!this.markName.nativeElement.contains(e.target)) {
        this.selectedMark.isEditMarkLabel = false;
      }
    });

    this.subscriptions.add(closeLineConfig);
    this.subscriptions.add(OnColorChange);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  async editMarkLabel() {
    this.selectedMark.isEditMarkLabel = false;
    this.selectedMark.isMarkLabelIsEdited = true;
    let updatedMarkIndex: number = this.selectedMark.index;
    if (this.markNameLabel && this.markNameLabel !== this.selectedMark.label) {
      let updateValue = new MarkModel();
      updateValue = this.selectedMark;
      updateValue.label = this.markNameLabel;
      await serviceFactory.MarkService.updatemark(
        updateValue,
        this.selectedMark.index,
        this.projectInfo.id
      )
        .then((markList: MarkModel[]) => {
          this.eventBus.cast(EventType.UPDATED_MARK_LIST, markList);
          this.eventBus.cast(
            EventType.HIGHLIGHT_SELECTED_MARK,
            updatedMarkIndex
          );

          // if (response.label) {
          //   this.selectedMark.label = response.label;
          //   this.selectedMark.translatedLabel = response.label;
          //   this.eventBus.cast(EventType.EDITLABELMARK, this.selectedMark);
          // }
        })
        .catch((err: AxiosError) => {
          this.markNameLabel = this.selectedMark.label;
          if (err.response.data === Constants.SESSIONEXPIRED) {
            this.sessionExpiredDialog.openDialog();
          } else {
            this.toasterService.showError(err);
          }
        });
    }
  }
  setEditingMode() {
    this.markNameLabel = this.selectedMark.label;
    this.selectedMark.isEditMarkLabel = !this.selectedMark.isEditMarkLabel;
  }

  openConfiguration(event) {
    event.stopPropagation();
    this.selectedMark.isMarkConfigurationOpen = true;
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.markName)
        .withPositions(positions)
        .withPush(true),
      hasBackdrop: true,
    });
    const componentPortal = new ComponentPortal(
      ColorPaletteComponent,
      null,
      this.createPortalInjector(this.selectedMark, this.projectInfo)
    );
    this.overlayRef.attach(componentPortal);
    this.overlayRef.backdropClick().subscribe(() => {
      // this.overlayRef.detach();
    });
  }
  createPortalInjector(selectedMark: MarkModel, projectInfo: ProjectInfo) {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: SELECTEDMARK, useValue: selectedMark },
        { provide: PROJECTINFO, useValue: projectInfo },
      ],
    });
  }
  emitSelectedItem(item: MarkModel) {
    item.isChecked = true;
    let newItem = new MarkModel();
    newItem.current = item.current;
    newItem.isChecked = item.isChecked;
    newItem.isEditMarkLabel = item.isEditMarkLabel;
    newItem.label = item.label;
    newItem.markListIndex = item.markListIndex;
    newItem.mlabel = item.mlabel;
    newItem.rgbValue = item.rgbValue;
    newItem.time = item.time;
    newItem.visible = item.visible;

    this.selectedItemOnClick.emit(newItem);
    // setTimeout(() => {
    //   this.eventBus.cast(EventType.AFTERSIDEBARNAVIGATION, true);
    // },300)
  }
  async colorChange() {
    if (this.color && this.color !== this.selectedMark.rgbValue) {
      let updateValue = new MarkModel();
      updateValue = this.selectedMark;
      updateValue.rgbValue = this.color;
      let updatedMarkIndex: number = this.selectedMark.index;
      //TODO::Folder Id Removal Migration
      await serviceFactory.MarkService.updatemark(
        updateValue,
        this.selectedMark.index,
        this.projectInfo.id
      )
        .then((markList: MarkModel[]) => {
          this.eventBus.cast(EventType.UPDATED_MARK_LIST, markList);
          this.eventBus.cast(
            EventType.HIGHLIGHT_SELECTED_MARK,
            updatedMarkIndex
          );
        })
        .catch((err: AxiosError) => {
          if (err.response.data === Constants.SESSIONEXPIRED) {
            this.sessionExpiredDialog.openDialog();
          } else {
            this.toasterService.showError(err);
          }
        });
    }
  }
}
