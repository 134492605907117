<div class="dialog">
  <div class="dialog__header">
    <span>{{ "CurrentTimeDialog_title" | translate }}</span>
    <span class="icon-close" (click)="close()">
      <app-icons [iconName]="'close'"></app-icons>
    </span>
  </div>
  <div>
    <div class="row">
      <mat-label
        class="col-md-6 labelfont"
        [ngClass]="data.isMarkOnSeriesData ? 'labelLink' : ''"
        >{{ xLabel }}</mat-label
      >
      <mat-label
        class="col-md-6 labelfont"
        [ngClass]="data.isMarkOnSeriesData ? 'labelLink' : ''"
        >{{ yLabel }}</mat-label
      >
    </div>
    <div
      class="inputField row"
      [ngClass]="data.isMarkOnSeriesData ? 'space' : ''"
    >
      <mat-form-field appearance="outline">
        <input
          id="current"
          type="text"
          class="col-md-6"
          matInput
          [(ngModel)]="data.current"
          (input)="updatedTime(data.current)"
          matTooltip="{{ tooltipXVal }}"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input
          id="time"
          type="text"
          class="col-md-6"
          matInput
          [(ngModel)]="data.time"
          (input)="updateCurrent(data.time)"
          matTooltip="{{ tooltipYVal }}"
        />
      </mat-form-field>

      <div
        class="icon-close"
        *ngIf="data.isMarkOnSeriesData && isShowLinkUnlink"
        (click)="linkChange()"
      >
        <app-icons [iconName]="'link'" [type]="'link'"></app-icons>
      </div>
      <div
        class="icon-close"
        *ngIf="data.isMarkOnSeriesData && !isShowLinkUnlink"
        (click)="linkChange()"
      >
        <app-icons [iconName]="'link'" [type]="'unlink'"></app-icons>
      </div>
    </div>
  </div>
  <div class="action-btn-div">
    <button
      type="submit"
      mat-button
      class="dialog__btn btn--ok"
      [ngClass]="isValidXYValue() ? '' : 'grayOut'"
      (click)="proceed()"
      [disabled]="!isValidXYValue()"
    >
      {{ "Button_add" | translate }}
    </button>
    <button
      type="submit"
      mat-button
      [ngClass]="'dialog__btn btn--cancell'"
      (click)="cancelEvent()"
    >
      {{ "CANCEL" | translate }}
    </button>
  </div>
</div>
