import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { HttpLoaderFactory } from "../app.module";
import { CustomLoader } from "../language/custom.loader";
import { LanguageMissingTranslationHandler } from "../language/language.missing-translation-handler";
import { FormatTimePipe } from "../services/pipes/format-time.pipe";
import { SessionTimerComponent } from "../session-timer/session-timer.component";
import { InformationPageComponent } from "./information-page/information-page.component";
import { OperationalHeaderComponent } from "./operational-header/operational-header.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { NavBarComponent } from "./nav-bar/nav-bar.component";
import { ProjectsModule } from "../project-main-view/projects.module";
import { CommonHelperModule } from "../common/common-helper.module";
@NgModule({
  declarations: [
    OperationalHeaderComponent,
    SessionTimerComponent,
    FormatTimePipe,
    InformationPageComponent,
    ConfirmationDialogComponent,
    NavBarComponent,
  ],
  imports: [
    CommonModule,
    CommonHelperModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    FormsModule,
    MatSelectModule,
    MatTooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        useFactory: HttpLoaderFactory,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler,
      },
    }),
    ProjectsModule,
  ],
  exports: [
    OperationalHeaderComponent,
    SessionTimerComponent,
    FormatTimePipe,
    ProjectsModule,
    InformationPageComponent,
    ConfirmationDialogComponent,
    NavBarComponent,
    CommonHelperModule,
  ],
  providers: [ConfirmationDialogComponent],
  bootstrap: [],
  entryComponents: [ConfirmationDialogComponent],
})
export class SharedModule {}
