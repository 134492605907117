import { Component, OnDestroy } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription } from "rxjs";
import SpinnerModel from "../models/common/SpinnerModel";
import { EventType } from "../models/constants/eventType";
import { LoginConstants } from "../models/constants/loginConstants";
import { UserState } from "../store/cache.reducer";
import UserRegistrationUIModel from "./user-registration/userRegistrationUIModel";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.css"],
})
export class LoginPageComponent implements OnDestroy {
  registerButtonClicked: boolean = false;
  showTerms: boolean = false;
  subscriptions: Subscription = new Subscription();
  isNewUser: boolean = false;
  isLoginClicked: boolean = false;
  userPreFillData: UserRegistrationUIModel = new UserRegistrationUIModel();
  spinnerModel = new SpinnerModel();
  constructor(
    private auth: AuthService,
    private eventBus: NgEventBus,
    public translate: TranslateService,
    private store: Store<{ userState: UserState[] }>
  ) {
    this.checkIfUserProfilePresent();
    this.spinnerModel.show = false;
    this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
    const LOGIN_NAVIGATE_BACK = this.eventBus
      .on(EventType.LOGIN_NAVIGATE_BACK)
      .subscribe((metaData: MetaData) => {
        this.registerButtonClicked = false;
      });
    const LOGIN_SHOW_TERMS_COND = this.eventBus
      .on(EventType.LOGIN_SHOW_TERMS_COND)
      .subscribe((metaData: MetaData) => {
        this.showTerms = true;
      });

    this.subscriptions.add(LOGIN_NAVIGATE_BACK);
    this.subscriptions.add(LOGIN_SHOW_TERMS_COND);
  }
  checkIfUserProfilePresent() {
    this.store
      .select((state) => state.userState)
      .subscribe((userState: UserState[]) => {
        if (userState && userState.length > 0) {
          this.registerButtonClicked = true;
          this.isNewUser = true;
          userState.forEach((user) => {
            switch (user.key) {
              case LoginConstants.FIRSTNAME:
                this.userPreFillData.firstName = user.value;
              case LoginConstants.LASTNAME:
                this.userPreFillData.lastName = user.value;
              case LoginConstants.EMAILID:
                this.userPreFillData.emailId = user.value;
            }
          });
        }
      });
  }
  applicationLandingPage() {
    this.isLoginClicked = true;
    this.auth.loginWithRedirect();
  }

  selfRegistrationPage() {
    this.registerButtonClicked = !this.registerButtonClicked;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
