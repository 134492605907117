import Product from "./product";

export default class BaseModel {
  id?: number;
  name?: string;
  isChecked?: boolean = false;
  isCardOpen?: boolean = false;
  toggleSlashEye?: boolean = false;
  index!: number;
  type: string;
  productAttributes: Product;
  nominalVoltage: number;
  productGroup: string;
  orderNumber: string;
  isEditProductLabel: boolean = false;
  isEditProductLabelInSettings: boolean = false;
  voltage: string;
  ambientTemparature: number;
  ratedVoltage: number;
  isShortCircuitLineLabelEdited: boolean = false;
  isLineConfigurationOpen?: boolean = false;
}
