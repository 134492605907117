import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import axios from "axios";
import serviceUrlConstants from "../services/serviceLayer/serviceUrlConstants";

@Injectable()
export class CustomLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-language": localStorage.getItem("i18nextLng"),
    });
    const translationUrl = serviceUrlConstants.getTranslationServiceUrl();
    return this.http
      .get<any>(translationUrl, { headers })
      .pipe(map((res) => res));
  }
}
