<div class="dialog">
  <div class="header">
    <span class="dialog__header">
      <app-components-header
        [headerName]="'project-definition.uploadProject'"
        [fontSize]="'1.25rem'"
      ></app-components-header>
      <!-- {{ "project-definition.uploadProject" | translate }} -->
    </span>
    <span class="icon-close" (click)="cancelEvent()">
      <app-icons [iconName]="'close'"></app-icons>
    </span>
  </div>
  <div class="dialog__upload">
    <div
      class="dialog__upload__file-drag-drop"
      (drop)="handleDrop($event)"
      (dragover)="handleDragOver($event)"
    >
      <div class="headerVal">
        {{ "project-definition.upload_dragDrop" | translate }}
      </div>
      <div class="headerValOr">
        {{ "project-definition.upload_or" | translate }}
      </div>
      <label for="file-input" class="browseButton">{{
        "project-definition.upload_browseFile" | translate
      }}</label>
      <input
        type="file"
        (change)="getfile($event)"
        id="file-input"
        style="display: none"
        accept=".scu"
      />
      <div *ngIf="selectedFileName">
        {{ selectedFileName }}
      </div>
    </div>

    <div class="format">
      {{ "project-definition.upload_format" | translate }}: .scu
    </div>
  </div>
  <div class="dialog__btn-container">
    <button (click)="cancelEvent()" class="dialog__btn btn--cancel">
      {{ "CANCEL" | translate }}
    </button>
    <button
      (click)="onUpload()"
      mat-button
      class="dialog__btn btn--save"
      [disabled]="!invalidFile || isFileEmpty"
      [ngClass]="isFileEmpty || !invalidFile ? '' : 'btn--enable'"
    >
      {{ "project-definition.upload" | translate }}
    </button>
  </div>
</div>
<app-spinner
  *ngIf="spinnerModel.show"
  [spinnerText]="spinnerModel.text"
></app-spinner>
