import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import { EventType } from "../../models/constants/eventType";
@Component({
  selector: "app-default-project",
  templateUrl: "./default-project.component.html",
  styleUrls: ["./default-project.component.css"],
})
export class DefaultProjectComponent {
  @Output() uploadProjectClick = new EventEmitter();
  constructor(public eventBus: NgEventBus) {}

  onCreateProject() {
    this.eventBus.cast(EventType.CREATENEWPROJECT, true);
  }

  onUploadProject() {
    this.uploadProjectClick.emit(true);
  }
}
