<ag-grid-angular
  class="ag-theme-alpine grid"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [gridOptions]="gridOptions"
  [rowHeight]="'68'"
  (gridReady)="onGridReady($event)"
  (onCellClicked)="onRowClick($event)"
>
</ag-grid-angular>
