import KeyValueModel from "../../models/common/KeyValueModel";
import LineStyleModel from "../../models/devices/line-config/LineStyleModel";
import LineConfigPresetValueService from "./LineConfigPresetValueService";

export default class LineConfigurationService {
  presetValueService = new LineConfigPresetValueService();
  async getPresetColor(): Promise<KeyValueModel[]> {
    return this.presetValueService.getPresetColor();
  }

  async getLineStyles(): Promise<LineStyleModel[]> {
    return this.presetValueService.getLineStyles();
  }
  async getLineWidth(): Promise<KeyValueModel[]> {
    return this.presetValueService.getLineWidth();
  }
}
