<div class="main">
  <div class="d-flex w-100">
    <button
      class="bg-transparent border-0 p-0 arrow_style text-white me-3"
      (click)="navigateBack()"
    >
      <img src="../../../assets/icon/LeftArrow.svg" />
    </button>
    <div class="border-dark-primary">
      <div class="heading_border fw-bold ps-3 pb-2">
        SIMARIS <span>curves</span>
      </div>
      <p class="ps-3 mb-0 lh-1 text-white w-75">
        {{ "pages.login.subTitle" | translate }}
      </p>
    </div>
  </div>
  <div
    class="content-height d-flex justify-content-center flex-column col-xl-8 col-lg-8 col-md-8 align-items-center"
  >
    <div class="register-header my-4">
      {{ "pages.login.userReg" | translate }}
    </div>
    <div class="w-100">
      <app-email-window
        *ngIf="!isNewUser"
        [buttonName]="buttonName"
        (emitEmailValidStatus)="setUserProfileData($event)"
      ></app-email-window>
      <app-register-user-window
        *ngIf="isNewUser"
        [buttonName]="buttonName"
        [userProfileData]="userProfileData"
      ></app-register-user-window>
    </div>
    <div>
      <div class="d-flex justify-content-center">
        {{ "pages.login.alreadyAccount" | translate }} &nbsp;
        <u class="pointer text-dark-success" (click)="redirectToLogin()">
          {{ "pages.login.loginLink" | translate }}</u
        >
      </div>
    </div>
  </div>
</div>
