<div class="input-box">
  <div>
    <label class="label-font">{{ inputLabel }}</label>
  </div>
  <div>
    <input
      class="col-md-5 form-control form-control-sm input"
      [(ngModel)]="translatedInputValue"
      (change)="onValueChange($event.target.value)"
      matTooltip="{{ '[ ' + tranlatedTooltipValue + ' ]' }}"
    />
  </div>
</div>
