<div class="loginPage_bg_image vh-100">
  <div class="row m-0 justify-content-end">
    <div *ngIf="showTerms" class="col-xl-7 col-lg-7 col-md-7">
      <div class="vh-100 p-5">
        <div class="row justify-content-center h-100">
          <div class="col-xl-10 col-lg-10 col-md-10 bg-white p-0 h-100">
            <app-login-terms-condition
              (emitCloseTermsandCondition)="showTerms = !showTerms"
            ></app-login-terms-condition>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-5 col-lg-5 col-md-5 vh-100 p-5">
      <div class="login_bg_color h-100 w-100 d-flex justify-content-center">
        <div
          *ngIf="!registerButtonClicked"
          class="d-flex justify-content-center text-white h-100 flex-column col-xl-8 col-lg-10 col-md-10"
        >
          <div class="project-name-py w-100">
            <div class="login_border fw-bold">
              SIMARIS
              <span class="fnt-size">curves</span>
              <p class="left-border mb-0 lh-1 sub-title">
                {{ "pages.login.subTitle" | translate }}
              </p>
            </div>
          </div>
          <div class="w-100 h-100 d-flex flex-column justify-content-center">
            <div class="project-login-pb">
              <button
                class="btn btn-dark-success w-100 rounded-0 position-relative"
                type="button"
                (click)="applicationLandingPage()"
              >
                {{ "pages.login.login" | translate }}
                <span
                  *ngIf="isLoginClicked"
                  class="position-absolute h-100 d-flex align-items-center top-0 project-login-spinner"
                >
                  <span class="spinner-border text-primary position-static">
                    <span class="visually-hidden"></span>
                  </span>
                </span>
              </button>
            </div>

            <p class="small text-center mb-3">
              {{ "pages.login.notRegistered" | translate }}
            </p>
            <button
              class="btn btn-dark-outline-success w-100"
              type="button"
              (click)="selfRegistrationPage()"
            >
              {{ "pages.login.register" | translate }}
            </button>
          </div>
        </div>
        <div
          *ngIf="registerButtonClicked"
          class="text-white h-100 register_box w-100"
        >
          <app-user-self-registration
            [isNewUser]="isNewUser"
            [userPreFillData]="userPreFillData"
          ></app-user-self-registration>
        </div>
      </div>
    </div>
  </div>
</div>
