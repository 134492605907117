<mat-radio-group class="">
  <mat-radio-button
    [disabled]="isDisable"
    [ngStyle]="isDisable ? { 'pointer-events': 'none' } : ''"
    *ngFor="let label of radioLabels"
    [checked]="label === activeCharacteristic"
    value="{{ label }}"
    (click)="onRadioSelection(label)"
    >{{ label | translate }}</mat-radio-button
  >
</mat-radio-group>
