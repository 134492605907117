import { createReducer, on } from "@ngrx/store";
import OrderNumber from "../models/devices/orderNumber";
import CountryModel from "../models/projects/CountryModel";
import {
  loadCountryData,
  loadProductFailure,
  loadProductSuccess,
  loadUserData,
} from "./cache.action";

export interface ProductState {
  productGroup: string;
  orderNumberList: Array<OrderNumber>;
  error: string;
}
export interface UserState {
  key: string;
  value: string;
}

export interface CountryState {
  value: CountryModel[];
}
const initialState: ProductState[] = [];
const userDataState: UserState[] = [];
const countryState: CountryState[] = [];

export const productReducer = createReducer(
  initialState,
  on(loadProductSuccess, (state, action) => {
    if (!state.find((sta) => sta.productGroup === action.product)) {
      return [
        ...state,
        {
          productGroup: action.product,
          orderNumberList: action.orderNumber,
          error: null,
        },
      ];
    } else {
      return state;
    }
  }),
  on(loadProductFailure, (state, action) => {
    return [
      ...state,
      {
        productGroup: action.product,
        orderNumberList: null,
        error: action.error,
      },
    ];
  })
);

export const userReducer = createReducer(
  userDataState,
  on(loadUserData, (state, action) => {
    if (!state.find((sta) => sta.key === action.key)) {
      return [
        ...state,
        {
          key: action.key,
          value: action.value,
        },
      ];
    } else {
      return state;
    }
  })
);
export const countryReducer = createReducer(
  countryState,
  on(loadCountryData, (state, action) => {
    return [
      ...state,
      {
        value: action.value,
      },
    ];
  })
);
