<div class="highchart" id="highchart">
  <div
    id="cursor"
    *ngIf="
      curveTracingModel.formattedXValue && curveTracingModel.formattedYValue
    "
    (click)="clickOnCursor($event)"
  >
    <img height="15" width="15" src="../../assets/icon/Crosshair.svg" />
  </div>
  <app-tooltip
    id="tooltip"
    *ngIf="
      curveTracingModel.formattedXValue && curveTracingModel.formattedYValue
    "
    [tooltip]="getTooltipText()"
    [left]="curveTracingModel.tooltipLeftPos"
    [top]="curveTracingModel.tooltipTopPos"
    [snapPoint]="curveTracingModel.prevSnapPoint"
  >
  </app-tooltip>
  <div
    id="high-chart"
    [chart]="chartData"
    class="hc"
    (mousemove)="onMouseMove($event)"
    [ngClass]="
      chartType !== 'TRIP' && showHideDefaultText() ? 'chart-disable' : ''
    "
  ></div>
  <div id="high-chart" *ngIf="showHideDefaultText()" class="default-text">
    {{ "no_curves_available" | translate }}
  </div>
  <!-- <div
    id="high-chart"
    *ngIf="!currentEnergyGraphActive && chartType !== 'TRIP'"
    class="noCurve"
  >
    {{ "no_curves_available" | translate }}
  </div> -->
</div>
