export class ProductNode {
  name: string;
  product_group: string;
  parent_id?: string;
  tree_id?: string;
  img_url?: string;
  children?: ProductNode[];
  isSearched?: boolean = false;
  expandable: boolean;
  level: number;
}
