import { Component, EventEmitter, Output } from "@angular/core";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { ToasterService } from "../../services/toaster.service";
@Component({
  selector: "app-login-terms-condition",
  templateUrl: "./login-terms-condition.component.html",
  styleUrls: ["./login-terms-condition.component.css"],
})
export class LoginTermsConditionComponent {
  @Output() emitCloseTermsandCondition = new EventEmitter();
  termsAndConditionHTML: string = "";
  constructor(private toasterService: ToasterService) {
    this.fetchTermsAndCondition();
  }
  async fetchTermsAndCondition() {
    await serviceFactory.DocumentService.getTermsAndCondition(
      localStorage.i18nextLng
    )
      .then((res) => {
        this.termsAndConditionHTML = res;
      })
      .catch((err) => {
        this.toasterService.showError(err);
      });
  }
  closeTermsandCondition() {
    this.emitCloseTermsandCondition.emit(true);
  }
}
