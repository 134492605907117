import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ArrowIconComponent } from "./arrow-icon/arrow-icon.component";
import { CloseIconComponent } from "./close-icon/close-icon.component";
import { CurvesIconComponent } from "./curves-icon/curves-icon.component";
import { DeleteIconComponent } from "./delete-icon/delete-icon.component";
import { DocumentIconComponent } from "./document-icon/document-icon.component";
import { DownloadIconComponent } from "./download-icon/download-icon.component";
import { EditIconComponent } from "./edit-icon/edit-icon.component";
import { EyeIconComponent } from "./eye-icon/eye-icon.component";
import { FolderIconComponent } from "./folder-icon/folder-icon.component";
import { GlobeIconComponent } from "./globe-icon/globe-icon.component";
import { HomeIconComponent } from "./home-icon/home-icon.component";
import { IconsComponent } from "./icons.component";
import { InfoIconComponent } from "./info-icon/info-icon.component";
import { LinkIconComponent } from "./link-icon/link-icon.component";
import { MoveUpDownComponent } from "./move-up-down/move-up-down.component";
import { PlusIconComponent } from "./plus-icon/plus-icon.component";
import { PropertiesIconComponent } from "./properties-icon/properties-icon.component";
import { ResetIconComponent } from "./reset-icon/reset-icon.component";
import { SclIconComponent } from "./scl-icon/scl-icon.component";
import { SettingIconComponent } from "./setting-icon/setting-icon.component";
import { UploadIconComponent } from "./upload-icon/upload-icon.component";
import { LogoutIconComponent } from "./logout-icon/logout-icon.component";

@NgModule({
  declarations: [
    IconsComponent,
    PlusIconComponent,
    UploadIconComponent,
    DownloadIconComponent,
    DeleteIconComponent,
    EditIconComponent,
    CloseIconComponent,
    HomeIconComponent,
    CurvesIconComponent,
    InfoIconComponent,
    MoveUpDownComponent,
    SclIconComponent,
    ArrowIconComponent,
    FolderIconComponent,
    DocumentIconComponent,
    ResetIconComponent,
    GlobeIconComponent,
    LinkIconComponent,
    EyeIconComponent,
    SettingIconComponent,
    PropertiesIconComponent,
    LogoutIconComponent,
  ],
  imports: [CommonModule],
  exports: [
    IconsComponent,
    PlusIconComponent,
    UploadIconComponent,
    DownloadIconComponent,
    DeleteIconComponent,
    EditIconComponent,
    CloseIconComponent,
    HomeIconComponent,
    CurvesIconComponent,
    InfoIconComponent,
    MoveUpDownComponent,
    SclIconComponent,
    ArrowIconComponent,
    FolderIconComponent,
    DocumentIconComponent,
    ResetIconComponent,
    GlobeIconComponent,
    LinkIconComponent,
    EyeIconComponent,
    SettingIconComponent,
    PropertiesIconComponent,
    LogoutIconComponent,
  ],
})
export class IconsModule {}
