<svg
  xmlns="http://www.w3.org/2000/svg"
  [attr.width]="height"
  [attr.height]="width"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M10.0001 8.82155L3.92265 2.74414L2.74414 3.92265L8.82155 10.0001L2.74414 16.0775L3.92265 17.256L10.0001 11.1786L16.0775 17.256L17.256 16.0775L11.1786 10.0001L17.256 3.92265L16.0775 2.74414L10.0001 8.82155Z"
    fill="currentColor"
  />
</svg>
