import BaseModel from "../devices/BaseModel";
import SettingsSliderModel from "./DeviceSettingsSliderModel";

export default class DeviceSettingsModel {
  curveType: string = "";
  activeCharacteristic: string;
  radioActions: Array<string> = [];
  isDisengageable: boolean;
  isEngaged: boolean;
  currentTime: Array<SettingsSliderModel> = [];
  selectedCharacteristic: string;
  productDetails: BaseModel;
  currentRef: SettingsSliderModel;
}
