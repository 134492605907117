import {
  AfterViewInit,
  Component,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription, take } from "rxjs";
import BaseModel from "../models/devices/BaseModel";
import { Constants } from "../models/constants/Constants";
import { EventType } from "../models/constants/eventType";
import SpinnerModel from "../models/common/SpinnerModel";
import OrderNumber from "../models/devices/orderNumber";

export const NOMINALVOLTAGELIST = new InjectionToken<{}>("NOMINALVOLTAGELIST");
@Component({
  selector: "app-search-device-overlay",
  templateUrl: "./search-device-overlay.component.html",
  styleUrls: ["./search-device-overlay.component.css"],
})
export class SearchDeviceOverlayComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  selectedProduct: BaseModel = new BaseModel();
  subscriptions: Subscription = new Subscription();
  metaProductsList = [];
  productsList = [];
  nominalVoltageHeader: string;
  addDeviceBtnText: string;
  noResultFountText: string;
  spinnerModel = new SpinnerModel();
  constructor(
    public eventBus: NgEventBus,
    private translator: TranslateService,
    @Inject(NOMINALVOLTAGELIST) public nominalVoltageList: number
  ) {
    const SEARCHEDPRODUCTRESULTS = this.eventBus
      .on(EventType.SEARCHEDPRODUCTRESULTS)
      .subscribe((metaData: MetaData) => {
        let nominalVoltage = this.selectedProduct.nominalVoltage;
        this.spinnerModel.show = false;
        this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
        if (metaData.data && metaData.data.length > 0) {
          this.metaProductsList = metaData.data;
          this.loadProductListOnDemand();
        } else {
          this.productsList = [];
          this.selectedProduct = new BaseModel();
          this.selectedProduct.nominalVoltage = nominalVoltage;
        }
      });
    //setting translation for the text available in this component
    this.translator
      .get("ObjectListView_nominal_voltage")
      .pipe(take(1))
      .subscribe((value) => (this.nominalVoltageHeader = value));
    this.translator
      .get("curves-view.addDevice")
      .pipe(take(1))
      .subscribe((value) => (this.addDeviceBtnText = value));
    this.translator
      .get("grid.no-result")
      .pipe(take(1))
      .subscribe((value) => (this.noResultFountText = value));
    this.subscriptions.add(SEARCHEDPRODUCTRESULTS);
  }

  ngOnInit(): void {
    this.selectedProduct.nominalVoltage = Constants.DEFAULT_NOMINALVOLTAGE;
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  ngAfterViewInit() {
    this.loadProductListOnDemand();
  }
  loadProductListOnDemand() {
    // TO calcualte the number of elements can be displayed according to available div height

    let noOfDeviceToBeDisplayed;
    if (document.getElementById("searchListDefault")) {
      noOfDeviceToBeDisplayed = Math.round(
        document.getElementById("searchListDefault").clientHeight / 35
      );
    } else if (document.getElementById("searchList")) {
      noOfDeviceToBeDisplayed = Math.round(
        document.getElementById("searchList").clientHeight / 35
      );
    }
    if (this.metaProductsList.length > noOfDeviceToBeDisplayed) {
      this.productsList = this.metaProductsList.slice(
        0,
        noOfDeviceToBeDisplayed + 1
      );
    } else {
      this.productsList = this.metaProductsList;
    }
  }
  closeConfig() {
    this.eventBus.cast(EventType.CLOSELINECONFIG, true);
  }
  onProductSelection(selectedMLFB: OrderNumber, event) {
    if (selectedMLFB.name.length > Constants.PRODUCT_GROUP_LENGTH) {
      this.selectedProduct.name = selectedMLFB.name;
      this.selectedProduct.productGroup = selectedMLFB.productGroup;
    }
    this.eventBus.cast(
      EventType.ORDERNUMBERSELECTEDFROMSEARCHLIST,
      selectedMLFB
    );
  }
  changeNominalVoltage(voltage: number) {
    this.selectedProduct.nominalVoltage = voltage;
    this.selectedProduct.ratedVoltage = voltage;
  }
  addDevice() {
    this.eventBus.cast(EventType.ONADDPRODUCT, this.selectedProduct);
    this.eventBus.cast(EventType.CLOSELINECONFIG, true);
  }
  onScroll(event) {
    let length = this.productsList.length;
    if (length !== this.metaProductsList.length) {
      const moreList = this.metaProductsList.splice(length, length + 10);
      this.productsList = [...this.productsList, ...moreList];
    }
  }
}
