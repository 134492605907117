<div *ngIf="selectedProduct && selectedProduct.productAttributes" class="prop">
  <div *ngFor="let attr of selectedProduct.productAttributes.attributes">
    <div *ngIf="attr.m_strName">
      {{ attr.m_strName }}
    </div>
    <div class="prop-value">
      <span *ngIf="attr.m_Value">{{ attr.m_Value | translateNumber }}</span>
      <span *ngIf="attr.m_strUnit"> {{ attr.m_strUnit }}</span>
    </div>
  </div>
</div>
