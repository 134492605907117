<div class="main">
  <div class="text">
    {{ "default-project.defaultText" | translate }}
  </div>
  <div class="d-flex gap-3">
    <button class="btn--secondary" (click)="onUploadProject()">
      <app-icons [iconName]="'upload'"></app-icons>
      {{ "project-definition.uploadProject" | translate }}
    </button>
    <button class="btn--primary" (click)="onCreateProject()">
      <app-icons [iconName]="'plus'"></app-icons>
      {{ "project-definition.createProject" | translate }}
    </button>
  </div>
</div>
