import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "translateNumber",
})
export class TranslateNumberPipe implements PipeTransform {
  transform(num: any): string {
    return num.toLocaleString(localStorage.getItem("i18nextLng"));
  }
}
