<div class="h-100">
  <app-setting-scl-mark-header
    [selectedProduct]="selectedProduct"
    [selectedMark]="selectedMark"
    [projectinfo]="projectinfo"
    [catalogTreeData]="catalogTreeData"
    [isMarkSelected]="isMarkSelected"
  ></app-setting-scl-mark-header>

  <mat-tab-group
    #tab
    class="tab-group"
    *ngIf="selectedProduct"
    [selectedIndex]="selectedTab"
    (selectedTabChange)="changeTab($event)"
    [ngClass]="
      (selectedProduct &&
        selectedProduct.product &&
        selectedProduct.product.type === 'shortcircuitline') ||
      isMarkSelected
        ? 'settingHeader'
        : ''
    "
    disableRipple
    animationDuration="0ms"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <app-icons class="p-r-5px" [iconName]="'settings'"></app-icons>
        <span>{{ "pdf_description_settings" | translate }}</span>
      </ng-template>
      <div class="h-100">
        <app-device-settings-bar
          [selectedProduct]="selectedProduct.product"
        ></app-device-settings-bar>
      </div>
    </mat-tab>
    <mat-tab
      *ngIf="
        selectedProduct &&
        selectedProduct.product &&
        selectedProduct.product.type !== 'shortcircuitline' &&
        !isMarkSelected
      "
    >
      <ng-template mat-tab-label>
        <app-icons class="p-r-5px" [iconName]="'properties'"></app-icons>
        <span>{{ "ObjectListView_properties" | translate }}</span>
      </ng-template>
      <div class="h-100">
        <app-device-properties [selectedProduct]="selectedProduct.product">
        </app-device-properties>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
