import { Component } from "@angular/core";

@Component({
  selector: "app-logout-icon",
  templateUrl: "./logout-icon.component.html",
})
export class LogoutIconComponent {
  constructor() {}

}
