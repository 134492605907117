<div [hidden]="type !== 'up'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.8332 15.8326L10.8332 7.3571L13.5773 10.1012L14.7557 8.92285L9.99984 4.16699L5.24438 8.92285L6.42278 10.1012L9.16651 7.3572L9.16651 15.8326H10.8332Z"
      fill="currentColor"
    />
  </svg>
</div>
<div [hidden]="type !== 'down'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.16691 4.16699L9.16691 12.6425L6.42278 9.89837L5.24438 11.0767L10.0002 15.8326L14.7557 11.0767L13.5773 9.89837L10.8336 12.6424L10.8336 4.16699L9.16691 4.16699Z"
      fill="currentColor"
    />
  </svg>
</div>
