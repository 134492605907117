import { Location } from "@angular/common";
import { Component, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription, take } from "rxjs";
import CountryModel from "../../models/projects/CountryModel";
import { loadCountryData } from "../../store/cache.action";
import { CountryState } from "../../store/cache.reducer";
import DialogModel from "../../models/common/DialogModel";
import SpinnerModel from "../../models/common/SpinnerModel";
import { Constants } from "../../models/constants/Constants";
import { EventType } from "../../models/constants/eventType";
import { PagingConstants } from "../../models/constants/pagingConstants";
import ProjectInfo from "../../models/drive/projectInfo";
import { columnDef } from "../../models/json/project-grid-columndata";
import routerNames from "../../models/router/routerNames";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../services/toaster.service";
import { FileUploadComponent } from "../file-upload/file-upload.component";

@Component({
  selector: "app-project-list",
  templateUrl: "./project-list.component.html",
  styleUrls: ["./project-list.component.css"],
})
export class ProjectListComponent implements OnDestroy {
  searchText: string = "";
  gridColumnData: any;
  dialogModel = new DialogModel();
  @ViewChild("projectDets") projDetsSideNav: MatSidenav;
  spinnerModel = new SpinnerModel();
  gridRowData: ProjectInfo[] = [];
  subscriptions: Subscription = new Subscription();
  allProjectData: ProjectInfo[] = [];
  constructor(
    private eventBus: NgEventBus,
    public dialog: MatDialog,
    private route: Router,
    private toasterService: ToasterService,
    private sessionExpiredDialog: SessionExpiredService,
    private location: Location,
    public translateService: TranslateService,
    private store: Store<{ countryState: CountryState[] }>
  ) {
    this.gridColumnData = columnDef;

    this.refreshProjectList();
    this.setTranslationsForPagingConstants();
    this.getCountryList();
    const onCancelProjectCreation = this.eventBus
      .on(EventType.CANCELPROJECTCREATION)
      .subscribe((data: MetaData) => {
        this.projDetsSideNav.close();
        this.route.navigate([routerNames.getProjectRoute()]);
      });
    const onEditproject = this.eventBus
      .on(EventType.EDITPROJECT)
      .subscribe((data: MetaData) => {
        this.projDetsSideNav.open();
      });
    const onCreateNewProject = this.eventBus
      .on(EventType.CREATENEWPROJECT)
      .subscribe((metadata) => {
        this.OnCreateProject();
      });
    const onProjectAdd = this.eventBus
      .on(EventType.ADDPROJECTSUCCESS)
      .subscribe(async (data: MetaData) => {
        this.projDetsSideNav.close();
        this.refreshProjectList();
      });
    const onFileUpload = this.eventBus
      .on(EventType.ONFILEUPLOADSUCCESS)
      .subscribe(async (data: MetaData) => {
        this.refreshProjectList();
      });
    const onProjectEdit = this.eventBus
      .on(EventType.EDITPROJECTSUCCESS)
      .subscribe(async (data: MetaData) => {
        this.projDetsSideNav.close();
        this.refreshProjectList();
      });
    const onProjectDelete = this.eventBus
      .on(EventType.DELETEPROJECTSUCCESS)
      .subscribe(async (metadata) => {
        this.refreshProjectList();
      });
    const onBrowserBack = this.location.subscribe(() => {
      this.projDetsSideNav.close();
    });
    this.subscriptions.add(onCancelProjectCreation);
    this.subscriptions.add(onEditproject);
    this.subscriptions.add(onCreateNewProject);
    this.subscriptions.add(onProjectAdd);
    this.subscriptions.add(onFileUpload);
    this.subscriptions.add(onProjectEdit);
    this.subscriptions.add(onProjectDelete);
    this.subscriptions.add(onBrowserBack);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  isTranslationAvailable() {
    return (
      PagingConstants.page &&
      PagingConstants.of &&
      PagingConstants.to &&
      PagingConstants.noResultFoundText
    );
  }
  async getCountryList() {
    let countryList;
    await serviceFactory.ProjectService.getCountry()
      .then((countriesList) => {
        countryList = this.formCountryModel(countriesList);
        this.store.dispatch(
          loadCountryData({
            value: countryList,
          })
        );
      })
      .catch((err) => {
        if (err.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(err);
        }
      });
  }
  formCountryModel(countriesList: string[]): CountryModel[] {
    let translatedCountryList: CountryModel[] = [];
    countriesList.forEach((code) => {
      let country = new CountryModel();
      country.code = code;
      this.translateService
        .get("country." + code)
        .pipe(take(1))
        .subscribe((value) => (country.name = value));
      translatedCountryList.push(country);
    });
    translatedCountryList.sort((a, b) => a.name.localeCompare(b.name));
    return translatedCountryList;
  }
  setTranslationsForPagingConstants() {
    this.translateService
      .get("pagination.page")
      .pipe(take(1))
      .subscribe((value) => (PagingConstants.page = value));
    this.translateService
      .get("pagination.of")
      .pipe(take(1))
      .subscribe((value) => (PagingConstants.of = value));
    this.translateService
      .get("pagination.to")
      .pipe(take(1))
      .subscribe((value) => (PagingConstants.to = value));
    this.translateService
      .get("grid.no-result")
      .pipe(take(1))
      .subscribe((value) => (PagingConstants.noResultFoundText = value));
  }
  async refreshProjectList() {
    this.spinnerModel.show = true;
    this.spinnerModel.text = "loader.searchProject";
    this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
    this.searchText = "";
    await serviceFactory.ProjectService.getAllProjects()
      .then((projectList: ProjectInfo[]) => {
        projectList.forEach((project) => {
          if (project.country) {
            project.country.localizedValue = this.translateService.instant(
              Constants.COUNTRY_PREFIX + project.country.value
            );
            project.country.value = project.country.localizedValue;
          }
        });
        this.gridRowData = projectList;
        this.allProjectData = projectList;
      })
      .catch((err) => {
        if (err.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(err);
        }
      })
      .finally(() => {
        this.spinnerModel.show = false;
        this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
      });
  }
  OnCreateProject() {
    this.projDetsSideNav.open();
    this.route.navigate([routerNames.getAddProjectURL()]);
  }
  onUploadProject() {
    const dialogRef = this.dialog.open(FileUploadComponent, {
      width: "40.272rem",
      height: "30.875rem",
      data: "",
    });
    //TODO: send an event and make changes to file upload component as per new UX.
  }

  onSearchTree(searchText: string) {
    if (searchText.trim()) {
      searchText = searchText.toLowerCase();
      this.searchText = searchText;

      this.gridRowData = this.allProjectData.filter(
        (x) =>
          x.name?.toLowerCase().includes(searchText) ||
          x.country?.localizedValue.toLowerCase().includes(searchText) ||
          x.client?.value.toLowerCase().includes(searchText) ||
          x.designOffice?.value.toLowerCase().includes(searchText) ||
          x.projectDescription?.value.toLowerCase().includes(searchText)
      );
    } else {
      this.gridRowData = this.allProjectData;
    }
  }
  async clearSearchText() {
    this.onSearchTree("");
  }
}
