import { Component, Input } from "@angular/core";
import { NgEventBus } from "ng-event-bus";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.css"],
})
export class SpinnerComponent {
  isShow: boolean = false;
  @Input() spinnerText: string = "Loading...Please wait";
  constructor(private eventBus: NgEventBus) {}
}
