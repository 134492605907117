import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-arrow-icon",
  templateUrl: "./arrow-icon.component.html",
})
export class ArrowIconComponent {
  @Input() type: string;
  constructor() {}
}
