import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription } from "rxjs";
import { Constants } from "../../models/constants/Constants";
import { EventType } from "../../models/constants/eventType";

@Component({
  selector: "app-product-image",
  templateUrl: "./product-image.component.html",
  styleUrls: ["./product-image.component.css"],
})
export class ProductImageComponent implements OnDestroy {
  productImgUrl: string = "";
  subscriptions: Subscription = new Subscription();
  constructor(private eventBus: NgEventBus) {
    const onLeafNodeClick = this.eventBus
      .on(EventType.ON_LEAFNODE_CLICK)
      .subscribe((metadata: MetaData) => {
        let selectedNode = metadata.data;
        this.productImgUrl = "";
        if (selectedNode.img_url) {
          this.checkIfImageExists(
            this.productImgUrl
              .concat(Constants.CATALOG_IMG_PATH)
              .concat(selectedNode.img_url),
            (exists) => {
              this.productImgUrl = exists
                ? this.productImgUrl
                    .concat(Constants.CATALOG_IMG_PATH)
                    .concat(selectedNode.img_url)
                : Constants.EMPTYPRODUCTIMAGE;
            }
          );
        } else {
          this.productImgUrl = Constants.EMPTYPRODUCTIMAGE;
        }
      });
    const onParentNodeClick = this.eventBus
      .on(EventType.ON_PARENTNODE_CLICK)
      .subscribe((metadata: MetaData) => {
        let selectedNode = metadata.data;
        this.productImgUrl = "";
        if (selectedNode.img_url === "" || selectedNode.img_url === null) {
          this.productImgUrl = "../../../../assets/productGroups/empty.png";
        } else {
          this.productImgUrl = "../../../../assets/productGroups/";
          this.productImgUrl = this.productImgUrl.concat(selectedNode.img_url);
        }
      });
    this.subscriptions.add(onLeafNodeClick);
    this.subscriptions.add(onParentNodeClick);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  //   getImageUrl(productGroup: string) {
  //     this.productImgUrl = this.productImgUrl.concat(productGroup).concat(".jpg");
  //     this.checkIfImageExists(this.productImgUrl, (exists) => {
  //       if (!exists) {
  //         productGroup = productGroup.includes("_")
  //           ? productGroup.replace("_", "")
  //           : productGroup;
  //         this.productImgUrl =
  //           "../../../../assets/productGroups/" + productGroup + ".jpg";
  //         this.checkIfImageExists(this.productImgUrl, (exists) => {
  //           if (!exists) {
  //             if (productGroup.length > 3) {
  //               productGroup = productGroup.substring(0, 4);
  //             } else {
  //               productGroup = productGroup + "A";
  //             }
  //             this.productImgUrl =
  //               "../../../../assets/productGroups/" + productGroup + ".jpg";
  //             this.checkIfImageExists(this.productImgUrl, (exists) => {
  //               if (!exists) {
  //                 this.productImgUrl = Constants.EMPTYPRODUCTIMAGE
  // ;
  //               }
  //             });
  //           }
  //         });
  //       }
  //     });
  //   }
  // updateUrl() {
  //   if (this.source === "leafNode") {
  //     this.productGroups = this.productGroups.replace(".png", ".jpg");
  //     this.checkIfImageExists(this.productGroups, (exists) => {
  //       if (!exists) {
  //         this.productGroups = Constants.EMPTYPRODUCTIMAGE
  //       }
  //     });
  //   } else {
  //     this.productImgUrl = this.productImgUrl.replace(".png", ".jpg");
  //     this.checkIfImageExists(this.productImgUrl, (exists) => {
  //       if (!exists) {
  //         this.productImgUrl = Constants.EMPTYPRODUCTIMAGE
  //       }
  //     });
  //   }
  // }
  checkIfImageExists(url: string, callback) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  }
}
