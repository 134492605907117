import { Constants } from "../../../app/models/constants/Constants";
import ProjectInfo from "../../../app/models/drive/projectInfo";
import AxiosClient from "../client/AxiosClient";
import { ResultType } from "../models/Result";
import serviceUrlConstants from "../serviceLayer/serviceUrlConstants";
import DriveFileResponse from "./DriveFileResponse";

//TODO::Folder Id Removal Migration
export default class FileService {
  async uploadFile(file: File, projectId: string): Promise<ProjectInfo> {
    const url = serviceUrlConstants.fileImportUrl(projectId);
    let projectInfo: ProjectInfo = null;
    const bodyFormData = new FormData();
    bodyFormData.append("file", file);
    let header = { headers: AxiosClient.GetHeader("multipart/form-data") };
    await AxiosClient.post(url, header, bodyFormData)
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            projectInfo = response.value;
            return projectInfo;
          }
          case ResultType.FAIL: {
            throw response.message;
          }
        }
      })
      .catch((error) => {
        console.log(error, "ERROR");
        throw error;
      });
    return projectInfo;
  }
  //TODO::Folder Id Removal Migration
  async uploadFileToDrive(
    projectId: string,
    fileName: string,
    isAutoSaveOnBufferTime?: boolean
  ): Promise<DriveFileResponse> {
    if (fileName === "") {
      fileName = Constants.CONFIGURATIONFILENAME;
    }

    let header = { headers: AxiosClient.GetHeader("application/json") };
    const url = serviceUrlConstants.serverFileImportUrl(projectId, fileName);

    await AxiosClient.post(url, header, "")
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            return response.value;
          }
          case ResultType.FAIL: {
            throw response.message;
          }
        }
      })
      .catch((error) => {
        console.log(error, "ERROR");
        throw error;
      });
    return null;
  }
  async importFile(url: string): Promise<File> {
    let file: File = null;
    let headerConfig = {};
    await AxiosClient.get(
      url,
      {
        headers: headerConfig,
        responseType: "blob",
      },
      "blob"
    )
      .then((response) => {
        // window.open(url);
        console.log(response, "response");
        //TODO: convert arraybuffer to File
        switch (response.type) {
          case ResultType.SUCCESS: {
            // let blobObj=new Blob(response.value);
            // file = new File(blobObj, "configuration.sc");
            const blob = new Blob([response.value], {
              type: "application/octet-stream",
            });
            let myFile = this.blobToFile(blob, "configuration.sc");
            console.log(myFile.type, "file");
            file = myFile;
            return file;
          }
          case ResultType.FAIL: {
            throw response.message;
          }
        }
      })
      .catch((e) => {
        console.log(e, "error");
        // throw e;
      });
    return file;
  }
  blobToFile(theBlob: Blob, fileName: string): File {
    return new File([theBlob as Blob], fileName);
  }
}
