<div #overlay id="overlay" class="overlay">
  <div class="overlay__header">
    <div>
      {{ "line-configuration.lineConfiguration" | translate }}
    </div>
    <div
      style="color: var(--grid-header-color)"
      class="icon-close"
      (click)="closeConfig()"
    >
      <app-icons [iconName]="'close'"></app-icons>
    </div>
  </div>
  <div class="overlay__config">
    <div class="overlay__config-header">
      <div>
        {{ "CurrentTimeDialog_label_color" | translate }}
      </div>
      <div
        [style.background-color]="selectedMark.rgbValue"
        class="overlay__config-color"
      ></div>
    </div>
    <div class="flex flex-direction-column">
      <div class="color">
        <button
          class="color-picker"
          matButton
          [(colorPicker)]="selectedMark.rgbValue"
          [style.background]="selectedMark.rgbValue"
          [cpPresetColors]="presetValues"
          [cpToggle]="true"
          [cpColorMode]="'presets'"
          [cpDialogDisplay]="'inline'"
          [cpPresetLabel]="''"
          (colorPickerChange)="OnColorChange()"
        ></button>
      </div>

      <div class="more-color-picker">
        <button
          class="flex color-picker-button"
          matButton
          [(colorPicker)]="selectedMark.rgbValue"
          (colorPickerChange)="OnColorChange()"
          [cpPosition]="'top-right'"
        >
          {{ "line-configuration.more" | translate }}
        </button>
      </div>
    </div>
    <!-- <mat-accordion class="color-config">
      <mat-expansion-panel class="expansion-panel" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="overlay__config-header">
              <div>
                {{ "line-configuration.lineConfiguration" | translate }}
              </div>
              <div
                [style.background-color]="selectedMark.rgbValue"
                class="overlay__config-color"
              ></div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <div class="flex flex-direction-column">
            <div class="color">
              <button
                class="color-picker"
                matButton
                [(colorPicker)]="selectedMark.rgbValue"
                [style.background]="selectedMark.rgbValue"
                [cpPresetColors]="presetValues"
                [cpToggle]="true"
                [cpColorMode]="'presets'"
                [cpDialogDisplay]="'inline'"
                [cpPresetLabel]="''"
                (colorPickerChange)="OnColorChange()"
              ></button>
            </div>

            <div class="more-color-picker">
              <button
                class="flex color-picker-button"
                matButton
                [(colorPicker)]="selectedMark.rgbValue"
                (colorPickerChange)="OnColorChange()"
                [cpPosition]="'bottom-right'"
              >
                {{ "line-configuration.more" | translate }}
              </button>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion> -->
  </div>
</div>
