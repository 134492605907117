import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-move-up-down",
  templateUrl: "./move-up-down.component.html",
})
export class MoveUpDownComponent {
  @Input() type: string;
  constructor() {}
}
