import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from "rxjs";
import { environment } from "../../environments/environment";
import { Constants } from "../models/constants/Constants";
import DialogModel from "../models/common/DialogModel";
import { EventType } from "../models/constants/eventType";
import { serviceFactory } from "../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../services/serviceLayer/session-expired-dialog.service";
import { SessionTimerService } from "../services/session-timer.service";
import { ToasterService } from "../services/toaster.service";
import { ConfirmationDialogComponent } from "../shared/confirmation-dialog/confirmation-dialog.component";
@Component({
  selector: "app-session-timer",
  templateUrl: "./session-timer.component.html",
  styleUrls: ["./session-timer.component.css"],
})
export class SessionTimerComponent implements OnInit, OnDestroy {
  timerSubscription: Subscription;
  counter: number;
  tick = 1000;
  dialogModel = new DialogModel();
  bufferTime: number = 60;
  startTime = new Date();
  intervalRef = null;
  isSessionTimedOut: boolean = false;
  subscriptions: Subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    public sessionTimer: SessionTimerService,
    private cookieService: CookieService,
    private router: Router,
    public eventBus: NgEventBus,
    private sessionExpiredDialog: SessionExpiredService,
    private toasterService: ToasterService
  ) {
    this.counter = environment.sessionTime;
    const isUserIdleEvent = this.eventBus
      .on(EventType.ISUSERIDLE)
      .subscribe((meta: MetaData) => {
        if (meta.data && this.counter > 0) {
          this.startTimer();
        } else if (!meta.data) {
          this.startTime = new Date();
        }
      });
    this.subscriptions.add(isUserIdleEvent);
  }
  async ngOnInit() {
    this.timerSubscription =
      await serviceFactory.SessionTimerService.resetNotifier.subscribe(
        async (res) => {
          if (res === "reset") {
            // this.counter = environment.sessionTime;
            this.startTime = new Date();
          }
        }
      );
  }
  ngOnDestroy() {
    // this.cleanUp();
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.subscriptions.unsubscribe();
  }
  startTimer() {
    clearInterval(this.intervalRef);
    this.intervalRef = setInterval((_) => {
      let current = new Date();
      let count = +current - +this.startTime;
      // if (environment.sessionTime - Math.floor((count / 1000)) >= 0)
      this.counter = environment.sessionTime - Math.floor(count / 1000);
      if (this.counter === 0 || this.counter < 0) {
        this.counter = 0;
        clearInterval(this.intervalRef);
        this.openDialog();
        this.eventBus.cast(EventType.SESSIONTIMEDOUT, true);
      } else if (
        this.counter === this.bufferTime &&
        this.router.url.includes(Constants.LISTENTRY) &&
        this.router.url.includes("/project")
      ) {
        this.eventBus.cast(EventType.AUTOSAVEONSESSIONEXPIRATION, false);
      }
    }, 1000);
  }
  cleanUp() {
    this.intervalRef.unsubscribe();
  }
  openDialog() {
    if (!this.isSessionTimedOut) {
      this.isSessionTimedOut = true;
      this.dialogModel.content = "session-timeout-content";
      this.dialogModel.header = "session-timeout-header";
      this.dialogModel.actions = [
        {
          action: "OK",
          type: "primary",
        },
      ];
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "37.5rem",
        height: "13rem",
        data: this.dialogModel,
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        await serviceFactory.ProjectService.logout()
          .then((response) => {
            this.cookieService.delete("JSESSIONID", "/");
            window.location.href = "/";
          })
          .catch((error: AxiosError) => {
            if (error.response.data === Constants.SESSIONEXPIRED) {
              this.sessionExpiredDialog.openDialog();
            } else {
              this.toasterService.showError(error.message);
            }
          });
      });
    }
  }
}
