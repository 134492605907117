import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import DialogModel from "../../models/common/DialogModel";
@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.css"],
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModel
  ) {
    this.dialogRef.disableClose = true;
  }

  cancelEvent() {
    this.dialogRef.close(false);
  }
  discardEvent() {
    this.dialogRef.close(false);
  }
  delete() {
    this.dialogRef.close(true);
  }
  proceed() {
    this.dialogRef.close(true);
  }
  close() {
    this.dialogRef.close();
  }
  onActionClick(actionType: string) {
    if (actionType === "primary") {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(false);
    }
  }
}
