import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Injector,
  ElementRef,
  Renderer2,
  OnChanges,
  OnDestroy,
} from "@angular/core";
import BaseModel from "../models/devices/BaseModel";
import ProductModel from "../models/devices/ProductModel";
import {
  Overlay,
  OverlayRef,
  OverlayModule,
  ConnectionPositionPair,
} from "@angular/cdk/overlay";
import {
  ColorPickerComponent,
  LINESTYLE,
  LINEWIDTH,
  ORDERNUMBER_POSITION,
  PRODUCT_TYPE,
} from "../color-picker/color-picker.component";
import { ComponentPortal } from "@angular/cdk/portal";
import { NgEventBus } from "ng-event-bus";
import {
  ORDERNUMBER_ID,
  LINECOLOR,
  ORDERNUMBER,
  PROJECTINFO,
} from "../color-picker/color-picker.component";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { EventType } from "../models/constants/eventType";
import { serviceFactory } from "../services/serviceLayer/servicefactory/serviceFactory";
import { AxiosError } from "axios";
import { ToasterService } from "../services/toaster.service";
import ListEntry from "../models/devices/ListEntry";
import { Subscription } from "rxjs";
import { SessionExpiredService } from "../services/serviceLayer/session-expired-dialog.service";
import { Constants } from "../models/constants/Constants";
import ProjectInfo from "../models/drive/projectInfo";
const positions = [
  new ConnectionPositionPair(
    { originX: "end", originY: "bottom" },
    { overlayX: "end", overlayY: "top" }
  ),
  new ConnectionPositionPair(
    { originX: "end", originY: "top" },
    { overlayX: "end", overlayY: "bottom" }
  ),
];

@Component({
  selector: "app-device-card",
  templateUrl: "./device-card.component.html",
  styleUrls: ["./device-card.component.css"],
})
export class DeviceCardComponent implements OnChanges, OnDestroy {
  @Input() isExpanded: boolean;
  @Input() selectedProduct: BaseModel;
  @Input() productData: ProductModel;
  @Input() projectInfo: ProjectInfo;
  @Output() selectedItemOnClick = new EventEmitter();
  @Output() emitSettings = new EventEmitter();
  overlayRef: OverlayRef;
  @ViewChild("colorpicker") colorpicker: any;
  @ViewChild("productName") productName: ElementRef;
  injector: Injector;
  color: string = "";
  productNameLabel: string;
  subscriptions: Subscription = new Subscription();
  constructor(
    public overlay: Overlay,
    public eventBus: NgEventBus,
    private toasterService: ToasterService,
    private renderer: Renderer2,
    private sessionExpiredDialog: SessionExpiredService
  ) {
    const closeLineConfig = this.eventBus
      .on(EventType.CLOSELINECONFIG)
      .subscribe(() => {
        if (this.overlayRef) {
          this.overlayRef.detach();
          this.selectedProduct.isLineConfigurationOpen = false;
        }
      });
    const onLineConfigChange = this.eventBus
      .on(EventType.ONLINECONFIGCHANGE)
      .subscribe((metaData: MetaData) => {
        if (
          metaData.data.productIndex === this.selectedProduct.index &&
          metaData.data.lineColor
        ) {
          this.color = metaData.data.lineColor;
        }
      });
    this.renderer.listen("window", "click", (e: Event) => {
      if (!this.productName.nativeElement.contains(e.target)) {
        this.selectedProduct.isEditProductLabel = false;
      }
    });
    this.subscriptions.add(closeLineConfig);
    this.subscriptions.add(onLineConfigChange);
    // this.subscriptions.add(onUpdateProductDetails);
  }
  // ngOnInit() {
  // }
  ngOnChanges() {
    this.productNameLabel = this.selectedProduct.name;
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  async editDeviceLabel() {
    this.selectedProduct.isEditProductLabel = false;
    if (
      this.productNameLabel &&
      this.productNameLabel !== this.selectedProduct.name
    ) {
      //TODO::Folder Id Removal Migration
      await serviceFactory.ProductService.updateDeviceLabel(
        this.productData.facadeListEntry.listEntry,
        this.productNameLabel,
        this.productData.facadeListEntry.listEntry.index,
        this.projectInfo.id
      )
        .then((response: ListEntry) => {
          if (response.mLabel) {
            this.productData.product.name = response.mLabel;
            this.productData.facadeListEntry.listEntry.mLabel = response.mLabel;
            this.eventBus.cast(EventType.ONDEVICELABELEDIT, this.productData);
            if (this.selectedProduct.type == Constants.SHORTCIRCUITLINE) {
              this.selectedProduct.isShortCircuitLineLabelEdited = true;
            }
          }
        })
        .catch((err: AxiosError) => {
          this.productNameLabel = this.productData.product.name;
          if (err.response.data === Constants.SESSIONEXPIRED) {
            this.sessionExpiredDialog.openDialog();
          } else {
            this.toasterService.showError(err);
          }
        });
    }
  }
  onActionsClick(event) {
    event.stopPropagation();
  }
  emitSelectedItem(item: BaseModel) {
    item.isChecked = true;
    // item.isCardOpen = !item.isCardOpen;
    let newItem = new BaseModel();
    newItem.id = item.id;
    newItem.index = item.index;
    newItem.name = item.name;
    newItem.toggleSlashEye = item.toggleSlashEye;
    newItem.isChecked = item.isChecked;
    newItem.isCardOpen = item.isCardOpen;
    this.selectedItemOnClick.emit(newItem);
    setTimeout(() => {
      this.eventBus.cast(EventType.AFTERSIDEBARNAVIGATION, true);
    }, 300);
  }
  emitSettingsClick(event) {
    this.emitSettings.emit(event);
  }
  openConfiguration(event) {
    event.stopPropagation();
    this.selectedProduct.isLineConfigurationOpen = true;
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.productName)
        .withPositions(positions)
        .withPush(true),
      hasBackdrop: true,
    });
    const componentPortal = new ComponentPortal(
      ColorPickerComponent,
      null,
      this.createPortalInjector(
        this.selectedProduct.index,
        this.productData.protectiveDevice.color,
        this.productData.protectiveDevice.lineWidth,
        this.productData.protectiveDevice.lineStyle,
        this.productData.facadeListEntry.listEntry.index,
        this.projectInfo,
        this.selectedProduct.type
      )
    );
    this.overlayRef.attach(componentPortal);
    this.overlayRef.backdropClick().subscribe(() => {
      // this.overlayRef.detach()
    });
  }
  createPortalInjector(
    selectedProductIndex: number,
    color: string,
    lineWidth: number,
    lineStyle: string,
    orderNumberPosition: number,
    projectInfo: ProjectInfo,
    productType: string
  ) {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: ORDERNUMBER_ID, useValue: selectedProductIndex },
        { provide: LINECOLOR, useValue: color },
        { provide: ORDERNUMBER, useValue: this.selectedProduct.name },
        { provide: LINEWIDTH, useValue: lineWidth },
        { provide: LINESTYLE, useValue: lineStyle },
        { provide: ORDERNUMBER_POSITION, useValue: orderNumberPosition },
        { provide: PROJECTINFO, useValue: projectInfo },
        { provide: PRODUCT_TYPE, useValue: productType },
      ],
    });
  }

  setEditingMode() {
    this.productNameLabel = this.selectedProduct.name;
    this.selectedProduct.isEditProductLabel =
      !this.selectedProduct.isEditProductLabel;
  }
}
