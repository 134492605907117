import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { HttpLoaderFactory } from "../app.module";
import { CustomLoader } from "../language/custom.loader";
import { LanguageMissingTranslationHandler } from "../language/language.missing-translation-handler";
import { ProductAttributesComponent } from "./product-attributes/product-attributes.component";
import { ProductCatalogTreeComponent } from "./product-catalog-tree/product-catalog-tree.component";
import { ProductImageComponent } from "./product-image/product-image.component";
import { DeviceCatalogDialogComponent } from "./device-catalog-dialog/device-catalog-dialog.component";
import { CommonHelperModule } from "../common/common-helper.module";
import { MaterialModule } from "../material.module";
import { IconsModule } from "../icons/icons.module";
@NgModule({
  declarations: [
    ProductAttributesComponent,
    ProductCatalogTreeComponent,
    ProductImageComponent,
    DeviceCatalogDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        useFactory: HttpLoaderFactory,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler,
      },
    }),
    MaterialModule,
    CommonHelperModule,
    IconsModule,
  ],
  exports: [
    ProductAttributesComponent,
    ProductCatalogTreeComponent,
    ProductImageComponent,
    DeviceCatalogDialogComponent,
  ],
  providers: [DeviceCatalogDialogComponent],
  bootstrap: [],
  entryComponents: [DeviceCatalogDialogComponent],
})
export class DeviceCatalogModule {}
