import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import BaseModel from "../models/devices/BaseModel";
@Injectable()
export class ConfirmationEventService {
  confirmedDeleteEvent = new BehaviorSubject<BaseModel>(new BaseModel());
  constructor() {}

  sendDeleteEvent(event: BaseModel) {
    this.confirmedDeleteEvent.next(event);
  }

  getDeleteEvent(): Observable<BaseModel> {
    return this.confirmedDeleteEvent.asObservable();
  }
}
