import { OverlayModule } from "@angular/cdk/overlay";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ChartModule } from "angular-highcharts";
import { NgEventBus } from "ng-event-bus";
import { ColorPickerModule } from "ngx-color-picker";
import { NgxFileDragDropModule } from "ngx-file-drag-drop";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
//import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { ColorPickerComponent } from "./color-picker/color-picker.component";
import { CurveTabComponent } from "./curve-tab/curve-tab.component";
import { DeviceCardComponent } from "./device-card/device-card.component";
import { DeviceViewSidebarComponent } from "./device-view/device-components/shared/device-list-view/device-view-sidebar/device-view-sidebar.component";
import { DeviceMainViewComponent } from "./device-view/device-components/shared/device-main-view/device-main-view/device-main-view.component";
import { DeviceSettingsPropertiesViewComponent } from "./device-view/device-components/shared/device-settings-properties/device-settings-properties-view/device-settings-properties-view.component";
import { RoutingGuard } from "./guards/routing.guard";
import { HighChartComponent } from "./high-chart/high-chart.component";
import { InfoBannerComponent } from "./info-banner/info-banner.component";
import { MaterialModule } from "./material.module";
import { ConfirmationEventService } from "./services/ConfirmationEventServices";
import ProductCatalogService from "./services/serviceLayer/ProductCatalogService";
import ProductService from "./services/serviceLayer/ProductService";
import { fromRoot } from "./store";
// import { I18N_PROVIDERS } from './translation-config';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { JwtModule } from "@auth0/angular-jwt";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AddMarkComponentComponent } from "./add-mark-component/add-mark-component.component";
import { ColorPaletteComponent } from "./color-palette/color-palette.component";
import { AuthGuard } from "./guards/auth.guard";
import { CustomLoader } from "./language/custom.loader";
import { LanguageMissingTranslationHandler } from "./language/language.missing-translation-handler";
import { MarkCardComponent } from "./mark-card/mark-card.component";
import { SearchDeviceOverlayComponent } from "./search-device-overlay/search-device-overlay.component";
import { SessionTimerService } from "./services/session-timer.service";
import { TooltipComponent } from "./tooltip/tooltip.component";
import { DeviceCatalogModule } from "./device-catalog-component/device-catalog.module";
import { DeviceSettingsModule } from "./device-settings-component/device-settings.module";
import { SharedModule } from "./shared/shared.module";
import { environment } from "./../environments/environment";
import { AuthModule, AuthService, AuthState } from "@auth0/auth0-angular";
import { Observable, of, throwError } from "rxjs";
import { filter, mergeMap, first, mapTo, catchError } from "rxjs/operators";
import axios from "axios";
import { LoginModule } from "./login-page/login.module";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return "";
}

// use axios interceptor to forward access tokens from auth0 in backend requests
function initializeAxios(
  authService: AuthService,
  authState: AuthState
): () => Observable<any> {
  // ugly code adapted from auth0-angular AuthHttpInterceptor to forward bearer token
  const waitUntil = (signal$) => (source$) =>
    source$.pipe(mergeMap((value) => signal$.pipe(first(), mapTo(value))));
  return () => {
    axios.interceptors.request.use(
      (config) => {
        const isLoaded$ = authService.isLoading$.pipe(
          filter((isLoading) => !isLoading)
        );
        return of(config)
          .pipe(waitUntil(isLoaded$))
          .toPromise()
          .then((_) =>
            authService
              .getAccessTokenSilently()
              .pipe(
                catchError((err) => {
                  authState.setError(err);
                  return throwError(err);
                })
              )
              .toPromise()
          )
          .then((token) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            return config;
          });
      },
      (error) => {
        console.log("error in axios response");
        return Promise.reject(error);
      }
    );
    return null;
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HighChartComponent,
    DeviceCardComponent,
    CurveTabComponent,
    ColorPickerComponent,
    DeviceMainViewComponent,
    DeviceViewSidebarComponent,
    DeviceSettingsPropertiesViewComponent,
    InfoBannerComponent,
    AddMarkComponentComponent,
    MarkCardComponent,
    ColorPaletteComponent,
    TooltipComponent,
    SearchDeviceOverlayComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler,
      },
    }),
    SharedModule,
    MaterialModule,
    ChartModule,
    OverlayModule,
    ColorPickerModule,
    NgxFileDragDropModule,
    HttpClientModule,
    InfiniteScrollModule,
    DeviceCatalogModule,
    DeviceSettingsModule,
    LoginModule,
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      closeButton: true,
    }),
    EffectsModule.forRoot([fromRoot.ProductEffects]),
    StoreModule.forRoot({
      productState: fromRoot.productReducer,
      userState: fromRoot.userReducer,
      countryState: fromRoot.countryReducer,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [],
        disallowedRoutes: [],
      },
    }),
    AuthModule.forRoot({
      ...environment.auth,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAxios,
      deps: [AuthService, AuthState],
      multi: true,
    },
    ConfirmationEventService,
    // I18N_PROVIDERS,
    NgEventBus,
    ProductService,
    ProductCatalogService,
    RoutingGuard,
    AuthGuard,
    SessionTimerService,
    {
      provide: Window,
      useValue: window,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {}
