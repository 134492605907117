export enum MarkConstants {
  MARK_XLABEL = "I[A]",
  MARK_YLABEL = "t[s]",
  MARK_YLABEL_IC = "Ic[A]",
  MIN_IA = 0.1,
  MAX_IA = 3000000,
  MIN_Ts = 0.001,
  MAX_Ts = 10000,
  MIN_IC = 0.1,
  MAX_IC = 150000,
  MIN_I2t = 0.01,
  MAX_I2t = 1000000000000,
  FACADE_TRIP = "tripping-characteristic",
  FACADE_CURRENT = "let-through-current",
  FACADE_ENERGY = "let-through-energy",
}
