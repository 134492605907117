import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-folder-icon",
  templateUrl: "./folder-icon.component.html",
})
export class FolderIconComponent {
  @Input() type: string;
  constructor() {}
}
