import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import { EventType } from "../../models/constants/eventType";

@Component({
  selector: "app-project-header",
  templateUrl: "./project-header.component.html",
  styleUrls: ["./project-header.component.css"],
})
export class ProjectHeaderComponent {
  @Input() isShowSubHeader: boolean;
  @Input() searchText: string;
  @Output() emitCreateProjectEvent = new EventEmitter();
  @Output() emitUploadProjectEvent = new EventEmitter();
  @Output() emitSearchTextEvent = new EventEmitter();

  constructor(public eventBus: NgEventBus) {}
  OnCreateProject() {
    this.eventBus.cast(EventType.CREATENEWPROJECT, true);
  }
  onUploadProject() {
    this.emitUploadProjectEvent.emit("true");
  }
  onSearchProject(event) {
    this.emitSearchTextEvent.emit(this.searchText);
  }
  clearSearchText() {
    this.searchText = "";
    this.emitSearchTextEvent.emit(this.searchText);
  }
}
