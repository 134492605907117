import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from "@ngx-translate/core";

export class LanguageMissingTranslationHandler
  implements MissingTranslationHandler
{
  constructor() {}

  handle(params: MissingTranslationHandlerParams) {
    console.debug(params.key);
    return params.key;
  }
}
