import { Component, Input } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { map, Observable, startWith, take } from "rxjs";
import { CountryState, UserState } from "../../store/cache.reducer";
import SpinnerModel from "../../models/common/SpinnerModel";
import { Constants } from "../../models/constants/Constants";
import { EventType } from "../../models/constants/eventType";
import ProjectInfo from "../../models/drive/projectInfo";
import CountryModel from "../../models/projects/CountryModel";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../services/toaster.service";
@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.css"],
})
export class ProjectDetailsComponent {
  @Input() header: string = "";
  @Input() action: string = "";
  projectInfo: ProjectInfo = new ProjectInfo();
  dialogHeight = "37.5rem";
  dialogWidth = "13rem";
  isNameFieldActive: boolean = false;
  isDescriptionFieldActive: boolean = false;
  isClientFieldActive: boolean = false;
  isLocationFieldActive: boolean = false;
  isDesignOfficeFieldActive: boolean = false;
  isCommentFieldActive: boolean = false;
  isCountryFieldActive: boolean = false;
  isFolderNameFieldActive: boolean = false;
  isValidCountryValue: boolean = false;

  countriesList: CountryModel[];
  countryFormControl = new FormControl();
  filteredOptions: Observable<any>;
  spinnerModel = new SpinnerModel();

  constructor(
    public eventBus: NgEventBus,
    private activateRoute: ActivatedRoute,
    private toasterService: ToasterService,
    public dialog: MatDialog,
    private sessionExpiredDialog: SessionExpiredService,
    private translator: TranslateService,
    private store: Store<{ countryState: CountryState[] }>
  ) {
    //To fetch Country list from store
    this.store
      .select((state) => state.countryState)
      .subscribe((countryState: CountryState[]) => {
        this.countriesList = countryState[0].value;
      });
    this.filteredOptions = this.countryFormControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterCountry(value))
    );
    this.activateRoute.params.subscribe(async (childParam) => {
      if (childParam.projectId) {
        this.projectInfo.id = childParam.projectId;
        if (this.header === "" && this.action === "") {
          this.header = "project-details.editProject";
          this.action = "Action_edititemlabel";
        }
        this.getProjectDetails();
      } else {
        if (this.header === "" && this.action === "") {
          this.header = "default-project.createProject";
          this.action = "add_device";
        }
        this.projectInfo = new ProjectInfo();
        this.setDefaultProjectCountry();
      }
    });
  }
  setDefaultProjectCountry() {
    if (window.navigator.languages.length > 0) {
      let browserLocaleWithRegion = window.navigator.languages.find(
        (lang) => new Intl.Locale(lang).region !== undefined
      );
      let browserRegion = new Intl.Locale(browserLocaleWithRegion).region;
      if (
        browserRegion &&
        this.filterCountryWithCode(browserRegion).length > 0
      ) {
        this.projectInfo.country.value = browserRegion;
        this.isValidCountryValue = true;
        this.translator
          .get(Constants.COUNTRY_PREFIX + this.projectInfo.country.value)
          .pipe(take(1))
          .subscribe((value) => this.countryFormControl.setValue(value));
      }
    }
  }
  filterCountry(value: String) {
    const filterValue = value.toLowerCase();
    return this.countriesList.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  filterCountryWithCode(value: String) {
    const filterValue = value.toLowerCase();
    return this.countriesList.filter((option) =>
      option.code.toLowerCase().includes(filterValue)
    );
  }
  saveSelectedCountry(country: CountryModel) {
    //this.projectInfo.country.value = country.code;
    this.inputChange(country.name);
  }
  clearProjInfo() {
    this.projectInfo.name = "";
    this.projectInfo.projectDescription.value = "";
    this.projectInfo.client.value = "";
    this.projectInfo.location.value = "";
    this.projectInfo.designOffice.value = "";
    this.projectInfo.comment.value = "";
  }
  async getProjectDetails() {
    await serviceFactory.ProjectService.getProjectByID(this.projectInfo.id)
      .then((project: ProjectInfo) => {
        this.translator
          .get(Constants.COUNTRY_PREFIX + project.country.value)
          .pipe(take(1))
          .subscribe((value) => this.countryFormControl.setValue(value));
        this.projectInfo = project;
        project.country.value
          ? (this.isValidCountryValue = true)
          : (this.isValidCountryValue = false);
      })
      .catch((error: AxiosError) => {
        if (error.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(error.message);
        }
      });
  }
  async onActionClick() {
    if (this.validateMaxChar()) {
      if (this.action === "add_device") {
        this.spinnerModel.show = true;
        this.spinnerModel.text = "loader.createProject";
        this.projectInfo.language.value = localStorage.getItem("i18nextLng");
        this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
        await serviceFactory.ProjectService.addProject(this.projectInfo)
          .then((project: ProjectInfo) => {
            this.projectInfo = project;
            this.eventBus.cast(EventType.ADDPROJECTSUCCESS, true);
            this.toasterService.showSuccess(
              "toaster-message.projectAddSuccess"
            );
          })
          .catch((error: AxiosError) => {
            if (error.response.data === Constants.SESSIONEXPIRED) {
              this.sessionExpiredDialog.openDialog();
            } else {
              this.toasterService.showError(error.message);
            }
          })
          .finally(() => {
            this.spinnerModel.show = false;
            this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
            this.clearProjInfo();
          });
      } else if (this.action === "Action_edititemlabel") {
        this.spinnerModel.show = true;
        this.spinnerModel.text = "loader.editProject";
        this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
        await serviceFactory.ProjectService.updateProject(this.projectInfo)
          .then((project: ProjectInfo) => {
            this.projectInfo = project;
            this.eventBus.cast(EventType.EDITPROJECTSUCCESS, true);
            this.toasterService.showSuccess(
              "toaster-message.projectUpdateSuccess"
            );
          })
          .catch((error: AxiosError) => {
            if (error.response.data === Constants.SESSIONEXPIRED) {
              this.sessionExpiredDialog.openDialog();
            } else {
              this.toasterService.showError(error.message);
            }
          })
          .finally(() => {
            this.spinnerModel.show = false;
            this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, this.spinnerModel);
          });
      } else {
        if (this.projectInfo.name.trim().length >= 100) {
          this.toasterService.showError(
            this.toasterService.toasterMessage(
              "toaster-message.maxCharsErrorProjectName"
            )
          );
        } else {
          this.toasterService.showError(
            this.toasterService.toasterMessage("toaster-message.maxCharsError")
          );
        }
      }
    }
  }
  isEditMode() {
    return this.action === "Action_edititemlabel";
  }
  validateMaxChar() {
    return (
      this.projectInfo.name.trim().length <= 100 &&
      this.projectInfo.projectDescription.value.trim().length <= 500 &&
      this.projectInfo.client.value.trim().length <= 500 &&
      this.projectInfo.location.value.trim().length <= 500 &&
      this.projectInfo.designOffice.value.trim().length <= 500 &&
      this.projectInfo.comment.value.trim().length <= 500
    );
  }
  validateProjectInfo() {
    return !(
      this.projectInfo.name.trim().length !== 0 && this.isValidCountryValue
    );
  }
  onCancelProject() {
    this.eventBus.cast(EventType.CANCELPROJECTCREATION, true);
  }
  onFocus(fieldName: string) {
    this[fieldName] = true;
  }

  onBlur(fieldName: string) {
    this[fieldName] = false;
  }

  inputChange(value) {
    let selection: CountryModel;

    if (this.countriesList) {
      this.countriesList.forEach((i) => {
        if (i.name == value) {
          selection = i;
        }
      });
    }
    if (
      this.countriesList &&
      this.countriesList.indexOf(selection) < 0 &&
      value != ""
    ) {
      this.isValidCountryValue = false;
    } else if (value == "") {
      this.isValidCountryValue = false;
      this.projectInfo.country.value = "";
    } else {
      this.isValidCountryValue = true;
      this.projectInfo.country.value = selection.code;
    }
  }
}
