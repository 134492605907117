<mat-tab-group
  class="tab"
  [selectedIndex]="selectedTab"
  (selectedTabChange)="changeTab($event)"
  animationDuration="0ms"
  disableRipple
>
  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ "CharacteristicsView_name" | translate }}</span> </ng-template
    ><app-high-chart
      [chartType]="'TRIP'"
      [xLabel]="'I[A]'"
      [yLabel]="'t[s]'"
      [xMin]="10"
      [xMax]="100000"
      [yMax]="10000"
      [yMin]="0.001"
      [projectInfo]="projectInfo"
    ></app-high-chart>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ "LetThroughCurrentView_name" | translate }}</span>
    </ng-template>
    <app-high-chart
      [chartType]="'CURRENT'"
      [xLabel]="'I[A]'"
      [yLabel]="'Ic[A]'"
      [projectInfo]="projectInfo"
      [xMin]="10"
      [xMax]="10000000"
      [yMax]="10000"
      [yMin]="0.001"
    ></app-high-chart>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ "LetThroughEngeryView_name" | translate }}</span>
    </ng-template>
    <app-high-chart
      [chartType]="'LETTHROUGH'"
      [xLabel]="'I[A]'"
      [yLabel]="
        'I' + this.superScript('2') + 't[A' + this.superScript('2') + 's]'
      "
      [projectInfo]="projectInfo"
      [xMin]="10"
      [xMax]="10000000"
      [yMax]="10000"
      [yMin]="0.001"
    ></app-high-chart>
  </mat-tab>
</mat-tab-group>
