import { Component, Inject, InjectionToken } from "@angular/core";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { Constants } from "../models/constants/Constants";
import { EventType } from "../models/constants/eventType";
import ProjectInfo from "../models/drive/projectInfo";
import MarkModel from "../models/marks/MarkModel";
import { serviceFactory } from "../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../services/toaster.service";

export const SELECTEDMARK = new InjectionToken<{}>("SELECTEDMARK");
export const PROJECTINFO = new InjectionToken<{}>("PROJECTINFO");
@Component({
  selector: "app-color-palette",
  templateUrl: "./color-palette.component.html",
  styleUrls: ["./color-palette.component.css"],
})
export class ColorPaletteComponent {
  presetValues: string[] = [];
  prevMarkColor: string;
  constructor(
    public eventBus: NgEventBus,
    public sessionExpiredDialog: SessionExpiredService,
    @Inject(SELECTEDMARK) public selectedMark: MarkModel,
    @Inject(PROJECTINFO) public projectInfo: ProjectInfo,
    private toasterService: ToasterService
  ) {
    serviceFactory.LineConfigurationService.getPresetColor().then((data) => {
      this.presetValues = data.map((c) => c.value);
    });
    this.prevMarkColor = this.selectedMark.rgbValue;
  }
  async closeConfig() {
    let updatedMarkIndex: number = this.selectedMark.index;
    this.eventBus.cast(EventType.CLOSELINECONFIG, true);
    await serviceFactory.MarkService.updatemark(
      this.selectedMark,
      this.selectedMark.index,
      this.projectInfo.id
    )
      .then((markList: MarkModel[]) => {
        this.eventBus.cast(EventType.UPDATED_MARK_LIST, markList);
        this.eventBus.cast(EventType.HIGHLIGHT_SELECTED_MARK, updatedMarkIndex);
      })
      .catch((err: AxiosError) => {
        if (err.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(err);
          this.selectedMark.rgbValue = this.prevMarkColor;
          this.eventBus.cast(EventType.ON_MARK_COLORCHANGE, this.selectedMark);
        }
      });
  }
  OnColorChange() {
    this.eventBus.cast(EventType.ON_MARK_COLORCHANGE, this.selectedMark);
  }
}
