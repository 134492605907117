<div class="h-100 py-3 folder-header">
  <div class="folder-name d-flex align-items-center gap-2">
    <app-icons [iconName]="'home'"></app-icons
    ><app-components-header
      [headerName]="'project-definition.myprojects'"
      [fontSize]="'1.25rem'"
    ></app-components-header>
  </div>

  <div class="sub-header gap-3" *ngIf="isShowSubHeader">
    <mat-form-field class="project__search" appearance="outline">
      <input
        class="padding-1"
        type="text"
        placeholder="{{ 'CatalogSearchControl_SearchLink' | translate }}"
        matInput
        [(ngModel)]="searchText"
        (keyup)="onSearchProject($event)"
      />
      <mat-icon matSuffix *ngIf="!searchText">search</mat-icon>
      <app-icons
        class="pointer"
        *ngIf="searchText"
        (click)="clearSearchText()"
        [iconName]="'close'"
        [height]="16"
        [width]="16"
      ></app-icons>
    </mat-form-field>

    <div class="d-flex gap-3">
      <button class="btn--secondary" (click)="onUploadProject()">
        <app-icons [iconName]="'upload'"></app-icons>
        {{ "project-definition.uploadProject" | translate }}
      </button>

      <button class="btn--primary" (click)="OnCreateProject()">
        <app-icons [iconName]="'plus'"></app-icons>
        {{ "project-definition.createProject" | translate }}
      </button>
    </div>
  </div>
</div>
