class RouterNames {
  getListEntryRoute(projectId: string): any {
    return `projects/${projectId}/listentry`;
  }
  getProjectRoute() {
    return `projects`;
  }
  getAddProjectURL() {
    return `projects/add`;
  }

  getEditProjectURL(projectId: string) {
    return `projects/edit/${projectId}`;
  }
}
export default new RouterNames();
