import MarkModel from "../models/marks/MarkModel";
import ProductModel from "../models/devices/ProductModel";
import { serviceFactory } from "../services/serviceLayer/servicefactory/serviceFactory";
import LineConfigurationModel from "../models/devices/line-config/LineConfigurationModel";

export default class SCLHelper {
  getPlotLineIndex(index: number, chartData) {
    return chartData.ref.xAxis[0].plotLinesAndBands.findIndex(
      (x) => x.options.id === index
    );
  }
  changeSCLWidth(
    chartData,
    selectedProduct: ProductModel,
    selectedItemList: ProductModel[]
  ) {
    if (chartData.ref) {
      var shortCircuitLineLength =
        chartData.ref.xAxis[0].plotLinesAndBands.length;
      for (var i = 0; i < shortCircuitLineLength; i++) {
        if (
          chartData.ref.xAxis[0].plotLinesAndBands[i].options.id ==
          selectedProduct.product.index
        ) {
          chartData.ref.xAxis[0].plotLinesAndBands[i].options.width =
            selectedProduct.protectiveDevice.lineWidth + 2;
          chartData.ref.xAxis[0].update();
        } else if (
          chartData.ref.xAxis[0].plotLinesAndBands[i].options.id !=
          selectedProduct.product.index
        ) {
          let product = selectedItemList.find(
            (x) =>
              x.product.index ===
              chartData.ref.xAxis[0].plotLinesAndBands[i].options.id
          );
          if (product) {
            chartData.ref.xAxis[0].plotLinesAndBands[i].options.width =
              product.protectiveDevice.lineWidth;
          }

          chartData.ref.xAxis[0].update();
        }
      }
      return chartData;
    }
  }
  onAddSCL(
    chartData: any,
    product: ProductModel,
    selectedItemList: ProductModel[],
    selectedViewMarksList: MarkModel[]
  ) {
    let showDefaultText;
    if (this.getPlotLineIndex(product.product.index, chartData) === -1) {
      showDefaultText = this.onAddShortCircuitLine(
        chartData,
        product,
        selectedItemList,
        selectedViewMarksList
      );
      if (!product.facadeListEntry.listEntry.visible) {
        chartData.ref.xAxis[0].removePlotLine(product.product.index);
        serviceFactory.HighChartHelper.removeSeries(
          product.product.index,
          chartData
        );
      }
    } else {
      showDefaultText = false;
      this.changeSCLWidth(chartData, product, selectedItemList);
      serviceFactory.HighChartHelper.changeStrokeWidth(
        product.product.index,
        chartData,
        selectedItemList,
        selectedViewMarksList,
        product,
        false
      );
    }
    return showDefaultText;
  }
  onAddShortCircuitLine(
    chartData: any,
    product: ProductModel,
    selectedItemList: ProductModel[],
    selectedViewMarksList: MarkModel[]
  ) {
    let isShowDefaultText = false;
    let value =
      product.facadeListEntry.listEntry.mProtectiveDevice["device"].current;
    let label = product.product.name;
    let index = product.product.index;
    let color = product.protectiveDevice.color;
    let width = product.protectiveDevice.lineWidth + 2;
    let lineStyle = product.protectiveDevice.lineStyle;
    chartData.addSeries({
      id: index,
      data: [[value, value]],
      showInLegend: false,
      visible: false,
    });
    chartData.ref.xAxis[0].addPlotLine({
      value: value,
      color: color,
      width: width,
      id: index,
      dashStyle: lineStyle,
      label: {
        text: label,
        rotation: 270,
        verticalAlign: "bottom",
        textAlign: "left",
        x: -5,
        y: -3,
        style: {
          color: color,
        },
      },
    });
    this.changeSCLWidth(chartData, product, selectedItemList);
    serviceFactory.HighChartHelper.changeStrokeWidth(
      product.product.index,
      chartData,
      selectedItemList,
      selectedViewMarksList,
      product,
      false
    );
    return isShowDefaultText;
  }
  updateSCLLabel(chartData: any, product: ProductModel) {
    chartData.ref.xAxis[0].plotLinesAndBands[
      this.getPlotLineIndex(product.product.index, chartData)
    ].label.attr({
      text: product.product.name,
    });
    chartData.ref.xAxis[0].plotLinesAndBands[
      this.getPlotLineIndex(product.product.index, chartData)
    ].options.label.text = product.product.name;
    chartData.ref.xAxis[0].update();
    return chartData;
  }
  updateSCLStyle(chartData: any, lineConfig: LineConfigurationModel) {
    chartData.ref.xAxis[0].plotLinesAndBands[
      this.getPlotLineIndex(lineConfig.productIndex, chartData)
    ].options.color = lineConfig.lineColor;
    chartData.ref.xAxis[0].plotLinesAndBands[
      this.getPlotLineIndex(lineConfig.productIndex, chartData)
    ].options.label.style.color = lineConfig.lineColor;
    chartData.ref.xAxis[0].plotLinesAndBands[
      this.getPlotLineIndex(lineConfig.productIndex, chartData)
    ].options.dashStyle = lineConfig.lineStyle;
    chartData.ref.xAxis[0].plotLinesAndBands[
      this.getPlotLineIndex(lineConfig.productIndex, chartData)
    ].options.width = lineConfig.lineWidth;
    chartData.ref.xAxis[0].update();
    return chartData;
  }
}
