<div *ngIf="type === 'close'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.4009 11.8313C15.916 11.1275 16.2847 10.4559 16.5085 10.0003C15.8026 8.56338 13.6804 5 9.99996 5C9.55003 5 9.12416 5.05472 8.72051 5.15086L7.39712 3.82747C8.17994 3.52605 9.04048 3.33333 9.99996 3.33333C15.8333 3.33333 18.3333 10 18.3333 10C18.3333 10 17.7756 11.4657 16.5915 13.0218L15.4009 11.8313ZM4.53538 5.71381L2.7441 3.92252L3.92248 2.74414L8.93505 7.75671C9.25945 7.60254 9.61793 7.50937 10.0003 7.50937C11.3788 7.50937 12.5003 8.63089 12.5003 10.0094C12.5003 10.3917 12.4071 10.7502 12.253 11.0746L17.2558 16.0775L16.0774 17.2559L14.1719 15.3503C13.0371 16.1229 11.6576 16.6667 9.99996 16.6667C4.16663 16.6667 1.66663 10 1.66663 10C1.66663 10 2.56959 7.62762 4.53538 5.71381ZM9.99996 15C11.1368 15 12.115 14.649 12.9578 14.1362L11.0772 12.2557C10.7498 12.4134 10.3874 12.5094 10.0003 12.5094C8.62184 12.5094 7.50032 11.3878 7.50032 10.0094C7.50032 9.62224 7.59629 9.25985 7.75402 8.93244L5.71702 6.89544C4.59285 7.98085 3.85168 9.2662 3.49132 9.99969C4.19729 11.4366 6.31954 15 9.99996 15Z"
      fill="currentColor"
    />
  </svg>
</div>
<div *ngIf="type === 'open'">
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="show">
      <g id="show_2">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5ZM12 10.5C12.8284 10.5 13.5 11.1716 13.5 12C13.5 12.8284 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12C10.5 11.1716 11.1716 10.5 12 10.5Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 4C5 4 2 12 2 12C2 12 5 20 12 20C19 20 22 12 22 12C22 12 19 4 12 4ZM12 6C16.42 6 19 10.28 19.81 12C19 13.72 16.42 18 12 18C7.58 18 5 13.72 4.19 12C5 10.28 7.58 6 12 6Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</div>
