import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-information-page",
  templateUrl: "./information-page.component.html",
  styleUrls: ["./information-page.component.css"],
})
export class InformationPageComponent {
  version: string = "0.0";
  siemens: string = "";
  webSCUVersion: string = "";
  corporateInformation: string = "";
  privacyPolicy: string = "";
  cookiePolicy: string = "";
  termsOfUse: string = "";
  digitalID: string = "";
  locale: string;
  corporateInfoUrl: string;
  privacyPolicyUrl: any;
  cookiePolicyUrl: any;
  termsOfUserUrl: any;
  digitalIdUrl: any;
  constructor(private translator: TranslateService) {
    this.translator
      .get("footer.siemens")
      .pipe(take(1))
      .subscribe((value) => (this.siemens = value));
    this.translator
      .get("footer.webSCUVersion")
      .pipe(take(1))
      .subscribe((value) => (this.webSCUVersion = value));
    this.translator
      .get("footer.corporateInformation")
      .pipe(take(1))
      .subscribe((value) => (this.corporateInformation = value));
    this.translator
      .get("footer.cookiePolicy")
      .pipe(take(1))
      .subscribe((value) => (this.cookiePolicy = value));
    this.translator
      .get("footer.privacyPolicy")
      .pipe(take(1))
      .subscribe((value) => (this.privacyPolicy = value));
    this.translator
      .get("footer.termsOfUse")
      .pipe(take(1))
      .subscribe((value) => (this.termsOfUse = value));
    this.translator
      .get("footer.digitalID")
      .pipe(take(1))
      .subscribe((value) => (this.digitalID = value));

    this.version = environment.appVersion;
  }

  onCorporateInfoClick() {
    this.locale = localStorage.getItem("i18nextLng");
    this.translator
      .get("corporateInfoUrl")
      .pipe(take(1))
      .subscribe((value) => (this.corporateInfoUrl = value));
    window.open(this.corporateInfoUrl);
  }
  onPrivacyPolicyClick() {
    this.locale = localStorage.getItem("i18nextLng");
    this.translator
      .get("privacyPolicyUrl")
      .pipe(take(1))
      .subscribe((value) => (this.privacyPolicyUrl = value));
    window.open(this.privacyPolicyUrl);
  }
  onCookiePolicyClick() {
    this.locale = localStorage.getItem("i18nextLng");
    this.translator
      .get("cookiePolicyUrl")
      .pipe(take(1))
      .subscribe((value) => (this.cookiePolicyUrl = value));
    window.open(this.cookiePolicyUrl);
  }
  onTermsOfUseClick() {
    this.locale = localStorage.getItem("i18nextLng");
    this.translator
      .get("termsOfUserUrl")
      .pipe(take(1))
      .subscribe((value) => (this.termsOfUserUrl = value));
    window.open(this.termsOfUserUrl);
  }
  onDigitalIDClick() {
    this.locale = localStorage.getItem("i18nextLng");
    this.translator
      .get("digitalIdUrl")
      .pipe(take(1))
      .subscribe((value) => (this.digitalIdUrl = value));
    window.open(this.digitalIdUrl);
  }
}
