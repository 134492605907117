import { Component, Input } from "@angular/core";
import BaseModel from "../../models/devices/BaseModel";

@Component({
  selector: "app-device-properties",
  templateUrl: "./device-properties.component.html",
  styleUrls: ["./device-properties.component.css"],
})
export class DevicePropertiesComponent {
  @Input() selectedProduct: BaseModel;

  constructor() {}
}
