export default class UserRegistrationUIModel {
  firstName!: string;
  lastName!: string;
  emailId!: string;
  passWord!: string;
  checkPassword!: string;
  selectedRole!: string;
  selectedRegion!: string;
  roles!: string[];
  regions!: string[];
  isExternal!: boolean;
  isNewUser!: boolean;
}
