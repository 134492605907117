import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ColDef, GridOptions, IHeaderParams } from "ag-grid-community";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription, take } from "rxjs";
import { EventType } from "../../models/constants/eventType";
import routerNames from "../../models/router/routerNames";
import { PagingConstants } from "../../models/constants/pagingConstants";

@Component({
  selector: "app-ag-grid",
  templateUrl: "./ag-grid.component.html",
  styleUrls: ["./ag-grid.component.css"],
})
export class AgGridComponent implements OnInit, OnChanges, OnDestroy {
  gridOptions: GridOptions;
  gridApi;
  projectId: string;
  subscriptions: Subscription = new Subscription();

  @Input() rowData;
  @Input() columnDefs: ColDef[];
  constructor(
    public router: Router,
    public eventBus: NgEventBus,
    public translateService: TranslateService
  ) {
    this.gridOptions = {
      pagination: true,
      paginationAutoPageSize: true,
      detailRowAutoHeight: true,
      localeText: {
        page: PagingConstants.page,
        of: PagingConstants.of,
        to: PagingConstants.to,
        noRowsToShow: PagingConstants.noResultFoundText,
      },
      rowHeight: 68,
      rowStyle: {
        background: "#dfdfd9",
        "border-bottom": "white 2px solid",
        "border-top": "white 2px solid",
      },
      icons: {
        sortAscending: '<img src="../../assets/icon/ascendingsort.svg" />',
        sortDescending: '<img src="../../assets/icon/descendingsort.svg" />',
        sortUnSort: '<img src="../../assets/icon/unsort.svg" />',
      },
      onCellClicked: (event) => this.onRowClick(event),
    };

    const CANCELPROJECTCREATION = this.eventBus
      .on(EventType.CANCELPROJECTCREATION)
      .subscribe((data: MetaData) => {
        this.gridResize();
      });
    this.subscriptions.add(CANCELPROJECTCREATION);
  }
  ngOnInit() {
    this.gridResize();
  }
  ngOnChanges() {
    this.columnDefs.forEach((col) => {
      if (col.headerName)
        col.headerName = this.translateService.instant(col.headerName);
    });
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setColumnDefs(this.columnDefs);
    }
  }
  onGridReady(params) {
    // this.rowData = data;
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
  @HostListener("window:resize", ["$event"])
  gridResize() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }
  onRowClick(event) {
    this.projectId = event.data.id;
    this.gridOptions.api.forEachNode((node) =>
      node.rowIndex === event.rowIndex ? node.setSelected(true) : 0
    );
    if (
      event.column &&
      event.column.colDef &&
      event.column.colDef.headerName === ""
    ) {
      event.stopPropagation();
    }
    let url = routerNames.getListEntryRoute(this.projectId);
    this.router.navigate([url]);
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
