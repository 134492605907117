<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between align-items-center m-2">
    <div class="terms-title">
      {{ "imp_term_use_header" | translate }}
    </div>
    <button
      type="button"
      class="bg-transparent border-0 p-0"
      (click)="closeTermsandCondition()"
    >
      <app-icons [iconName]="'close'"></app-icons>
    </button>
  </div>
  <div class="overflow-auto m-2 pe-1" [innerHTML]="termsAndConditionHTML"></div>
</div>
