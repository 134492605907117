<div class="h-100 w-100" [ngClass]="spinnerModel.show ? 'pointer-none' : ''">
  <div class="header">
    <div>
      {{ header | translate }}
    </div>
    <div class="icon-close">
      <app-icons (click)="close()" [iconName]="'close'"></app-icons>
    </div>
  </div>
  <div class="d-flex content">
    <div class="catalog">
      <app-product-catalog-tree [catalogTreeData]="data.catalogTreeData">
      </app-product-catalog-tree>
      <!-- <mat-tab-group
        #tab
        class="catalog-tab"
        [selectedIndex]="selectedTab"
        (selectedTabChange)="onTabClick($event)"
        animationDuration="0ms"
      >
        <mat-tab>
          <ng-template mat-tab-label>
            <span> {{ "CatalogDialog_WINDOW_TITLE" | translate }}</span>
          </ng-template>
          <app-product-catalog-tree [catalogTreeData]="data.catalogTreeData">
          </app-product-catalog-tree>
        </mat-tab>
        <mat-tab [disabled]="true">
          <ng-template mat-tab-label>
            <span> {{ "CatalogFavoriteControl_Favorites" | translate }}</span>
          </ng-template>
          <div class="device-add__tab-catalog"></div>
        </mat-tab>
      </mat-tab-group> -->
    </div>
    <div class="attributes">
      <app-product-attributes
        [projectInfo]="data.projectInfo"
        (closeCatalog)="close()"
        (showSpinner)="showSpinner($event)"
      ></app-product-attributes>
    </div>
  </div>
  <app-spinner
    *ngIf="spinnerModel.show"
    [spinnerText]="spinnerModel.text"
  ></app-spinner>
</div>
