<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.56522 0C4.28249 0 0 4.47715 0 10C0 15.5228 4.28249 20 9.56522 20C14.8479 20 19.1304 15.5228 19.1304 10C19.1304 7.34784 18.1227 4.8043 16.3288 2.92893C14.535 1.05357 12.1021 0 9.56522 0ZM17.1504 9H14.3478C14.2064 6.65566 13.452 4.39691 12.167 2.47C14.8696 3.49511 16.7945 6.01744 17.1504 9ZM6.69565 11C6.8775 13.4997 7.89309 15.8532 9.56522 17.65C11.2373 15.8532 12.2529 13.4997 12.4348 11H6.69565ZM6.69565 9C6.8775 6.50034 7.89309 4.14679 9.56522 2.35C11.2373 4.14679 12.2529 6.50034 12.4348 9H6.69565ZM6.95118 2.48847L6.96348 2.47H7.00174L6.95118 2.48847ZM1.91304 9C2.28491 6.00896 4.23387 3.49251 6.95118 2.48847C5.67363 4.41134 4.92362 6.66315 4.78261 9H1.91304ZM1.98 11H4.78261C4.92407 13.3443 5.67844 15.6031 6.96348 17.53C4.26086 16.5049 2.3359 13.9826 1.98 11ZM14.3478 11C14.195 13.3483 13.4273 15.6073 12.1287 17.53C14.8724 16.5386 16.8432 14.0096 17.2174 11H14.3478Z"
    fill="currentColor"
  />
</svg>
